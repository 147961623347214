import { HighIconImage, LowIconImage, MediumIconImage } from "../apps/patients/components/ChartIcons";
import React from "react";
import { AbClaimDiagnosticCode } from "./billing";
import { Audit } from './Audit'

export type ConditionAndProcedureType = {
  id?: string,
  description: string | null,
  conditionDescription?: string | null,
  diagnosticCodeId?: string,
  diagnosticCode?: AbClaimDiagnosticCode | null,
  status: ConditionStatus,
  conditionStatus?: ConditionStatus,
  severity?: ConditionSeverity | null,
  conditionSeverity?: ConditionSeverity | null,
  startDate?: Date | null,
  endDate?: Date | null,
  notes?: string,
  audit?: Audit,
  version: string
}

export enum ConditionStatus {
  UNKNOWN = 'UNKNOWN',
  ACTIVE = 'ACTIVE',
  RECURRENCE = 'RECURRENCE',
  RELAPSE = 'RELAPSE',
  INACTIVE = 'INACTIVE',
  REMISSION = 'REMISSION',
  RESOLVED = 'RESOLVED',
}

export enum ConditionSeverity {
  SEVERE = 'SEVERE',
  MODERATE = 'MODERATE',
  MILD = 'MILD',
}

export const ConditionStatusMap: Map<ConditionStatus, {label: string, order: number}> = new Map ([
  [ConditionStatus.UNKNOWN, {label: "Unknown", order: 0}],
  [ConditionStatus.ACTIVE, {label: "Active", order: 1}],
  [ConditionStatus.RECURRENCE, {label: "Recurrence", order: 2}],
  [ConditionStatus.RELAPSE, {label: "Relapse", order: 3}],
  [ConditionStatus.INACTIVE, {label: "Inactive", order: 4}],
  [ConditionStatus.REMISSION, {label: "Remission", order: 5}],
  [ConditionStatus.RESOLVED, {label: "Resolved", order: 6}],
])

export const ConditionSeverityMap: Map<ConditionSeverity, {label: string, description: string, order: number, icon: React.ReactNode}> = new Map ([
  [ConditionSeverity.SEVERE, {label: "Severe", description: "Severe severity", order: 0, icon: <HighIconImage />}],
  [ConditionSeverity.MODERATE, {label: "Moderate", description: "Moderate severity", order: 1, icon: <MediumIconImage />}],
  [ConditionSeverity.MILD, {label: "Mild", description: "Mild severity", order: 2, icon: <LowIconImage />}],
])

export const cleanConditionData = (data: ConditionAndProcedureType) => {
  data.diagnosticCodeId = data.diagnosticCode?.id || ''
  delete data.diagnosticCode
  return data
}