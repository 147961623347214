import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { Section, SimpleCheckbox } from 'saga-library/src'
import { Address, AddressType, AddressDefaults } from "saga-client/src/components/Address";
import { rules } from '../../../util/validation'
import { useFormContext } from 'saga-library/src/components/Form'
import { forEach } from 'lodash'
import TabFocusInterceptor from 'saga-library/src/hooks/tabFocusInterceptor'

export type AddressDetailsType = AddressType

const Form = (props) => {
  const [ useOriginalPatientAddress, setUseOriginalPatientAddress ] = useState<boolean>(!!props?.relationshipAddress)
  const { setValue, setFocus } = useFormContext();

  const firstFieldRef = useRef<any>(null);
  const [hasFocus, setHasFocus] = useState(!props.relationshipAddress);
  const setTabFocus = (focus) => {
    setHasFocus(focus)
  }
  TabFocusInterceptor(firstFieldRef, hasFocus, setTabFocus)

  useEffect(() => {
    if (props?.relationshipAddress) {
      forEach(Object.keys(props?.relationshipAddress), (key) => {
        const value = useOriginalPatientAddress ? props?.relationshipAddress[key] : AddressDefaults[key]
        setValue(key, value, { shouldDirty: false })
      })
      if (!useOriginalPatientAddress) {
        setTabFocus(false)
        setTimeout(() => {
          setFocus('street')
        }, 0)
      }
    }
  }, [useOriginalPatientAddress, props?.relationshipAddress])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      <Section.SubHeader>Address</Section.SubHeader>
      { props?.relationshipAddress &&
          <SimpleCheckbox
            dataTestId={`${props?.dataTestId}-original-patient-address`}
            label={'Same as original patient'}
            value={useOriginalPatientAddress}
            onChange={() => setUseOriginalPatientAddress(!useOriginalPatientAddress)}
          />
      }
      { (!props?.relationshipAddress || !useOriginalPatientAddress) &&
        <Address
          rules={rules.address}
          dataTestId={props?.dataTestId}
          firstFieldRef={firstFieldRef}
        />
      }
    </Box>
  )
}

export const AddressDetails = {
  Form: Form,
  defaults: AddressDefaults
}
