import React from 'react'
import InputMask from 'react-input-mask'
import { Controller, useFormContext } from "saga-library/src/components/Form";
import { SimpleTextField } from "../TextField";
import { SxProps, Theme } from "@mui/material";

export interface SimpleTextFieldMaskProps {
  formatMask: string
  maskChar?: string
  style?: object
  value: string
  alwaysShowMask?: boolean
}

export function SimpleTextFieldMask({
  formatMask,
  maskChar,
  style,
  value,
  alwaysShowMask,
}: SimpleTextFieldMaskProps) {
  return (
    <InputMask
      mask={formatMask}
      maskChar={maskChar}
      value={value}
      style={style}
      alwaysShowMask={alwaysShowMask}
    />
  )
}

export interface TextFieldMaskProps {
  name: string
  label: string
  fullWidth?: boolean
  sx?: SxProps<Theme>
  rules?: object
  type?: 'tel' | 'email' | 'text' | 'password'
  size?: 'small' | 'medium'
  formatMask: string
  maskChar: string
  unmask?: (value: string) => string
  onChange?: (value) => void
  autoFocus?: boolean
  disabled?: boolean
  maxLength?: number | undefined
  dataTestId?: string
}

const removeMask = (input) => {
  return input.target.value.replace(/[^0-9a-z]/gi, '')
}

export default function TextFieldMask({
  name,
  label,
  fullWidth,
  sx,
  rules,
  type = 'text',
  formatMask,
  maskChar = '',
  unmask,
  onChange,
  size = 'small',
  autoFocus,
  disabled = false,
  maxLength,
  dataTestId,
}: TextFieldMaskProps) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={''}
      render={({ field: { onChange: controlledOnChange, value = '', name, ref }, fieldState: {error} }) => (
        <InputMask
          value={value || ''}
          mask={formatMask}
          maskChar={maskChar}
          onChange={(input) => {
            if (unmask) {
              controlledOnChange(unmask(input.target.value))
              if (onChange) {
                onChange(unmask(input.target.value))
              }
            } else {
              controlledOnChange(removeMask(input))
              if (onChange) {
                onChange(removeMask(input))
              }
            }
          }}
          disabled={disabled}
        >
          {(inputProps) => {
            inputProps.maxLength ??= maxLength
            return (
              <SimpleTextField
                dataTestId={dataTestId}
                label={label}
                name={name}
                error={!!error}
                helperText={error?.message}
                value={value}
                size={size}
                slotProps={{
                  htmlInput: inputProps
                }}
                fullWidth={fullWidth}
                sx={sx}
                type={type}
                autoFocus={autoFocus}
                inputRef={ref}
                disabled={disabled}
              />
            )}
          }
        </InputMask>
      )}
    />
  )
}
