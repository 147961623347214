import {
  DosageType,
  PrescriptionStatus,
  PrescriptionStatusMap,
  PrescriptionType,
  PrescriptionUsage
} from '../../../../../types/Prescription'
import moment from 'moment-timezone'
import { useStaticDataContext } from '../../../../../providers/StaticDataContext'
import { addDurationToDate } from 'saga-library/src/util'
import { usePrescriptionContext } from '../../../providers/PrescriptionProvider'
import { nullOrUndefinedComparison } from 'saga-library/src/components/TableList/TableSortUtils'
import { cloneDeep } from 'lodash'

export const PrescriptionSortPredicate = (a:PrescriptionType, b:PrescriptionType) : number => {
  const getStatusOrder = (prescription: PrescriptionType) => {
    return prescription.status ? PrescriptionStatusMap.get(prescription.status)!.order : null
  }
  const aStatusOrder = getStatusOrder(a)
  const bStatusOrder = getStatusOrder(b)

  return (nullOrUndefinedComparison(aStatusOrder, bStatusOrder) ?? aStatusOrder! - bStatusOrder!)
    || moment(b.startDate).unix() - moment(a.startDate).unix()
    || (nullOrUndefinedComparison(a.audit?.crePit, b.audit?.crePit) ?? moment(b.audit?.crePit).unix() - moment(a.audit?.crePit).unix())
    || 0
}

const buildDosageSummary = (dosages: DosageType[], prescriptionDoseQuantityUnit, unitsOfTime) => {
  let summary = ""

  for (const dosage of dosages){
    let dosageSummary = dosage.dosageRange

    if (dosage.dosageUnits) {
      let units = prescriptionDoseQuantityUnit[dosage.dosageUnits]?.display
      dosageSummary += ` ${units}`
    }

    if (dosage.frequency) {
      dosageSummary += ` ${dosage.frequency}`
    }

    if (dosage.prn) {
      dosageSummary += "PRN"
    }

    if (dosage.durationUnits && dosage.durationAmount) {
      let durationUnits = unitsOfTime[dosage.durationUnits]
      dosageSummary += ` ${dosage.durationAmount} ${durationUnits}`
    }

    summary += dosageSummary
  }

  return summary
}

export const DateDisplayMap = {
  "day" : {
    display: "Day(s)",
    sort: 0
  },
  "week" : {
    display: "Week(s)",
    sort: 1
  },
  "month" : {
    display: "Month(s)",
    sort: 2
  },
  "annually" : {
    display: "Year(s)",
    sort: 3
  },
  "hour" : {
    display: "Hour(s)",
    sort: 4
  },
  "minute" : {
    display: "Minute(s)",
    sort: 5
  },
  "seconds" : {
    display: "Second(s)",
    sort: 6
  },
}

const getDosageDurationInSeconds = (dosage, unitsOfTime) => {
  if(dosage.durationUnits && dosage.durationAmount) {

    return moment.duration(
      dosage.durationAmount,
      unitsOfTime[dosage.durationUnits].replace(/[^a-zA-Z]+/g, '').toLowerCase()
    ).asSeconds()
  }
  return 0
}

const calculateDosageEndDate = (prescription: PrescriptionType | undefined, unitsOfTime) => {
  if (!prescription){
    return undefined
  }

  if(prescription.dosages.length < 1 || prescription.usage === PrescriptionUsage.CONTINUOUS){
    return prescription.endDate || undefined
  }

  const dosageDurationsInSeconds = prescription.dosages.map(dosage => {
    return getDosageDurationInSeconds(dosage, unitsOfTime)
  }).filter(duration => duration > 0)

  if(dosageDurationsInSeconds.length === 0) return undefined

  let durationInSeconds = prescription.link === "THEN" ?
    dosageDurationsInSeconds.reduce((a, b) => a + b, 0) :
    Math.max(...dosageDurationsInSeconds)

  if(prescription.refillsExpire){
    return addDurationToDate(moment.duration(durationInSeconds, 'seconds'), moment(prescription.refillsExpire)).toDate()
  }

  if(prescription.refills && prescription.refills > 0) {
    durationInSeconds = durationInSeconds * (parseInt(prescription.refills.toString()) +1)
  }

  return addDurationToDate(moment.duration(durationInSeconds, 'seconds'), moment(prescription.startDate)).toDate()
}

const getMaxIntFromString = (inputString): number => {
  let rangeString = inputString

  if(rangeString.includes("-")) {
    const range = rangeString.split("-")
    rangeString = parseInt(range[0]) > parseInt(range[1]) ? range[0] : range[1]
  }

  return parseInt(rangeString)
}

const getSecondsPerDosageFrequency = (dosage:DosageType, dosageDurationInSeconds) => {
  if(!dosage.frequency) return null

  if(dosage.frequency.includes('<x>') || dosage.frequency.includes('<y>')){
    return null
  }

  //One time only
  if(dosage.frequency === 'ONCE' || dosage.frequency === 'STAT'){
    return dosageDurationInSeconds
  }
  const secondsPerHour = 60 * 60
  const secondsPerDay = 24 * secondsPerHour
  const secondsPerWeek = 7 * secondsPerDay
  const secondsPerMonth = 30 * secondsPerDay

  const oncePerDayOptionList = ["QAM", "QPM", "QNOON", "QHS", "QD"]
  //Once per day
  if(oncePerDayOptionList.includes(dosage.frequency)){
    return secondsPerDay
  }

  //Twice per day
  if(dosage.frequency === 'BID'){
    return secondsPerDay / 2
  }

  //Three times per day
  if(dosage.frequency === 'TID'){
    return secondsPerDay / 3
  }

  //Four times per day
  if(dosage.frequency === 'QID'){
    return secondsPerDay / 4
  }

  //X times per day
  if(dosage.frequency.includes("ID")){
    return secondsPerDay / parseInt(dosage.frequency)
  }

  //X every Y
  if(dosage.frequency[0]==='Q') {
    const maxFreq = getMaxIntFromString(dosage.frequency.slice(1))

    //every x seconds
    if (dosage.frequency[dosage.frequency.length - 1] === 'S') {
      return maxFreq
    }
    //every x minutes
    if (dosage.frequency[dosage.frequency.length - 1] === 'M') {
      return maxFreq * 60
    }

    //every x hours
    if (dosage.frequency[dosage.frequency.length - 1] === 'H') {
      return maxFreq * secondsPerHour
    }

    //every x days
    if(dosage.frequency[dosage.frequency.length - 1] === 'D') {
      return maxFreq * secondsPerDay
    }

    //every x weeks
    if (dosage.frequency[dosage.frequency.length - 1] === 'W') {
      return maxFreq * secondsPerWeek
    }

    //every x months
    if (dosage.frequency[dosage.frequency.length - 1] === 'L') {
      return maxFreq * secondsPerMonth
    }
  }

  console.error(`Unknown Frequency Code: Unable to calculate frequency.`)
  return 0
}

const calculatePrescriptionQuantity = (prescription: PrescriptionType | undefined, unitsOfTime) => {
  let totalQuantity = 0

  if (!prescription || prescription.dosages.length < 1 || prescription.dosages.some( d => d.frequency?.includes('<x>') || d.frequency?.includes('<y>') )) return totalQuantity

  for (const dosage of prescription.dosages) {
    if (dosage.dosageRange) {
      const dosageQuantity = getMaxIntFromString(dosage.dosageRange)

      const dosageDurationInSeconds = getDosageDurationInSeconds(dosage, unitsOfTime)

      const dosageFrequencyInSeconds = getSecondsPerDosageFrequency(dosage, dosageDurationInSeconds)

      if (dosageQuantity && dosageFrequencyInSeconds && dosageDurationInSeconds) {
        const frequencyPerDuration = dosageDurationInSeconds / dosageFrequencyInSeconds < 1 ? 1 : dosageDurationInSeconds / dosageFrequencyInSeconds
        const totalDosageQuantityForDuration = dosageQuantity * frequencyPerDuration
          totalQuantity += totalDosageQuantityForDuration
      } else {
        totalQuantity += dosageQuantity
      }
    }
  }

  return totalQuantity
}

const processPrescriptionData = (data, unitsOfTime) => {
  const prescriptionData = Object.assign({}, data) as PrescriptionType

  const todayDate = moment().format('YYYY-MM-DD')
  if(!prescriptionData.startDate || moment(prescriptionData.startDate).isBefore(todayDate)){
    prescriptionData.startDate = moment().toDate()
  }

  if(!prescriptionData.endDate || moment(prescriptionData.endDate).isBefore(todayDate)){
    prescriptionData.endDate = calculateDosageEndDate(prescriptionData, unitsOfTime)
  }

  return prescriptionData
}

export const cleanPrescriptionInput = (prescription) => {
  const prescriptionData = cloneDeep(prescription)

  delete prescriptionData.practitionerName
  delete prescriptionData.id
  delete prescriptionData.renewedOn
  delete prescriptionData.renewedFrom
  delete prescriptionData.renewedFromVersion
  delete prescriptionData.status

  delete prescriptionData.drug.ingredient
  delete prescriptionData.drug.drug
  delete prescriptionData.drug.brand

  prescriptionData.practitionerId = prescriptionData?.practitioner?.practitionerId ?? prescriptionData.practitionerId
  prescriptionData.locationId = prescriptionData?.practitioner?.locationId ?? prescriptionData.locationId
  delete prescriptionData.practitioner

  return prescriptionData
}

export const usePrescriptionUtils = () => {
  const { unitsOfTime, prescriptionDoseQuantityUnit} = useStaticDataContext()
  const { archivePrescription, updateDraftPrescription, activatePrescription, createDraftPrescription } = usePrescriptionContext()

  const saveAsDraft = (handleSubmit, onSuccess) => {
    handleSubmit(
      async (data) => {
        const prescriptionData = processPrescriptionData(data, unitsOfTime)

        if(prescriptionData.id) {
          updateDraftPrescription(prescriptionData, onSuccess)
        } else {
          createDraftPrescription(prescriptionData, onSuccess)
        }
      }
    )()
  }

  const saveAsActive = async ( setValue, handleSubmit, onSuccess ) => {
    setValue('status', PrescriptionStatus.ACTIVE)

    await handleSubmit(
      async (data) => {
        const prescriptionData = processPrescriptionData(data, unitsOfTime)
        await activatePrescription(prescriptionData, onSuccess)
      },
      (errors) => {
        setValue('status', undefined)
      }
    )()
  }

  const setAsHistorical = (handleSubmit, onSuccess) => {
    handleSubmit(
      async(data) => {
        const prescriptionData = processPrescriptionData(data, unitsOfTime)
        archivePrescription(prescriptionData, onSuccess)
      }
    )()
  }

  return {
    calculateQuantity: (prescription) => calculatePrescriptionQuantity(prescription, unitsOfTime),
    processPrescription: (prescription) => processPrescriptionData(prescription, unitsOfTime),
    buildSummary: (prescription) => buildDosageSummary(prescription.dosages, prescriptionDoseQuantityUnit, unitsOfTime),
    buildSummaryFromDosages: (dosages) => buildDosageSummary(dosages, prescriptionDoseQuantityUnit, unitsOfTime),
    saveAsActive,
    saveAsDraft,
    setAsHistorical
  }
}