import React from 'react'
import Box from '@mui/material/Box'
import { useLazyQuery } from '@apollo/client'
import { LoadingButton, Section, TextField, Typography, Form } from 'saga-library/src'
import _get from 'lodash/get'
import { useForm, FormProvider } from "saga-library/src/components/Form"
import * as yup from 'yup'
import { ValidateInviteFormProps, ValidateInviteFormValues } from '../../types/invitations'
import { VALIDATE_INVITATION } from "../../graphql-definitions";

export const ValidateInviteForm = (props: ValidateInviteFormProps) => {

  const [validateQuery, { variables, loading }] = useLazyQuery(
    VALIDATE_INVITATION, {
      onCompleted: (data) => {
        const valid = _get(data, 'public.invitation.validateInvitation.valid')
        if (!valid) props.setErrorMessage("Incorrect answer or invitation expired.")
        const dataWithPasscode = {...data}
        dataWithPasscode.passcode = variables?.passcode
        props.onSubmit(!!valid, dataWithPasscode)
      },
      onError: (error) => {
        console.error(error)
        props.setErrorMessage("The invitation could not be validated.")
        props.onSubmit(false, "")
    },
    fetchPolicy: 'network-only'
  })

  const handleValidateInvite = (data) => {
    props.setErrorMessage("")
    validateQuery({
      variables: data
    })
  }

  const schema = yup.object().shape({
    hash: yup.string().required(),
    salt: yup.string().required(),
    passcode: yup.string().required("An answer is required.").max(255)
  }).required();

  const validateInviteFormMethods = useForm<ValidateInviteFormValues>({
    schema: schema
  })

  const {
    handleSubmit,
    register
  } = validateInviteFormMethods

  register("hash", { value: props.hash })
  register("salt", { value: props.salt })

  return (<FormProvider {...validateInviteFormMethods}>

    <Form id={'reset-password-form'} onSubmit={handleSubmit(handleValidateInvite)} autoComplete={'off'}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Section.Header sx={{ mt: 1 }}>
        Invite security question
      </Section.Header>
      <Typography variant={'body1'} sx={{ mt: 1, mb: 1 }}>
        {props.question}
      </Typography>
      <TextField
        label={'Answer'}
        sx={{ width: 280, pr: 1 }}
        name={'passcode'}
      />

      <Box sx={{ my: 2 }}>
        <LoadingButton
          name={'PasscodeSubmit'}
          type={'submit'}
          loading={loading}
          variant={'contained'}
        >
          Submit
        </LoadingButton>
        </Box>
      </Box>
    </Form>
  </FormProvider >)
}
