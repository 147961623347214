import Box from '@mui/material/Box'
import React from "react";
import { Typography } from 'saga-library/src';

const UserRoleSelectItem = ({ data, props }) => {
  const { lastName, firstName, roles } = data
  const userRoles = roles.join(', ')

  return (
    <Box
      component="li"
      sx={{
        flex: '1 1 auto',
        display: 'flex',
        padding: 1,
        alignItems: 'center'
      }}
      {...props}
      key={data.key}
      name={`user_${data.value}`}
      role={'option'}
    >
      <Box>
        <Box> {`${lastName}, ${firstName}`} </Box>
        <Typography variant={'body1'} lineclamp={1} sx={{color: theme => theme.palette.greys.medium}}>
          {userRoles}
        </Typography>
      </Box>
    </Box>
  )
}

export default UserRoleSelectItem
