import React from 'react'
import { Box, useTheme } from "@mui/material";
import { Typography } from "saga-library/src";
import { useFormattedDate } from "../../../../hooks/FormattedDate";
import { useFormContext } from "saga-library/src/components/Form";
import { ClaimInfoBase } from '../ClaimInfo'

export const Form = () => {
  const { getValues } = useFormContext()
  const theme = useTheme()
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <ClaimInfoBase />
      <Box>
        <Typography variant={'body2'} sx={{ color: theme.palette.greys.medium }}>
          Action
        </Typography>
        <Typography variant={'body1'}>
          {getValues('actionCode')}
        </Typography>
      </Box>
      <Box>
        <Typography variant={'body2'} sx={{ color: theme.palette.greys.medium }}>
          Date assessed
        </Typography>
        <Typography variant={'body1'}>
          {useFormattedDate(getValues('assessmentPostPit'))}
        </Typography>
      </Box>
      <Box>
        <Typography variant={'body2'} sx={{ color: theme.palette.greys.medium }}>
          Date paid
        </Typography>
        <Typography variant={'body1'}>
          {useFormattedDate(getValues('expectedPaymentDate'))}
        </Typography>
      </Box>
    </Box>
  )
}

export const AssessedClaimsInfo = {
  Form: Form,
}