import React, { useState } from 'react'
import { Box } from '@mui/material'
import { rules } from '../../../util/baseClaimValidation'
import { useWatch } from "saga-library/src/components/Form"
import { serviceCodeHospitalAdmission } from '../../../../../utils/ABClaimConstants'
import BatchControlledTextField from "../components/BatchControlledTextField";
import { MultipleValuesField } from '../components/MultipleValuesField';
import { BatchControlledBillingProfileSelect } from "../components/BatchControlledBillingProfileSelect";
import { BatchControlledPHNSearch } from "../components/BatchControlledPHNSearch";
import { BatchControlledDatePicker } from "../components/BatchControlledDatePicker";
import { BatchControlledServiceCodeSearch } from "../components/BatchControlledServiceCodeSearch";
import { BatchControlledDiagnosticCodeInput } from "../components/BatchControlledDiagnosticCodeInput";
import { BatchControlledServiceFeeModifierSelect } from "../components/BatchControlledServiceFeeModifierSelect";
import { BatchControlledFacilityInput } from "../components/BatchControlledFacilityInput";
import { BatchControlledFunctionalCenter } from "../components/BatchControlledFunctionalCenterInput";
import { BatchControlledLocationCodeSelect } from "../components/BatchControlledLocationCodeSelect";
import { BatchControlledReferralPractitionerSearch } from "../components/BatchControlledReferralPractitionerSearch";
import FormattedDatePicker from "../../../../../components/FormattedDatePicker";
import { DiagnosticCodesInfoTooltip, FeeModifierInfoTooltip, ServiceCodeInfoTooltip } from "../../ClaimInfoTooltips";

const Form = ({ control, register, resetField, getValues, setValue, batchUpdateMultiple, dataTestId }) => {

  const [practitionerId, setPractitionerId] = useState(
    getValues('practitionerId')
  )

  const serviceCode = useWatch({
    control,
    name: 'serviceCode',
  })

  const serviceDate = useWatch({
    control,
    name: 'serviceDate',
  })

  const diagnosticCodes = useWatch({
    control,
    name: 'diagnosticCodes'
  })

  const serviceCodeFeeModifiers = useWatch({
    control,
    name: 'serviceCodeFeeModifiers'
  })

  const handleBillingProfileChange = (billingProfile) => {
    setPractitionerId(billingProfile.practitionerId)
  }

  const multipleValuesWithTooltipStyle = {
    width: '100%'
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <MultipleValuesField
          dataTestId={`${dataTestId}-billingProfile`}
          label={'Practitioner'}
          multipleValuesState={batchUpdateMultiple.billingProfile}
          setUpdatedFields={() => setValue('billingProfile', '', { shouldDirty: true })}
        >
          <BatchControlledBillingProfileSelect
            label={'Practitioner'}
            name={'billingProfile'}
            onSelect={handleBillingProfileChange}
            dataTestId={`${dataTestId}-billingProfile`}
          />
        </MultipleValuesField>
        <MultipleValuesField
          dataTestId={`${dataTestId}-patient`}
          label={'Patient'}
          multipleValuesState={batchUpdateMultiple.patient}
          setUpdatedFields={() => setValue('patient', '', { shouldDirty: true })}
        >
          <BatchControlledPHNSearch
            name={'patient'}
            label={"Patient"}
            dataTestId={`${dataTestId}-patient`}
          />
        </MultipleValuesField>
        <MultipleValuesField
          dataTestId={`${dataTestId}-serviceDate`}
          label={'Service date'}
          multipleValuesState={batchUpdateMultiple.serviceDate}
          setUpdatedFields={() => setValue('serviceDate', '', { shouldDirty: true })}
        >
          <BatchControlledDatePicker
            label={'Service date'}
            name={'serviceDate'}
            dataTestId={`${dataTestId}-serviceDate`}
          />
        </MultipleValuesField>
        <Box
          sx={{
            p: 1,
            border: 1,
            borderRadius: '8px',
            borderColor: 'greys.light',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              gap: '4px',
              color: 'greys.light'
            }}
          >
            <MultipleValuesField
              dataTestId={`${dataTestId}-serviceCode`}
              label={'Service code'}
              multipleValuesState={batchUpdateMultiple.serviceCode}
              setUpdatedFields={() => setValue('serviceCode', '', { shouldDirty: true })}
              sx={multipleValuesWithTooltipStyle}
            >
              <BatchControlledServiceCodeSearch
                label={'Service code'}
                name={'serviceCode'}
                practitionerId={practitionerId}
                serviceDate={serviceDate}
                sx={multipleValuesWithTooltipStyle}
                dataTestId={`${dataTestId}-serviceCode`}
              />
            </MultipleValuesField>
            <ServiceCodeInfoTooltip serviceCode={serviceCode}/>
          </Box>
          {serviceCode &&
            serviceCodeHospitalAdmission.includes(
              serviceCode['serviceCode']
            ) && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  justifyContent: 'space-evenly'
                }}
              >
                <FormattedDatePicker
                  label={'Hospital admission date'}
                  name={'hospitalAdmissionDate'}
                  sx={{ width: '100%' }}
                  dataTestId={`${dataTestId}-hospitalAdmissionDate`}
                />
              </Box>
            )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              gap: '4px',
              color: 'greys.light'
            }}
          >
            <MultipleValuesField
              dataTestId={`${dataTestId}-diagnosticCodes`}
              label={'Diagnostic code'}
              multipleValuesState={batchUpdateMultiple.diagnosticCodes}
              setUpdatedFields={() => setValue('diagnosticCodes', '', { shouldDirty: true })}
              sx={multipleValuesWithTooltipStyle}
            >
              <BatchControlledDiagnosticCodeInput
                label={'Diagnostic code'}
                name={'diagnosticCodes'}
                practitionerId={practitionerId}
                serviceDate={serviceDate}
                sx={multipleValuesWithTooltipStyle}
                dataTestId={`${dataTestId}-diagnosticCodes`}
              />
            </MultipleValuesField>
            <DiagnosticCodesInfoTooltip diagnosticCodes={diagnosticCodes}/>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              gap: '4px',
              color: 'greys.light'
            }}
          >
            <MultipleValuesField
              dataTestId={`${dataTestId}-feeModifiers`}
              label={'Fee modifiers'}
              multipleValuesState={batchUpdateMultiple.serviceCodeFeeModifiers}
              setUpdatedFields={() => setValue('serviceCodeFeeModifiers', '', { shouldDirty: true })}
              sx={multipleValuesWithTooltipStyle}
            >
              <BatchControlledServiceFeeModifierSelect
                serviceCodeId={serviceCode?.id}
                sx={{
                  my: 1,
                  ...multipleValuesWithTooltipStyle
                }}
                batchUpdateMultiple={batchUpdateMultiple}
                dataTestId={`${dataTestId}-feeModifiers`}
              />
            </MultipleValuesField>
            <FeeModifierInfoTooltip feeModifiers={serviceCodeFeeModifiers} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              gap: 2
            }}
          >
            <BatchControlledTextField
              label={'Calls'}
              type={'number'}
              name={'calls'}
              dataTestId={`${dataTestId}-calls`}
              field={'calls'}
              slotProps={{
                htmlInput: { maxLength: rules.calls.max },
                input: register('calls', { valueAsNumber: true })
              }}
              sx={{ width: '112px' }}
              multipleValuesState={batchUpdateMultiple.calls}
              setUpdatedFields={() => setValue('calls', '', { shouldDirty: true })}
            />
            <BatchControlledTextField
              label={'Encounter'}
              type={'number'}
              name={'encounter'}
              dataTestId={`${dataTestId}-encounter`}
              field={'encounter'}
              slotProps={{
                htmlInput: { maxLength: rules.encounter.max },
                input: register('encounter', { valueAsNumber: true })
              }}
              sx={{ width: '112px' }}
              multipleValuesState={batchUpdateMultiple.encounter}
              setUpdatedFields={() => setValue('encounter', '', { shouldDirty: true })}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <MultipleValuesField
          dataTestId={`${dataTestId}-facility`}
          label={'Facility'}
          multipleValuesState={batchUpdateMultiple.facility}
          setUpdatedFields={() => {
            setValue('facility', '', { shouldDirty: true })
          }}
        >
          <BatchControlledFacilityInput
            label={"Facility"}
            name={'facility'}
            practitionerId={practitionerId}
            serviceDate={serviceDate}
            dataTestId={`${dataTestId}-facility`}
          />
        </MultipleValuesField>
        <MultipleValuesField
          dataTestId={`${dataTestId}-functionalCenter`}
          label={'Functional centre'}
          multipleValuesState={batchUpdateMultiple.functionalCenter}
          setUpdatedFields={() => {
            setValue('functionalCenter', '', { shouldDirty: true })
          }}
        >
          <BatchControlledFunctionalCenter
            name={'functionalCenter'}
            label={'Functional centre'}
            onClear={() => resetField('functionalCenter')}
            dataTestId={`${dataTestId}-functionalCenter`}
          />
        </MultipleValuesField>
        <MultipleValuesField
          dataTestId={`${dataTestId}-locationCode`}
          label={'Location'}
          multipleValuesState={batchUpdateMultiple.locationCode}
          setUpdatedFields={() => {
            setValue('locationCode', '', { shouldDirty: true })
          }}
        >
          <BatchControlledLocationCodeSelect
            label={'Location'}
            name={'locationCode'}
            dataTestId={`${dataTestId}-locationCode`}
          />
        </MultipleValuesField>
        <MultipleValuesField
          dataTestId={`${dataTestId}-referralPractitioner`}
          label={'Referral practitioner'}
          multipleValuesState={batchUpdateMultiple.referralPractitioner}
          setUpdatedFields={() => {
            setValue('referralPractitioner', '', { shouldDirty: true })
          }}
        >
          <BatchControlledReferralPractitionerSearch
            label={'Referral practitioner'}
            name={'referralPractitioner'}
            dataTestId={`${dataTestId}-referralPractitioner`}
          />
        </MultipleValuesField>
        <BatchControlledTextField
          label={'Text'}
          name={'text'}
          field={'text'}
          multipleValuesState={batchUpdateMultiple.text}
          setUpdatedFields={() => setValue('text', '', { shouldDirty: true })}
          multiline={true}
          hideHelperText={true}
          slotProps={{
            htmlInput: { maxLength: rules.text.max, sx: { height: '100% !important', overflow: 'auto !important'  } },
            input: { sx: { mt: 1 }, style: { minHeight: '40px', flexGrow: 1, flexShrink: 1 } },
            inputLabel: { sx: { mt: 1 } }
          }}
          sx={{ height: '100%', mt: 0, overflow: 'hidden' }}
          dataTestId={`${dataTestId}-text`}
        />
      </Box>
    </Box>
  )
}

export const BatchUpdateClaimBaseDetails = {
  Form: Form,
}
