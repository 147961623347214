import { ConfirmationDialog, Typography } from "saga-library/src";
import { Box } from "@mui/material";
import React from "react";
import CircleIcon from '@mui/icons-material/Circle';
import { AbClaimField } from '../../../../../types/billing';

const DialogListText = ({field, updatedValue}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <CircleIcon
        sx={{
          fontSize:'6px',
          mr: 1,
        }}
      />
      {
        (updatedValue && (field === 'Paper documentation to follow' || field === 'Confidential')) ?
          <Typography variant={'body1'}><strong>{field}</strong> will be updated to {updatedValue}.</Typography>
            :
        (updatedValue) ?
          <Typography variant={'body1'}><strong>{field}</strong> will be updated to <strong>{updatedValue}.</strong></Typography>
          :
          <Typography variant={'body1'}><strong>{field}</strong> will be cleared.</Typography>
      }
    </Box>
  )
}

interface BatchSaveDialogProps {
  selectedClaimIds: string[]
  openSaveModal: boolean
  setOpenSaveModal: (v) => void
  updatedFieldValues: AbClaimField[]
  saveConfirmed: () => void
}

export const BatchSaveDialog = ({
  selectedClaimIds,
  openSaveModal,
  setOpenSaveModal,
  updatedFieldValues,
  saveConfirmed,
}: BatchSaveDialogProps) => {
    return (
      <ConfirmationDialog
        open={openSaveModal}
        onClose={() => setOpenSaveModal(false)}
        title={`Save changes to ${selectedClaimIds.length} claims?`}
        message={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3
            }}
          >
            <Typography variant={'body1'}>
              The following fields will be updated with the values entered for selected claims:
            </Typography>
            <Box sx={{ pl: 2 }}>
              {updatedFieldValues.map((field) => {
                return (
                  <DialogListText
                    field={field.fieldName}
                    updatedValue={field.fieldValue}
                  />
                )
              })}
            </Box>
            <Typography variant={'body1'}>All other fields will not be updated.</Typography>
          </Box>
        }
        primaryAction={saveConfirmed}
        primaryLabel={'Save'}
        dataTestId={'batch-save-claims-confirmation'}
      />
    )
}