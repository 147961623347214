import React from 'react'
import { Typography, TextField} from "saga-library/src";

interface PrintPageInputProps {
  pageCount: number | undefined,
  disabled?: boolean,
  onChange?: (value: string) => void,
  dataTestId: string,
}

export const PrintPageInput = ({
  pageCount,
  disabled,
  onChange,
  dataTestId,
}: PrintPageInputProps) => {
  return (
    <TextField
      disabled={disabled}
      dataTestId={`${dataTestId}-pageRange`}
      name={"pageRange"}
      label={"Pages"}
      hideHelperText={true}
      placeholder={"E.g.: 1, 4-8"}
      onChange={(e) => onChange?.(e?.target?.value)}
      extraEndAdornment={
        <>
          <Typography>of&nbsp;</Typography>
          <Typography sx={{ fontWeight: "bold" }}>{pageCount}</Typography>
        </>
      }
    />
  );
};