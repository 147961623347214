import { ConfirmationDialog } from "saga-library/src";
import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { LoadingOverlay } from "../../../components/LoadingScreen";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { ReadyIcon, ResubmittedIcon } from "./ClaimStatusIcons";
import _get from "lodash/get";
import PermissionButton from "../../../components/PermissionButton";
import { Permission, PermissionType } from "../../../types/settings/Permission";
import { GET_SUBMIT_INFO, LIST_ASSESSEDCLAIMS, LIST_UNSUBMITTEDCLAIMS, SUBMIT_CLAIMS } from "../../../graphql-definitions";

const SubmitClaimsButton = () => {

  const { tenant_id } = useParams()
  const { showSuccessAlert, showErrorAlert } = useAlerts()
  const [open, setOpen] = useState(false)

  const [submitClaimsMutation, {loading, reset}] = useMutation(SUBMIT_CLAIMS)

  const openDialog = () => {
    setOpen(true)
  }

  const submitClaims = () => {
    setOpen(false)
    submitClaimsMutation({
      variables: {
        tenantId: tenant_id,
      },
      refetchQueries: [LIST_UNSUBMITTEDCLAIMS, LIST_ASSESSEDCLAIMS]
    }).then(() => {
      showSuccessAlert("Claims submitted.")
      reset()
    })
    .catch(() => {
      showErrorAlert("Claims couldn't be submitted.")
      reset()
    })
  }

  return (
    <>
      <PermissionButton
        name="submitToAHCIP"
        type={'button'}
        variant={'outlined'}
        onClick={openDialog}
        sx={{ backgroundColor: "white" }}
        requiredType={PermissionType.Billing}
        requiredPermission={Permission.READWRITE}
        dataTestId={'submitToAhcip-button'}
      >
        SUBMIT TO AHCIP
      </PermissionButton>
      <ClaimSubmissionDialog
        open={open}
        setOpen={setOpen}
        submit={submitClaims}
      />
      <LoadingOverlay loading={loading} message={"Submitting claims"} />
    </>
  )
}

interface ClaimSubmissionDialogProps {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  submit: () => void,
}

const ClaimSubmissionDialog = ({open, setOpen, submit}: ClaimSubmissionDialogProps) => {
  const { tenant_id } = useParams()
  const [runQuery, { loading, data }] = useLazyQuery(GET_SUBMIT_INFO, {
    fetchPolicy: 'cache-and-network',
    variables: { tenantId: tenant_id },
  })

  useEffect(() => {
    if(open) {
      runQuery()
    }
  }, [open, runQuery])

  const modalView = () => {
    if (loading) {
      return <CircularProgress />
    }

    if (data) {
      return (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, mb: 2 }}>
            <ReadyIcon />
            <Typography><strong>{_get(data, 'tenant.abClaim.submissionInfo.readyClaims', 0)}</strong> new claims will be
              submitted.</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, mb: 3 }}>
            <ResubmittedIcon />
            <Typography><strong>{_get(data, 'tenant.abClaim.submissionInfo.resubmittedClaims', 0)}</strong> assessed
              claims will be resubmitted.</Typography>
          </Box>
          <Box>
            <Typography>Held claims or claims containing errors will not be submitted.</Typography>
          </Box>
        </>
      )
    }

    return <></>
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={() => setOpen(false)}
      title={"Submit claims to AHCIP"}
      message={modalView()}
      primaryAction={() => submit()}
      primaryLabel={"Submit"}
      dataTestId={"submitToAhcip-confirmation"}
    />
  )
}

export default SubmitClaimsButton