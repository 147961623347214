import {
  AbLabResult,
  AbLabResultNoteAndComment,
  AbLabResultObservationResult,
  AbLabResultSegment
} from '../../../../../types/patients'
import { Box, Chip, useTheme } from '@mui/material'
import { IconButton, Typography } from 'saga-library/src'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { LabAndInvestigationResultValue } from './LabAndInvestigatioResultValue'
import React, { ReactNode } from 'react'
import { ReactComponent as GraphIcon} from "../../../../../assets/GraphIcon.svg";
import DOMPurify from 'dompurify'

const SegmentSortPredicate = (a: AbLabResultSegment, b: AbLabResultSegment) => a.segmentOrder - b.segmentOrder

export interface LabAndInvestigationDetailsProps {
  lab: AbLabResult
  segments: AbLabResultSegment[]
  dataTestId: string
  onGraphClick: (result: AbLabResultObservationResult) => void
  header?: ReactNode
  headerOnClick?: () => void
  isRow?: boolean
}

export interface LabAndInvestigationObservationResultDetailProps {
  result: AbLabResultObservationResult
  dataTestId: string
  onGraphClick: (result: AbLabResultObservationResult) => void
  expandObservation: boolean
}

export interface LabAndInvestigationNoteAndCommentDetailProps {
  noteAndComment: AbLabResultNoteAndComment
  dataTestId: string
}

export const LabAndInvestigationDetails = ({
  lab,
  segments,
  dataTestId,
  onGraphClick,
  header,
  headerOnClick,
  isRow
}:LabAndInvestigationDetailsProps) => {
  const theme = useTheme()

  const hasSegments = segments?.length > 0

  // observations can expand to take up all of the available space if nothing is abnormal and there are no descriptions
  const expandObservation = !segments
    .filter(s => s.__typename === "AbLabResultObservationResult")
    .some(s => {
      const result = (s as AbLabResultObservationResult)
      return ((result.abLabResultObservationResultAbnormals?.length ?? 0) > 0)
        || !!result.observationDescription
    })

  const baseSx = {
    border: '1px rgba(32, 41, 49, 0.32) solid',
    padding: '4px 8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: '40px'
  }

  const headerSx = {
    ...baseSx,
    background: theme.palette.greys.extraLight,
    borderRadius: hasSegments ? '4px 4px 0px 0px' : '4px',
    color: lab.isAbnormal ? theme.palette.error.main : theme.palette.greys.dark
  }

  const headerOnClickSx = {
    ...headerSx,
    '&:hover': {
      background: theme.palette.backgrounds.hover,
    },
    cursor: 'pointer'
  }

  const resultSx = {
    ...baseSx,
    background: 'white',
    alignItems: 'center',
    borderRadius: 0,
  }

  const getSegmentComponent = (segment: AbLabResultSegment, index: number) => {
    switch (segment?.__typename) {
      case "AbLabResultObservationResult":
        return <LabAndInvestigationObservationResultDetail result={segment} dataTestId={`${dataTestId}-${index}`} onGraphClick={onGraphClick} expandObservation={expandObservation}/>
      case "AbLabResultNoteAndComment":
        return <LabAndInvestigationNoteAndCommentDetail noteAndComment={segment} dataTestId={`${dataTestId}-${index}`} />
    }
    return <></>
  }

  return (
    <Box
      key={`${lab.id}-detail`}
      fontFamily={!isRow ? "Roboto Mono, monospace" : ""}
      sx={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      {header && (
        <Box
          sx={{...(headerOnClick ? headerOnClickSx : headerSx)}}
          {...(headerOnClick && {onClick: headerOnClick})}
        >
          {header}
        </Box>
      )}
      {
        segments.sort(SegmentSortPredicate).map((segment, resultIndex) => {

          const isFirstSegment = !header && resultIndex === 0
          const isLastSegment = resultIndex === segments?.length - 1

          if(segment.__typename === "AbLabResultObservationResult"
            && segment.observationValue === "Embedded PDF") return <Box sx={{ display: 'none' }} key={`no-info-box-${resultIndex}`}></Box>

          return (
            <Box
              sx={{
                ...resultSx,
                ...(isFirstSegment
                    ? {borderTopLeftRadius: '4px', borderTopRightRadius: '4px'}
                    : {borderTop: 0}
                ),
                ...(isLastSegment
                    ? {borderBottomRightRadius: '4px', borderBottomLeftRadius: '4px'}
                    : {}
                )
              }}
              key={`${segment?.__typename}-${segment.id}`}
            >
              {getSegmentComponent(segment, resultIndex)}
            </Box>
          )
        })
      }
    </Box>
  )
}

const sanitizeComment = (noteAndComment) => {
  if(!noteAndComment?.comment){
    return ""
  }
  return DOMPurify.sanitize(noteAndComment?.comment, {FORBID_TAGS: ['a'], ALLOWED_TAGS: ['br', 'null']});
}

const LabAndInvestigationNoteAndCommentDetail = ({noteAndComment, dataTestId}:LabAndInvestigationNoteAndCommentDetailProps) => {
  return (
    <Box>
      <Typography variant={"h5"} fontFamily={"inherit"}>Comment</Typography>
      <Typography
        variant={"body1"}
        fontFamily={"inherit"}
        sx={{
          whiteSpace: "break-spaces"
        }}
      >
        {
          sanitizeComment(noteAndComment)
            ?.replaceAll('<BR>', '\n')
            ?.replaceAll('<br>', '\n')
            ?.replaceAll('</null>', '\n')
        }
      </Typography>
    </Box>
  )
}

const ObservationResultObservationDescription = ({result, dataTestId}) => {
  return (
    <Typography
      variant={"h5"}
      fontFamily={"inherit"}
      dataTestId={`${dataTestId}-description`}
      sx={{
        alignContent: "center",
        minHeight: "24px",
        paddingY: 1
      }}
    >
      {result?.observationDescription}
    </Typography>
  )
}

const ObservationResultAbnormal = ({result}) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        paddingY: 1
      }}
    >
      <WarningAmberIcon />
      {(result.abLabResultObservationResultAbnormals?.filter(a => a.flag).map(a =>
        <Chip
          data-testid={'lab-and-investigation-abnormal-chip'}
          size={"small"}
          sx={{
            color: "white",
            backgroundColor: theme.palette.error.main
          }}
          label={a.flag?.display}
          key={a.id}
        />
      ))}
    </Box>
  )
}

const ObservationResultGraph = ({result, onGraphClick, dataTestId}) => {
  return (
      <IconButton
        name={"lab-and-investigation-graphButton"}
        dataTestId={`${dataTestId}-graphButton`}
        onClick={() => onGraphClick(result)}
        icon={<GraphIcon />}
      />
  )
}

const LabAndInvestigationObservationResultDetail = ({result, dataTestId, onGraphClick, expandObservation}:LabAndInvestigationObservationResultDetailProps) => {
  const theme = useTheme()

  const abnormal = (result.abLabResultObservationResultAbnormals?.length ?? 0) > 0

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flex: "1 1 auto",
        alignItems: "start",
        gap: 1,
        color: abnormal ? theme.palette.error.main : theme.palette.greys.dark
      }}
    >
      {!expandObservation &&
        <Box
          sx={{
            display: "flex",
            flex: "0 0 40%"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: "1 1 auto"
            }}
          >
            {!!result?.observationDescription && <ObservationResultObservationDescription result={result} dataTestId={dataTestId}/>}
          </Box>
          {abnormal && <ObservationResultAbnormal result={result}/>}
        </Box>
      }
      <Box
        sx={{
          display: "flex",
          gap: 1,
          flex: "1 1 auto",
          justifyContent: "space-between",
          alignSelf: "center"
        }}
      >
        <LabAndInvestigationResultValue result={result} dataTestId={dataTestId}/>
        {result?.valueType?.code === "NM" && <ObservationResultGraph result={result} onGraphClick={onGraphClick} dataTestId={dataTestId}/>}
      </Box>
    </Box>
  )
}