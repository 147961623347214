import { FormattedAgeString } from '../components/FormattedAge'

export const getAgeAndGenderString = (patient) => {
  if (!patient.dob && !patient.gender?.name) {
    return ''
  } else if (!patient.dob) {
    return patient.gender?.name
  }

  const ageString = `${FormattedAgeString(patient.dob, false)} old`
  if (!patient.gender?.name) {
    return ageString
  }

  return `${ageString} ${patient.gender.name.toLowerCase()}`
}