import React, { useState } from "react";
import {
  EntryDescription,
  EntryTitle,
  ResultOptionsWrapper,
  IconButton
} from "saga-library/src";
import { PrescriptionType } from '../../../types/Prescription'
import { capitalizedWord } from '../../../utils/StringHelpers'
import { usePrescriptionUtils } from '../../../apps/patients/components/prescriptions/components/PrescriptionUtil'
import { PrescriptionResultType } from '../../../apps/patients/components/prescriptions/components/DrugSearch'
import { Box } from "@mui/material";
import DeleteOutline from '@mui/icons-material/DeleteOutline'

interface PrescriptionResultProps {
  Prescription: PrescriptionResultType
  searchText: string
  onUnfavourite?: ()=> void
  icons?: React.ReactNode
}

export const PrescriptionResult = ({
  Prescription,
  searchText,
  onUnfavourite,
  icons
}: PrescriptionResultProps) => {
  const { buildSummary } = usePrescriptionUtils()

  const [isMouseOver, setIsMouseOver] = useState(false)

  const dosageSummary = buildSummary(Prescription)
  let prescription = Prescription as PrescriptionType

  return (
    <Box className={"main"} sx={{ width: "100%" }}>
      <ResultOptionsWrapper>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row"
          }}
          onMouseEnter={() => setIsMouseOver(true)}
          onMouseLeave={() => setIsMouseOver(false)}
        >
          <Box sx={{ flex: "1 1 100%", height: 44 }}>
            <EntryTitle
              title={
                prescription.drug?.brand
                  ? prescription.drug?.name
                  : capitalizedWord(prescription.drug?.name || '')
              }
              searchText={searchText}
              maxLines={1}
            />
            { dosageSummary && <EntryDescription text={dosageSummary} searchText={searchText} maxLines={1}/> }
          </Box>
          {(!!onUnfavourite && searchText.length === 0 && isMouseOver) && [
            <IconButton key={"unfavourite_button"} onClick={(e)=>{
              onUnfavourite()
              e.stopPropagation()
            }} icon={<DeleteOutline />} />,
          ]}
          <Box
            sx={{
              color: 'greys.light',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Box sx={{ display: 'flex', gap: 2 }}>
              {icons}
            </Box>
          </Box>
        </Box>
      </ResultOptionsWrapper>
    </Box>
  )
}

export default PrescriptionResult;