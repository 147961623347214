import React, { useMemo } from 'react'
import {
  EncounterNoteField,
  EncounterNoteTemplate, EncounterNoteTemplateField,
  EncounterNoteTemplateFieldTree,
  EncounterNoteTemplateFieldType
} from '../../../../types/patients'
import { Box } from '@mui/material'
import { EmptyList } from 'saga-library/src'
import {buildNewEncounterNote, getInputNameForField} from './EncounterNoteUtil'
import { EncounterNoteContainer } from './components/EncounterNoteContainer'
import { EncounterNoteTextField } from './components/EncounterNoteTextField'
import { EncounterNoteVitals } from './components/EncounterNoteVitals'
import { EncounterNoteCheckboxWithTextField } from './components/EncounterNoteCheckboxWithTextField'

interface EncounterNoteTemplateFieldsProps {
  template?: EncounterNoteTemplate | null,
  isSigned?: boolean,
  disabled?: boolean,
  hasInitialVitalData?: boolean
  additionalFields: EncounterNoteField[]
  register: (name) => any
}

export const EncounterNoteTemplateFields = ({
  template,
  isSigned,
  disabled,
  hasInitialVitalData,
  additionalFields,
  register
}: EncounterNoteTemplateFieldsProps) => {

  const renderField = (templateFields: EncounterNoteTemplateField[], dataTestId: string) => {
    return templateFields.map((templateField) => {
      switch (templateField.type) {
        case EncounterNoteTemplateFieldType.TEXTBOX:
          const textboxName = getInputNameForField(templateField)
          return (
            <EncounterNoteTextField
              key={textboxName}
              name={textboxName!}
              isSigned={isSigned}
              disabled={disabled}
              dataTestId={dataTestId}
              register={register}
            />
          )
        case EncounterNoteTemplateFieldType.CHECKBOX_TEXTBOX:
          const checkboxTextboxName = getInputNameForField(templateField)
          return (
            <EncounterNoteCheckboxWithTextField
              key={checkboxTextboxName}
              name={checkboxTextboxName!}
              label={templateField.name}
              isSigned={isSigned}
              disabled={disabled}
              dataTestId={dataTestId}
              register={register}
            />
          )
        default:
          console.warn("Unknown field type: ", templateField.type)
          return <></>
      }
    })
  }

  const renderOuterField = (templateField: EncounterNoteTemplateFieldTree, dataTestId: string, moreFields: EncounterNoteField[]) => {
    switch (templateField.type) {
      case EncounterNoteTemplateFieldType.VITALS:
        return (
          <EncounterNoteVitals
            key={`vitals-${templateField.id}`}
            name={`vitals-${templateField.id}`}
            isSigned={isSigned}
            disabled={disabled}
            dataTestId={dataTestId}
            templateField={templateField}
            additionalFields={moreFields}
            hasInitialVitalData={hasInitialVitalData}
            register={register}
          />
        )
      case EncounterNoteTemplateFieldType.GROUP:
        return (
          <EncounterNoteContainer
            key={templateField.id}
            templateField={templateField}
            dataTestId={dataTestId}
            defaultStyle={'controlled'}
          >
            {renderField(templateField.subFields, dataTestId)}
          </EncounterNoteContainer>
        )
      default:
        return (
          <EncounterNoteContainer
            key={templateField.id}
            templateField={templateField}
            dataTestId={dataTestId}
            defaultStyle={'controlled'}
          >
            {renderField([templateField], dataTestId)}
          </EncounterNoteContainer>
        )
    }
  }

  const view = useMemo(() => {
    if (!template) return <></>
    return buildNewEncounterNote(template)?.template?.fieldTree?.map((templateField, index) => {
      return renderOuterField(templateField, `encounter-note-field-${index}`, additionalFields)
    })
  }, [template, additionalFields])

  if (!template) {
    return <EmptyList message={"Select an encounter note template"} />
  }

  return (
    <Box
      key={'encounter-note-template-fields'}
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: '1 1 100%',
        gap: 1,
        mb: 1
      }}
    >
      { view }
    </Box>
  )
}