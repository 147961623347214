import React from "react";
import { StatusBadge, TableListRowConfig, Typography } from "saga-library/src";
import { AbClaim } from '../../../../types/billing';
import { useTheme } from "@mui/material";
import { ABClaimAssessmentOutcome } from "../../../../utils/ABClaimConstants";
import { useFormattedDate } from "../../../../hooks/FormattedDate";
import { capitalizedWord } from "../../../../utils/StringHelpers";
import { NameAndIdentifier } from "saga-library/src/components/TableList/CellBlocks/NameAndIdentifier";

export const AssessedClaimRow = (
  claim: AbClaim,
  onChange: (claim_id: string) => void,
  isRowSelected: boolean,
  onRowClick: (claim: AbClaim) => void
): TableListRowConfig => {

  return {
    key: claim.id,
    onClick: ()=>{
      onRowClick(claim)
    },
    onRowSelectionChange: () => {
      if (onChange) {
        onChange(claim.id)
      }},
    isRowSelected: isRowSelected,
    rowData: [
      {
        children: (
          <NameAndIdentifier
            name={`${claim.patient?.lastName}, ${claim.patient?.firstName}`}
            identifier={claim.patient?.primaryIdentifier}
          />
        )
      },
      {
        children: (
          <Typography variant={'body2'}>{useFormattedDate(claim.serviceDate)}</Typography>
        )
      },
      {
        children: (
          <Typography variant={'body2'}>{claim.serviceCode.serviceCode}</Typography>
        )
      },
      {
        children: (
          <Typography variant={'body2'}>{claim.diagnosticCodes?.map((dc) => {
            return dc?.diagnosticCode
          })}</Typography>
        )
      },
      {
        children:(
          <NameAndIdentifier
            name={`${claim.billingProfile?.practitionerLastName}, ${claim.billingProfile?.practitionerFirstName}`}
            identifier={claim.billingProfile?.name}
          />
        )
      },
      {
        children: (
          <Typography variant={'body2'} sx={{ textAlign: 'right' }}>${claim.claimAmount.toFixed(2)}</Typography>
        )
      },
      {
        children: (
          <Typography variant={'body2'} sx={{ textAlign: 'right' }}>${claim.assessmentAmount != null ? `$${claim.assessmentAmount.toFixed(2)}` : ''}</Typography>
        )
      },
      {
        children: (
          <Typography variant={'body2'}>{claim.claimIdentifier}</Typography>
        )
      },
      {
        children: (
          <AssessmentStatusBadge
            claim={claim}
            sx={{ borderRadius: '16px' }}
          />
        ),
      },
      {
        children: (
          <Typography variant={'body2'}>{claim.explanatoryCodes?.map((dc) => {
            return dc?.explanatoryCode
          })}</Typography>
        )
      },
    ]
  }
}

export const AssessmentStatusBadge = ({claim, sx={}}) => {
  const theme = useTheme()
  const statusColors = {
    ADJUSTED: theme.palette.warning.dark,
    REFUSED: theme.palette.error.main,
    PAID: theme.palette.primary.main,
    HELD: theme.palette.greys.medium
  }

  const text = claim.assessmentOutcome === ABClaimAssessmentOutcome.APPLIED ? (claim.adjusted ? "ADJUSTED" : "PAID") : claim.assessmentOutcome

  return (
    <StatusBadge
      text={capitalizedWord(text)}
      color={statusColors[text]}
      sx={sx}
    />
  )
}
