import React from "react";
import { useParams } from "react-router-dom";
import { useBackgroundQuery, QueryReference, useMutation } from '@apollo/client'
import {
  GET_PATIENT_OTHER_DOCUMENTS,
  UPDATE_LINKED_DOCUMENT
} from '../../../graphql-definitions';
import { LinkedDocumentType, UpdateLinkedDocumentInput } from '../../../types/Document'
import { useAlerts } from "saga-library/src/providers/Alerts";
import _get from "lodash/get";
import { updateCacheByCategory } from "../util/PatientUtils";
import { LinkedDocumentSortPredicate } from '../components/LinkedDocumentForm/LinkedDocumentUtil'
import { useDocumentContext } from '../../../providers/DocumentProvider'
import { usePatientDocumentStateContext } from './PatientDocumentStateProvider'
import { saveDocumentChanges } from '../../tasks/documents/components/ImportedDocumentsActionProvider'

const getLinkedDocumentQueryResults = (data) => {
  return [..._get(data, 'tenant.patient.document.otherDocuments', []) as Array<LinkedDocumentType>]
}

export const parseLinkedDocumentQueryResults = (data) => {
  return getLinkedDocumentQueryResults(data).sort(LinkedDocumentSortPredicate)
}

interface PatientDocumentContextInterface {
  updateLinkedDocument: (
    documentId: string,
    document: UpdateLinkedDocumentInput,
    categoryChanged: boolean,
    patientChanged: boolean,
    onSuccess?: (documentResult: UpdateLinkedDocumentInput) => void,
    successMessage?: string,
    errorMessage?: string
  ) => Promise<void>,
  documentQueryRef: QueryReference | null
  getLinkedDocumentQueryResults: (any) => LinkedDocumentType[]
  parseLinkedDocumentQueryResults: (any) => LinkedDocumentType[]
}

const defaultPatientDocumentContext: PatientDocumentContextInterface = {
  updateLinkedDocument: (
    documentId: string,
    document: UpdateLinkedDocumentInput,
    categoryChanged: boolean,
    patientChanged: boolean,
    onSuccess?: (documentResult: UpdateLinkedDocumentInput) => void,
    successMessage?: string,
    errorMessage?: string
  ) => Promise.resolve(),
  documentQueryRef: null,
  getLinkedDocumentQueryResults: getLinkedDocumentQueryResults,
  parseLinkedDocumentQueryResults: parseLinkedDocumentQueryResults
}


const PatientDocumentContext = React.createContext(defaultPatientDocumentContext)

export const PatientDocumentProvider = ({ children }) => {
  const { tenant_id, patient_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const [ updateLinkedDocumentMutation ] = useMutation(UPDATE_LINKED_DOCUMENT)
  const { uploadToAzure, updateFile } = useDocumentContext()
  const { pdfDocRef, documentModified, selectedFile } = usePatientDocumentStateContext()

  const [documentQueryRef] = useBackgroundQuery(GET_PATIENT_OTHER_DOCUMENTS, {
    variables: {
      patientId: patient_id,
      tenantId: tenant_id
    }
  })

  const updateLinkedDocument = async (documentId, document, categoryChanged, patientChanged, onSuccess, successMessage, errorMessage) => {
    if (documentModified){
      await saveDocumentChanges(documentModified, pdfDocRef, selectedFile, updateFile, uploadToAzure).then((updatedFile) => {
        if (!updatedFile) {
          showErrorAlert("Document changes couldn't be saved. Document was not linked.")
          return Promise.reject()
        }
      }).catch(() => {
        showErrorAlert("Document changes couldn't be saved. Document was not linked.")
        return Promise.reject()
      })
    }

    await updateLinkedDocumentMutation({
      variables: {
        tenantId: tenant_id,
        linkedDocumentId: documentId,
        input: document
      },
      onCompleted: (data) => {
        const documentResult = data.tenant.patient.linkedDocument.update
        if (successMessage) {
          showSuccessAlert(successMessage)
        } else if (patientChanged) {
          showSuccessAlert("Document saved and moved to new patient.")
        } else if (categoryChanged) {
          showSuccessAlert("Document saved and moved to new category.")
        } else {
          showSuccessAlert("Document has been saved.")
        }
        if (onSuccess) {
          onSuccess(documentResult)
        }
      },
      onError: (error) => {
        console.error("Error saving document", error)
        showErrorAlert(errorMessage || "Document couldn't be saved.")
       },
      update: async(cache, { data }) => {
        let updatedDocument: LinkedDocumentType = data.tenant.patient.linkedDocument.update
        updateCacheByCategory(cache, updatedDocument, tenant_id, patient_id)

        if (document.reviewUserId) {
          updatedDocument.reviews?.forEach((review) => {
            cache.modify({
              id: cache.identify({
                id: review.id,
                __typename: 'ReviewDocument'
              }),
              fields: {
                isReviewed() {
                  return review.isReviewed
                }
              }
            })
          })
        }
      }
    })
  }

  const providerValues = {
    documentQueryRef,
    updateLinkedDocument,
    getLinkedDocumentQueryResults,
    parseLinkedDocumentQueryResults
  }

  return (
    <PatientDocumentContext.Provider value={providerValues}>
      { children }
    </PatientDocumentContext.Provider>
  )
}

export const usePatientDocumentContext = () => {
  return React.useContext(PatientDocumentContext)
}

export const moveDocumentToOtherDocument = async (cache, document, tenantId, patientId) => {
  try {
    await cache.updateQuery({
      query: GET_PATIENT_OTHER_DOCUMENTS,
      variables: {
        tenantId,
        patientId
      }
    }, (data) => {
      const existingDocuments = _get(data, 'tenant.patient.document.otherDocuments', []).filter(d => d.id !== document.id)

      return {
        tenant: {
          patient: {
            document: {
              otherDocuments: [...existingDocuments, document]
            }
          }
        }
      }
    })
  } catch (error) {
    console.log(error)
  }
}