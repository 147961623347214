import { Box } from '@mui/material'
import { FilterSelect, LoadingButton, Section } from 'saga-library/src'
import React from 'react'
import { DocumentCategory, GetDocumentCategory } from '../../../../types/Document'
import { ReviewDocument } from '../../../../types/inbox/ReviewDocument'

interface DocumentsHeaderProps {
  setFilters: (event: any) => void,
  dataTestId: string,
}

export const DocumentsHeader = ({
  setFilters,
  dataTestId
}: DocumentsHeaderProps) => {

  const documentCategoryOptions = [
    { label: "All categories", value: "*" },
    ...Object.values(DocumentCategory).map((category) => {
      return {
        label: GetDocumentCategory(category),
        value: category
      }
    })
  ]

  const reviewOptions = [
    { label: 'Unreviewed', value: 'unreviewed' },
    { label: 'Reviewed', value: 'reviewed' },
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 1
      }}
    >
      <FilterSelect
        options={documentCategoryOptions}
        onSelect={(event) => setFilters((filters) => ({...filters, category: event.value}))}
        defaultSelectedValue={'*'}
        dataTestId={`${dataTestId}-list-documents-filter`}
      />
      <FilterSelect
        options={reviewOptions}
        onSelect={(event) => setFilters((filters) => ({...filters, reviewed: event.value}))}
        defaultSelectedValue={'unreviewed'}
        dataTestId={`${dataTestId}-list-documents-review-filter`}
      />
    </Box>
  )
}


interface DocumentResultHeaderProps {
  reviewDocument?: ReviewDocument,
  loading: boolean,
  loadingMarkReviewed: boolean,
  formName: string,
  formSubmitting?: boolean,
  onMarkAsReviewedClicked: () => void,
}

export const DocumentResultHeader = ({
  reviewDocument,
  formName,
  onMarkAsReviewedClicked,
  formSubmitting,
  loading,
  loadingMarkReviewed
}: DocumentResultHeaderProps) => {
  const dataTestId = 'document-result-header'

  if (loading) {
    return <></>
  }

  if (reviewDocument) {
    return (
      <Box sx={{ display: 'flex', mb: 1, alignItems: 'center', justifyContent: 'space-between' }}>
        <Section.Header
          dataTestId={`${dataTestId}-sectionLabel`}
          sx={{mb: 0}}
        >
          {reviewDocument?.linkedDocument?.description || GetDocumentCategory(reviewDocument?.linkedDocument?.category)}
        </Section.Header>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          <LoadingButton
            form={formName}
            type={'submit'}
            name={'saveButton'}
            variant={'outlined'}
            loading={formSubmitting}
            disabled={loading || loadingMarkReviewed}
            data-testid={`${dataTestId}-save-button`}
          >
            SAVE
          </LoadingButton>
          { !reviewDocument.isReviewed && <LoadingButton
            form={formName}
            type={'button'}
            name={'markAsReviewedButton'}
            variant={'contained'}
            loading={loadingMarkReviewed}
            disabled={loading || formSubmitting}
            onClick={onMarkAsReviewedClicked}
            data-testid={`${dataTestId}-mark-reviewed-button`}
          >
            MARK AS REVIEWED
          </LoadingButton>}
        </Box>
      </Box>
    )
  }
  return <></>
}