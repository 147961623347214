import React, { useCallback, memo } from 'react'
import {
  CalendarNext,
  CalendarPrev,
  Eventcalendar,
  formatDate,
  MbscCalendarEventData,
  MbscEventClickEvent,
  MbscCalendarEvent,
} from '@mobiscroll/react'
import '@mobiscroll/react/dist/css/mobiscroll.min.css'
import { Box, useTheme } from '@mui/material'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import { EmptyList, Typography } from 'saga-library/src'
import { momentTimezone } from '@mobiscroll/react'
import { default as momentTZ } from 'moment-timezone'
import { LoadingSpinner } from 'saga-client/src/components/LoadingScreen'

interface AgendaProps {
  headerActions?: React.ReactNode
  timezone: string | null
  events?: MbscCalendarEvent[]
  renderEvent: (data: MbscCalendarEventData) => React.ReactNode
  onSelectedDateChanged: (event: MbscEventClickEvent, data: Eventcalendar) => void
  date: number | string | Date | null
  loading?: boolean
  paddingRight?: number
}

export const Agenda = memo(({
  headerActions,
  timezone,
  events = [],
  renderEvent,
  onSelectedDateChanged,
  date,
  loading,
  paddingRight = 0
}: AgendaProps) => {
  const theme = useTheme()
  const agendaRef = React.useRef(null)

  momentTimezone.moment = momentTZ

  const agendaHeader = useCallback(() => {
    if (!date) {
      return <></>
    }

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          "& .mbsc-calendar-button": {
            color: `${theme.palette.primary.main} !important`,
            fontFamily: "Inter, sans-serif !important",
            textTransform: "capitalize",
            fontSize: "16px",
            fontStyle: "normal",
            lineHeight: "20px",
          },
          "& .mbsc-calendar-button:hover": {
            backgroundColor: "#f6f9f8 !important",
          }
        }}
        data-testid={"agenda-header"}
      >
        {headerActions}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex" }} data-testid={"agenda-prev"}><CalendarPrev className={"mbsc-calendar-button"} /></Box>
          <Box sx={{ display: "flex" }} data-testid={"agenda-date"}>
            <Typography variant={"subtitle1"} color={"primary"}>
              {formatDate('DDDD, MMMM DD YYYY', momentTZ(date).toDate())}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }} data-testid={"agenda-next"}><CalendarNext className={"mbsc-calendar-button"} /></Box>
        </Box>
      </Box>
    )
  }, [date])

  const emptyAgenda = useCallback(() => {
    if (loading) {
      return <LoadingSpinner />
    }
    return (
      <Box sx={{ display: "flex", height: "100%" }}>
        <EmptyList icon={CalendarMonthOutlinedIcon} message={"No appointments or events today."} sx={{ height: "100%", justifyContent: "center" }} />
      </Box>
    )
  }, [loading])

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        position: "relative",
        overflow: "hidden",
        "& .mbsc-calendar-controls": {
          p: 0,
          pb: "10px"
        },
        "& .mbsc-material.mbsc-calendar-wrapper:after": {
          display: "none"
        },
        "& .mbsc-schedule-date-header": {
          display: "none"
        },
        "& .mbsc-event-list": {
          overflow: "auto",
          pr: paddingRight
        },
        "& .mbsc-list": {
          display: "flex",
          flexDirection: "column",
          height: "100%"
        },
        "& .mbsc-list > div": {
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          height: "100%"
        },
        "& .mbsc-event.mbsc-list-item": {
          m: 0,
          p: 0,
          minHeight: "48px",
          flexShrink: 0
        },
        "& .mbsc-event.mbsc-list-item:hover .md-custom-event-cont": {
          opacity: 1
        },
        "& .mbsc-event.mbsc-list-item:hover .md-custom-event-cont .md-custom-event-button": {
          visibility: "visible"
        },
        "& .mbsc-event.mbsc-list-item.mbsc-focus": {
          boxShadow: "none"
        }
      }}
    >
      <Eventcalendar
        ref={agendaRef}
        data={events}
        showEventTooltip={false}
        renderHeader={agendaHeader}
        renderEvent={renderEvent}
        renderAgendaEmpty={emptyAgenda}
        view={{
          agenda: {
            type: 'day'
          }
        }}
        theme={"material"}
        themeVariant={"light"}
        actionableEvents={false}
        onSelectedDateChange={onSelectedDateChanged}
        dataTimezone={'utc'}
        displayTimezone={timezone as string}
        timezonePlugin={momentTimezone}
        invalidateEvent="strict"
      />
    </Box>
  )
})