import { useAccountContext } from '../../../../providers/AccountContext'
import React, { useEffect, useRef, useState } from 'react'
import { useFormattedDate } from '../../../../hooks/FormattedDate'
import moment from 'moment-timezone'
import { Box, ListItem } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import { EditIconButton, RemoveButton, Typography, Button, TextField } from 'saga-library/src'
import { userDisplayName } from 'saga-library/src/util/formatting'
import { useFormContext } from 'saga-library/src/components/Form'
import { CommentStateType } from '../../../../types/tasks/TaskAssignment'
import { usePrompt } from '../../../../providers/NavigationPrompt'
import { flushSync } from 'react-dom'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { DeletedListItem } from './DeletedListItem'

export const CommentListItem = ({
  taskComment,
  dataTestId,
  index,
  editComment,
  onEdit,
  onCancel:onParentCancel
}) => {
  const { userId } = useAccountContext()
  const [initialValue, setInitialValue] = useState()
  const [ toBeDeleted, setToBeDeleted] = useState<boolean>(false)
  const [ openPrompt, setOpenPrompt] = useState<boolean>(false)
  const { setValue, getValues } = useFormContext()
  const { enableNavigationPrompt } = usePrompt()

  const itemName = `task_comment_${taskComment.id}`
  useEffect(()=>{
    setInitialValue(taskComment)
  },[taskComment.version])

  useEffect(() => {
    enableNavigationPrompt(openPrompt, itemName, undefined, openPrompt, resetComment)
    return () => enableNavigationPrompt(false,itemName)
  }, [openPrompt])

  const {comment, id, audit} = taskComment
  const {creUserLastName, creUserFirstName, creUserId} = audit

  const getUserInitials = () => {
    if(creUserLastName && creUserFirstName){
      return creUserFirstName.trim().charAt(0) + creUserLastName.trim().charAt(0)
    }
  }

  const onCancel = () => {
    resetComment()
  }

  const onDelete = () => {
    setToBeDeleted(true)
    setValue(`comments.${index}`, {...(getValues(`comments.${index}`)), state: CommentStateType.DELETED}, {shouldDirty: true})
  }

  const onRestore = () => {
    setToBeDeleted(false)
    resetComment()
  }

  const resetComment = () => {
    setValue(`comments.${index}`,initialValue)
    onParentCancel()
    flushSync(() => {
      setOpenPrompt(false)
    })
  }

  const EditView = () => {
    const inputRef = useRef<HTMLInputElement>(null)
    const currentValue = getValues(`comments.${index}.comment`)

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.setSelectionRange(currentValue.length, currentValue.length);
      }
    }, [inputRef.current])

    return (
      <CommentContainer
        audit={audit}
        actions={
          <>
            <Button
              name={'cancel_edit'}
              variant={'text'}
              onClick={onCancel}
              size={'small'}
              sx={{py:0, height:'auto'}}
              dataTestId={`cancel-edit-comment-${id}`}
            >
              Cancel
            </Button>
          </>
        }
      >
        <TextField
          name={`comments.${index}.comment`}
          label={''}
          multiline={true}
          rows={3}
          fullWidth={true}
          dataTestId={`${dataTestId}-comment`}
          inputRef={inputRef}
          autoFocus={true}
          onChange={()=> setValue(`comments.${index}`, {...(getValues(`comments.${index}`)), state: CommentStateType.UPDATED})}
        />
      </CommentContainer>
    )
  }

  const CommentView = () => {
    return (
      <CommentContainer
        audit={audit}
        actions={
          <>
            {creUserId === userId && <Box
              className={"comment_actions"}
              sx={{ gap: 1 }}
            >
              <EditIconButton
                onClick={onEdit}
                dataTestId={`${dataTestId}_edit_comment`}
                sx={{height: 24, width: 24, flex:"0 0 auto"}}
              />

              <RemoveButton
                onClick={onDelete}
                dataTestId={`${dataTestId}_delete_comment`}
                sx={{height: 24, width: 24, flex:"0 0 auto"}}
              />
            </Box>}
          </>
        }
      >
        <Typography> { comment }</Typography>
      </CommentContainer>
    )
  }

  if(toBeDeleted){
    return (
      <DeletedListItem dataTestId={`${dataTestId}-comment`} onRestore={onRestore} />
    )
  }

  return (
    <ListItem
      data-testid={`${dataTestId}_comment`}
      sx={{
        gap:1,
        alignItems:"start",
        borderRadius:"8px",
        ".comment_actions": {
          display:"none"
        },
        "&:hover": {
          backgroundColor: 'backgrounds.hover',
          ".comment_actions": {
            display: 'flex',
          }
        },
        backgroundColor: editComment ? 'backgrounds.hover' : "inherit",
        mb:1,
        p: "4px"
      }}
    >
      <Avatar>
        {getUserInitials()}
      </Avatar>
      {editComment ? <EditView /> : <CommentView />}
    </ListItem>
  )
}

const CommentContainer = ({audit, actions, children}) => {
  const {crePit, luPit, creUserFirstName, creUserLastName } = audit
  const formattedDate = useFormattedDate(crePit, true)
  const getTimeFromNow = () => {
    const dateHoursDiff = moment().diff(moment(crePit), 'hours')

    if(dateHoursDiff > 22){
      return formattedDate
    }

    return moment(crePit).fromNow()
  }

  const editState = () => {
    if(luPit !== crePit){
      return "(Edited)"
    }
    return ""
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: "flex", gap: 1, mb: "4px", alignItems: 'center' }}>
        <Box>
          <Typography variant={'h5'} lineclamp={1}>{ userDisplayName(creUserFirstName, creUserLastName) }</Typography>
          <Typography variant={'p2'} sx={{ color: 'greys.medium' }} lineclamp={1}>{ `${getTimeFromNow()} ${editState()}` }</Typography>
        </Box>
        <Box sx={{ flex: "1 1 auto", display: 'flex', justifyContent: "end" }}>
          {actions}
        </Box>
      </Box>
      { children }
    </Box>
  )
}