import React, {createContext, useState, useEffect} from "react";
import {AbLabResult} from "../types/patients";
import {FileType} from "../types/tasks";
import {useLazyQuery} from "@apollo/client";
import {GET_HISTORICAL_LAB_AND_INVESTIGATION} from "../graphql-definitions";
import _get from "lodash/get";
import {useParams} from "react-router-dom";

interface LabAndInvestigationStateContextType {
  labAndInvestigation: AbLabResult | null,
  setLabAndInvestigation: (labAndInvestigation: AbLabResult | null) => void,
  selectedLabAndInvestigation: AbLabResult | null, // this can either be labAndInvestigation or an old version of it
  setSelectedLabAndInvestigation: (labId: string) => void,
  files: FileType[],
  selectedFile: FileType | null,
  setSelectedFile: (file: FileType | null) => void
}

const defaultLabAndInvestigationContext : LabAndInvestigationStateContextType = {
  labAndInvestigation: null,
  setLabAndInvestigation: () => {},
  selectedLabAndInvestigation: null,
  setSelectedLabAndInvestigation: () => {},
  files: [],
  selectedFile: null,
  setSelectedFile: () => {}
}

const LabAndInvestigationStateContext = createContext(defaultLabAndInvestigationContext)

interface LabAndInvestigationStateProviderProps {
  children: React.ReactNode,
  labAndInvestigation: AbLabResult | null
}

export const LabAndInvestigationStateProvider = ({ children, labAndInvestigation: inputLabAndInvestigation }:LabAndInvestigationStateProviderProps) => {
  const [labAndInvestigation, setLabAndInvestigation] = useState<AbLabResult | null>(inputLabAndInvestigation)
  const [selectedLabAndInvestigation, setSelectedLabAndInvestigation] = useState<AbLabResult | null>(inputLabAndInvestigation)
  const [files, setFiles] = useState<FileType[]>([])
  const [selectedFile, setSelectedFile] = useState<FileType | null>(null)
  const { tenant_id } = useParams()

  const loadSelectedLab = (lab: AbLabResult | null) => {
    setSelectedLabAndInvestigation(lab)

    const resultsWithEmbeddedFile = lab?.abLabResultObservationResults
        ?.filter(r => r.file)
        ?.map(r => r.file!)
      ?? []

    setFiles(resultsWithEmbeddedFile)
    resultsWithEmbeddedFile.length > 0 ? setSelectedFile(resultsWithEmbeddedFile[0]) : setSelectedFile(null)
  }

  const [getHistoricalLabAndInvestigation] = useLazyQuery(GET_HISTORICAL_LAB_AND_INVESTIGATION, {
    onCompleted: async (data) => {
      const lab: AbLabResult = _get(data, 'tenant.labAndInvestigationHistory.get', null)
      loadSelectedLab(lab)
    },
    onError: error => {
      console.error(JSON.stringify(error, null, 2))
    }
  })

  const changeSelectedLabAndInvestigation = async (labId: string) => {
    if (!labAndInvestigation) return
    if (selectedLabAndInvestigation?.id === labId) return

    if (labAndInvestigation.id === labId) {
      loadSelectedLab(labAndInvestigation)
      return
    }

    await getHistoricalLabAndInvestigation({
      variables: {
        tenantId: tenant_id,
        id: labId
      }
    })
  }

  useEffect(() => {
    if (inputLabAndInvestigation) {
      setLabAndInvestigation(inputLabAndInvestigation)
    }
  }, [inputLabAndInvestigation])

  useEffect(() => {
    loadSelectedLab(labAndInvestigation)
  }, [labAndInvestigation])

  const providerValue : LabAndInvestigationStateContextType = {
    labAndInvestigation,
    setLabAndInvestigation,
    selectedLabAndInvestigation,
    setSelectedLabAndInvestigation: changeSelectedLabAndInvestigation,
    files,
    selectedFile,
    setSelectedFile
  }

  return (
    <LabAndInvestigationStateContext.Provider value={providerValue}>
      { children }
    </LabAndInvestigationStateContext.Provider>
  )
}

export const useLabAndInvestigationStateContext = () => {
  return React.useContext(LabAndInvestigationStateContext)
}