import React, { useState } from 'react'
import { Box, Collapse } from '@mui/material'
import { LoadingButton, Section, TextField, Button, Typography, Form } from 'saga-library/src'
import { Logo } from '../../../components/Logo'
import { useForm, FormProvider } from "saga-library/src/components/Form"
import * as yup from "yup"
import { useSagaAuthentication } from '../../../providers/Auth'
import { useNavigate } from 'react-router-dom'

function ForgotPasswordForm() {
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const { performForgotPassword, friendlyErrorMessage, clearErrors } = useSagaAuthentication()
  const navigate = useNavigate();

  const schema = yup.object().shape({
    email: yup.string().email().required()
  }).required();

  const formMethods = useForm<FormValues>({
    schema: schema
  })

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = formMethods

  const onSubmit = (data) => {
    clearErrors()

    return performForgotPassword(data)
      .then((success) => {
        if (success) setEmailSent(true)
      })
  }

  return (
    <FormProvider {...formMethods}>
      <Box sx={{ mt: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Form id={'forgot-password-form'} onSubmit={handleSubmit(onSubmit)}>
          <Section.Column>
            <Box sx={{ pl: 2, pr: 3, display: 'flex', flexDirection: 'column', mt: 2, justifyContent: 'center', alignItems: 'center' }}>
              <Logo sx={{ width: 180, height: 70 }} />
              <Section.Header sx={{ mt: 2 }}>
                Forgot Password
              </Section.Header>
              {emailSent ? 
                <>
                  <Typography variant={'body1'}>
                    Please check your email inbox for the reset password link.
                  </Typography>
                  <Box sx={{ my: 2 }}>
                    <Button
                      name={'Ok'}
                      type={'button'}
                      variant={'contained'}
                      onClick={() => { navigate('/signin') }}
                      dataTestId={'forgot-password-ok-button'}
                    >
                      Ok
                    </Button>
                  </Box>
                </>
               : 
                <>
                  <Typography sx={{ mt: 2, mb: 1 }} variant={'body1'}>
                    Enter your email address for a reset password link.
                  </Typography>
                  <TextField
                    label={'Email address'}
                    sx={{ width: 280 }}
                    name={'email'}
                    dataTestId={'forgot-password-email'}
                  />
                  <Collapse in={!!friendlyErrorMessage} sx={{ color: "#C41923" }}>
                    {friendlyErrorMessage}
                  </Collapse>
                  <Box sx={{ my: 2 }}>
                    <LoadingButton
                      name={'ForgotPasswordButton'}
                      type={'submit'}
                      loading={isSubmitting}
                      variant={'contained'}
                      dataTestId={'forgot-password-submit-button'}
                    >
                      Send Email
                    </LoadingButton>
                  </Box>
                </>
              }
            </Box>
          </Section.Column>
        </Form>
      </Box>
    </FormProvider>
  )
}

type FormValues = {
  email?: string
}

export default ForgotPasswordForm