import React, { useEffect } from "react";
import {
  DialogV2,
  LineChart,
  SimpleStartEndDatePicker,
  TableList,
  TableListRowConfig,
  Typography
} from 'saga-library/src'
import { AbLabResultObservationResult } from "../../../../types/patients";
import { useLazyQuery } from "@apollo/client";
import { GET_HISTORICAL_LAB_VALUES } from "../../../../graphql-definitions";
import { useParams } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import _get from "lodash/get";
import { useFormattedDate } from "../../../../hooks/FormattedDate";
import { parseReferenceRange } from "./LabAndInvestigationsUtil";
import { LabAndInvestigationResultValue } from "./components/LabAndInvestigatioResultValue";
import { MarkElement as MuiMarkElement } from "@mui/x-charts"
import _uniqWith from "lodash/uniqWith";
import {ReactComponent as WarningAmberFilledIcon} from "../../../../assets/WarningAmberFilledIcon.svg";
import { DATE_FORMAT } from "../../../../utils/SettingsConstants";
import { useAccountContext } from "../../../../providers/AccountContext";
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { LoadingSpinner } from '../../../../components/LoadingScreen'

export interface LabValueGraphProps {
  result: AbLabResultObservationResult | null
  setResult: (a: AbLabResultObservationResult | null) => void
  dataTestId: string
}

export const LabValueGraph = ({
  result,
  setResult,
  dataTestId
}:LabValueGraphProps) => {
  const { tenant_id } = useParams()
  const { patient_id } = useParams()
  const theme = useTheme()
  const { getUserSetting } = useAccountContext()

  const [ observationValues, setObservationValues ] = React.useState<AbLabResultObservationResult[]>([])
  const [ observationValuesToGraph, setObservationValuesToGraph ] = React.useState<AbLabResultObservationResult[]>([])
  const [ referenceRange, setReferenceRange ] = React.useState<number[]>([])
  const [ minDateRange, setMinDateRange ] = React.useState<Date | null>(null)
  const [ maxDateRange, setMaxDateRange ] = React.useState<Date | null>(null)

  const getRows = () => {
    return observationValues
      .map((result, index) =>
        ObservationResultRow(result, index, `${dataTestId}-row-${index}`)
      )
  }
  
  const [ getLabValues, { loading } ] = useLazyQuery(GET_HISTORICAL_LAB_VALUES, {
    onCompleted: (data) => {
      const values = _get(data, 'tenant.patient.labAndInvestigation.historicalLabValues', []) as AbLabResultObservationResult[]
      setObservationValues(values)

      const valuesToGraph = values.filter(v => v.observationDate && v.observationValue)
      const uniqueValuesToGraph = _uniqWith(valuesToGraph, (a, b) =>
        a.observationValue === b.observationValue && a.observationDate?.valueOf() === b.observationDate?.valueOf()
      )
      setObservationValuesToGraph(uniqueValuesToGraph.reverse())
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (result && result.id) {
      getLabValues({
        variables: {
          tenantId: tenant_id,
          patientId: patient_id,
          resultId: result.id
        },
      })
      setReferenceRange(result?.referenceRange ? parseReferenceRange(result.referenceRange) : [])
    } else {
      setObservationValues([])
      setObservationValuesToGraph([])
      setReferenceRange([])
      setMinDateRange(null)
      setMaxDateRange(null)
    }
  }, [getLabValues, result, patient_id, tenant_id])

  const MarkElement = (props) => {
    const { dataIndex, ...other } = props

    if ((observationValuesToGraph?.[dataIndex - 1]?.abLabResultObservationResultAbnormals?.length ?? 0) > 0) {
      return <WarningAmberFilledIcon
        width={'24px'}
        height={'24px'}
        {...other}
        x={`${parseInt(other.x) - 12}px`}
        y={`${parseInt(other.y) - 12}px`}
        color={theme.palette.error.main}
        fill={'white'}
      />
    } else {
      return <MuiMarkElement {...other} />
    }
  }

  const DisplayGraphBody = () => {
    if (loading) {
      return <LoadingSpinner />
    }
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          height: "458px"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: '4px',
            mt: 1
          }}
        >
          <SimpleStartEndDatePicker
            startDate={minDateRange}
            setStartDate={d => setMinDateRange(d)}
            endDate={maxDateRange}
            setEndDate={d => setMaxDateRange(d)}
            dateFormat={getUserSetting(DATE_FORMAT) as string}
            dataTestId={`${dataTestId}-dateRange`}
            sx={{width: "395px"}}
          />
          <TableList
            columns={[{name: ''}]}
            rows={getRows()}
            showHeader={false}
            tableSx={{mt: 2, pr: 0}}
            dataTestId={`${dataTestId}-table`}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", gap: 2, pl: 6 }}>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: 'center',
                stroke: theme.palette.primary.main,
                strokeWidth: 2
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="44" height="14" viewBox="0 0 44 14" fill="none">
                <line y1="7.39355" x2="16" y2="7.39355" />
                <line x1="28" y1="7.39355" x2="44" y2="7.39355" />
                <circle cx="21.65" cy="7.04355" r="5.65" />
              </svg>
              <Typography variant={'body1'}>Recorded value</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: 'center'
              }}
            >
              <WarningAmberFilledIcon
                width={'24px'}
                height={'24px'}
                color={theme.palette.error.main}
                fill={'white'}
              />
              <Typography variant={'body1'}>Abnormal value</Typography>
            </Box>
          </Box>
          <Box sx={{ height: "calc(100% - 24px)" }}>
            <LineChart
              data={[{
                label: "Lab",
                values: observationValuesToGraph.map(v => {
                  return {value: parseFloat(v.observationValue!), date: new Date(v.observationDate!.valueOf())}
                })
              }]}
              min={minDateRange}
              max={maxDateRange}
              referenceMin={referenceRange.length === 2 ? referenceRange[0] : undefined}
              referenceMax={referenceRange.length === 2 ? referenceRange[1] : undefined}
              slots={{mark:MarkElement}}
              slotProps={{legend: {hidden: true}}}
            />
          </Box>
        </Box>
      </Box>
    )
  }

  return <DialogV2
    title={result?.observationDescription ?? ""}
    onClose={() => setResult(null)}
    open={!!result}
    fullWidth={true}
    maxWidth={"lg"}
    showActions={false}
    sx={{px:1, py:0}}
  >
    <DisplayGraphBody />
  </DialogV2>
}

export const ObservationResultRow = (
  result: AbLabResultObservationResult,
  index: number,
  dataTestId: string
): TableListRowConfig => {
  const theme = useTheme()
  const formattedDate = useFormattedDate(result?.observationDate, true)

  const abnormal = (result.abLabResultObservationResultAbnormals?.length ?? 0) > 0

  if(result) {
    return ({
      rowData: [{
        sx: {borderTop: index === 0 ? "1px solid rgba(224, 224, 224, 1)" : ""},
        children: [
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              color: abnormal ? theme.palette.error.main : theme.palette.greys.dark
            }}
            key={result.id!}
          >
            <Typography variant={"body1"}>{formattedDate}</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                gap: 1
              }}
            >
              {abnormal &&
                <WarningAmberIcon />
              }
              <LabAndInvestigationResultValue result={result} dataTestId={dataTestId} />
            </Box>
          </Box>
        ]
      }],
      key: result.id!
    })
  }

  return {rowData: [], key: ""}
}