import { gql } from "@apollo/client";
import {LAB_AND_INVESTIGATION_FRAGMENT} from "./patient/LabAndInvestigationQueries";

export const GET_HISTORICAL_LAB_AND_INVESTIGATION = gql`
  ${LAB_AND_INVESTIGATION_FRAGMENT}
  query GetHistoricalLabAndInvestigation($tenantId: ID!, $id: ID!) {
    tenant(id: $tenantId) {
      labAndInvestigationHistory {
        get(id: $id) {
          ...LabAndInvestigationFragment
        }
      }
    }
  }
`

export const GET_LAB_AND_INVESTIGATION_HISTORY = gql`
  ${LAB_AND_INVESTIGATION_FRAGMENT}
  query GetLabAndInvestigationHistory($tenantId: ID!, $id: ID!) {
    tenant(id: $tenantId) {
      labAndInvestigationHistory {
        list(id: $id) {
          ...LabAndInvestigationFragment
        }
      }
    }
  }
`