import { Box, InputAdornment } from "@mui/material";
import React, { Suspense, useEffect, useRef, useState } from 'react'
import {
  TextField,
  ToggleButtonGroup,
  IconButton,
  Typography,
  Switch
} from "saga-library/src";
import CalculateOutlined from "@mui/icons-material/CalculateOutlined";
import FormattedDatePicker from "../../../../../components/FormattedDatePicker";
import { DosageManager } from "./DosageManager";
import { DictionarySelect } from "./DictionarySelect";
import { useStaticDataContext } from "../../../../../providers/StaticDataContext";
import { PermissionType } from "../../../../../types/settings/Permission";
import { PermissionForm } from "../../../../../components/PermissionForm";
import { PharmacyBlock } from "../../../../../components/SearchControls/Pharmacy/PharmacyArray";
import { Pharmacy } from "../../../../../types";
import { PrescriptionType, PrescriptionUsage } from "../../../../../types/Prescription";
import { usePrescriptionUtils } from "./PrescriptionUtil";
import { useFormContext } from "saga-library/src/components/Form";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { DrugSearch } from "./DrugSearch";
import TabFocusInterceptor from "saga-library/src/hooks/tabFocusInterceptor";
import { usePrescriptionContext } from "../../../providers/PrescriptionProvider";
import { usePatientProfileContext } from '../../../providers/PatientProfileProvider'
import { useReadQuery } from '@apollo/client'
import { PatientRelationshipsType } from '../../profile/PatientRelationships'
import { omit } from 'lodash'
import _get from 'lodash/get'
import { LoadingSpinner } from '../../../../../components/LoadingScreen'
import { PractitionerSelectWithLocation } from '../../../../../components/PractitionersSelectWithLocation'

export const PrescriptionForm = ({ formName, onSubmit: parentOnSubmit, formDisabled= false, autoFocus = false}) => {
  const { prescribedQuantityUnit, prescriptionDrugForm, prescriptionRouteOfAdministration, prescriptionAdministrationSite, prescriptionIndicationForUse} = useStaticDataContext()
  const { setValue, getValues } = useFormContext();
  const { showErrorAlert } = useAlerts()
  const { calculateQuantity } = usePrescriptionUtils()
  const { incrementFavouritePrescription } = usePrescriptionContext()
  const [ favouriteSelection, setFavouriteSelection ] = useState<string>('');

  const { patientRelationshipRef } = usePatientProfileContext()
  const { data } = useReadQuery(patientRelationshipRef)
  const patientRelationships: PatientRelationshipsType = omit(_get(data, 'tenant.patient.relationships', null), 'id')
  const pharmacies: Pharmacy[] = patientRelationships.pharmacies || []

  const displayPharmacies = () => {
    if (pharmacies && pharmacies.length > 0) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
          { pharmacies.map((pharmacy) => {
            return (
              <Box key={`${pharmacy.id}-box`}
                   sx={{ padding: '0 16px 8px 0',
                     marginBottom: '18px',
                     marginRight: '16px',
                     borderRight: '1px solid rgba(32, 41, 49, 0.50)',
                     minWidth: '240px',
                   }}
              >
                <PharmacyBlock pharmacy={pharmacy} />
              </Box>
            )
          })
          }
        </Box>
      )
    }
    return <Typography variant={"body1"}>No pharmacies are linked to this patient.</Typography>
  }

  const firstFieldRef = useRef<any>(null);
  const [hasFocus, setHasFocus] = useState(autoFocus);
  const setFocus = (focus: boolean) => {
    setHasFocus(focus)
  }
  TabFocusInterceptor(firstFieldRef, hasFocus, setFocus)

  useEffect(() => {
    if (firstFieldRef && !autoFocus) {
      setHasFocus(false)
    }
  }, [firstFieldRef]);

  const setQuantity = () => {
    const calculatedQuantity = calculateQuantity(getValues() as PrescriptionType)
    if(calculatedQuantity) {
      setValue('quantityAmount', calculatedQuantity)
      setValue('quantityCalculated', true)
    } else {
      setValue('quantityAmount', null)
      setValue('quantityCalculated', false)
      showErrorAlert("Please fill in all dosage fields before calculating the quantity.")
    }
  }

  const onSubmit = (e) => {
    parentOnSubmit(e)
    if (favouriteSelection) {
      incrementFavouritePrescription(favouriteSelection)
      setFavouriteSelection('')
    }
  }

  return <PermissionForm
    name={`${formName}-prescription-form`}
    onSubmit={onSubmit}
    id={formName}
    requiredPermissionType={PermissionType.Chart}
    readOnlyOverride={false}
    formDisabled={formDisabled}
    sx={{
      minWidth:'850px',
      paddingRight: '8px',
    }}
  >
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: "4px" }}>
      <PrescriptionFormRow>
        <DrugSearch
          name={'drug'}
          label={'Prescription'}
          disabled={formDisabled}
          autoFocus={autoFocus}
          sx={{flex:"1 1 100%"}}
          setFavouriteSelection={setFavouriteSelection}
          dataTestId={`${formName}-drug`}
          inputRef={firstFieldRef}
        />
        <Switch name={'historical'} label={"Historical"} sx={{mt:2}} dataTestId={`${formName}-historical`}/>
      </PrescriptionFormRow>

      <PrescriptionFormRow>
        <PractitionerSelectWithLocation
          label={'Prescriber'}
          name={'practitioner'}
          dataTestId={`${formName}-prescriber`}
          disabled={formDisabled}
        />
        <FormattedDatePicker label={'Starts'} name={'startDate'} sx={{minWidth: "165px"}} dataTestId={`${formName}-starts`}/>
        <FormattedDatePicker label={'Ends'} name={'endDate'} sx={{minWidth: "165px"}} dataTestId={`${formName}-ends`}/>
      </PrescriptionFormRow>

      <DosageManager name={'dosages'} disabled={formDisabled} dataTestId={`${formName}-dosages`}/>

      <PrescriptionFormRow >
        <Box sx={{ display: 'flex', gap: 1, flex: "0 0 auto" }}>
          <TextField
            dataTestId={`${formName}-quantity-amount`}
            name={'quantityAmount'}
            label={'Quantity'}
            sx={{flex:"0 0 140px"}}
            regexPattern={/^[1-9]\d*$/}
            onChange={()=>{
              setValue('quantityCalculated', false)
            }}
            slotProps={{
              input: {
                sx:{pr:"4px" },
                endAdornment: <InputAdornment position={'end'} sx={{width:"30px"}}>
                  <IconButton icon={<CalculateOutlined />} onClick={setQuantity}/>
                </InputAdornment>
              }
            }}
          />
          <DictionarySelect
            dataTestId={`${formName}-quantity-units`}
            name={'quantityUnits'}
            label={'Units'}
            dictionary={prescribedQuantityUnit}
            sx={{
              flex:"1 1 175px",
              flexBasis: '175px',
              width: '175px'
            }}
            sortOptions={'asc'}
          />
        </Box>
        <DictionarySelect
          dataTestId={`${formName}-dosage-form`}
          name={'dosageForm'} 
          label={'Dosage form'} 
          dictionary={prescriptionDrugForm} 
          sx={{flex:"1 1 300px"}} 
        />
        <Box sx={{ display: "flex", gap: 1, flex: "0 0 260px" }}>
          <TextField
            dataTestId={`${formName}-refills`}
            name={'refills'} 
            label={'# of refills'} 
            sx={{flex:"0 0 96px"}} 
            type={'number'} 
            regexPattern={/^[1-9]\d*$/} 
          />
          <FormattedDatePicker
            dataTestId={`${formName}-refills-expiry`}
            label={'Refills expire'} 
            name={'refillsExpire'} 
            sx={{flex: "1 1 165px"}}
          />
        </Box>
      </PrescriptionFormRow>

      <PrescriptionFormRow>
        <DictionarySelect dataTestId={`${formName}-route`} name={'route'} label={'Route of administration'} dictionary={prescriptionRouteOfAdministration} sx={{flexBasis:'30%'}}/>
        <DictionarySelect dataTestId={`${formName}-site`} name={'site'} label={'Site of administration'} dictionary={prescriptionAdministrationSite} sx={{flexBasis:'30%'}}/>
        <DictionarySelect dataTestId={`${formName}-indication`} name={'indication'} label={'Indication for use'} dictionary={prescriptionIndicationForUse} sx={{flexBasis:'40%'}} freeSolo/>
      </PrescriptionFormRow>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, my: 1 }}>
        <ToggleButtonGroup
          dataTestId={`${formName}-usage`}
          label={'Continuous use'}
          name={'usage'}
          required
          buttons={[
            { value: PrescriptionUsage.CONTINUOUS, sx:{width: '144px'}, children: "Continuous" },
            { value: PrescriptionUsage.SHORT_TERM, sx:{width: '144px'}, children: "Short Term" },
          ]}
        />
        <ToggleButtonGroup
          dataTestId={`${formName}-substitutions`}
          label={'Substitutions'}
          name={'substitutions'}
          required
          buttons={[
            { value: "GENERIC", sx:{width: '144px'}, children: "Generic" },
            { value: "THERAPEUTIC", sx:{width: '144px'}, children: "Therapeutic" },
            { value: "NO", sx:{width: '144px'}, children: "No" },
          ]}
        />
      </Box>
      <TextField
        dataTestId={`${formName}-additional-instructions`}
        label={'Additional instructions'}
        name={`additionalInstructions`}
        multiline={true}
        fullWidth={true}
      />
      <Typography variant={"h5"}>Patient’s pharmacies</Typography>
      <Suspense fallback={<LoadingSpinner size={"md"} label={"Loading pharmacies..."} />}>
        { displayPharmacies() }
      </Suspense>
    </Box>
  </PermissionForm>
}

export const PrescriptionFormRow = ({ children, sx={} }) => {
  return <Box
    sx={{
      display: 'flex',
      alignItems: 'start',
      gap: 2,
      ...sx
    }}
  >
    {children}
  </Box>
}