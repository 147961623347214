import React from 'react'
import { Button, DialogV2, Typography, RadioGroup, SimpleCheckbox } from 'saga-library/src'
import { Box, Collapse } from '@mui/material'
import { usePatientDocumentStateContext } from '../../providers/PatientDocumentStateProvider'
import { PrintPageInput } from '../../../../components/Print/PrintPageInput'
import { PagesToInclude, PrintType } from '../../../../types/Document'
import { Form, FormProvider, useForm } from 'react-hook-form'
import { PrintTypeSelect } from '../../../../components/Print/PrintTypeSelect'

const FORM_NAME = 'print_pdf_modal_form'

export const PagesToIncludeOptions = [
  {label: "All", value: PagesToInclude.ALL},
  {label: "Current", value: PagesToInclude.CURRENT},
  {label: "Range", value: PagesToInclude.RANGE}
]

export const PrintTypeOptions = [
  {label: PrintType.DOCUMENT, value: PrintType.DOCUMENT},
  {label: PrintType.DOCUMENT_AND_MARKUPS, value: PrintType.DOCUMENT_AND_MARKUPS},
  {label: PrintType.DOCUMENT_AND_STAMPS, value: PrintType.DOCUMENT_AND_STAMPS},
  {label: PrintType.FORM_FIELDS_ONLY, value: PrintType.FORM_FIELDS_ONLY},
]

interface PrintPdfModalProps {
  open: boolean
  onModalClose: () => void
  printPdfCallback: (data: any) => void,
  onPrintClicked?: (printType: number, pageRange: string, pagesToInclude: PagesToInclude) => void
}

export const PrintPdfModal = ({
  open,
  onModalClose,
  printPdfCallback,
  onPrintClicked,
}: PrintPdfModalProps) => {
  const { pdfDocRef, pdfViewerRef, selectedFile } = usePatientDocumentStateContext()

  let totalPageCount = pdfDocRef?.current?.getPageCount()

  const defaultValues = {
    'pagesToInclude': PagesToInclude.ALL,
    'printType': PrintType.DOCUMENT,
    'pageRange': '',
    'reversePages': false,
    'printError': '',
  }

  const formMethods = useForm({
    defaultValues,
  })

  const { watch, setFocus, setValue, reset } = formMethods
  const pagesToInclude = watch('pagesToInclude')
  const pageRange = watch('pageRange')
  const printType = watch('printType')
  const printError = watch('printError')
  const reversePages = watch('reversePages')

  const getPrintType = () => {
    switch (printType){
      case PrintType.DOCUMENT:
        return 0
      case PrintType.DOCUMENT_AND_MARKUPS:
        return 1
      case PrintType.DOCUMENT_AND_STAMPS:
        return 2
      case PrintType.FORM_FIELDS_ONLY:
        return 3
      default:
        return 0
    }
  }

  const printPdf = async () => {
    if (!pdfDocRef?.current || !selectedFile || !pdfViewerRef?.current) {
      setValue('printError', 'There was an issue printing the document.')
      return
    }

    if(pagesToInclude === PagesToInclude.ALL) {
      await pdfViewerRef.current?.printEx({ type: getPrintType(), pageRange: getPageRange(pagesToInclude, totalPageCount, pageRange), progress: true }, (data) => {
        printPdfCallback(data)
      })
    }
    else if(pagesToInclude === PagesToInclude.CURRENT) {
      await pdfViewerRef.current.printCurrentView()
    }
    else if(pagesToInclude === PagesToInclude.RANGE) {
      if(!pageRange) {
        setValue('printError', 'Please enter a page range.')
        return
      }
      await pdfViewerRef.current?.printEx({ type: getPrintType(), pageRange: getPageRange(pagesToInclude, totalPageCount, pageRange), progress: true }, (data) => {
        printPdfCallback(data)
      })
    }

    onModalClose()
    reset(defaultValues)
  }

  return (
    <FormProvider {...formMethods}>
      <Form
        id={FORM_NAME}
        name={FORM_NAME}
        autoComplete={'false'}
        onSubmit={() => {}}
      >
        <DialogV2
          size={'xs'}
          open={open}
          onClose={onModalClose}
          primaryAction={()=>null}
          title={"Print"}
          overridePrimaryComponent={
            <Button
              onClick={async () => {
                if(!!onPrintClicked) {
                  onPrintClicked(getPrintType(), getPageRange(pagesToInclude, totalPageCount, pageRange), pagesToInclude)
                  onModalClose()
                }
                else {
                   await printPdf()
                }
              }}
              type={'button'}
              name={'savePrint'}
              dataTestId={'save-print'}
            >
              Save
            </Button>
          }
        >
          <Box
            sx={{
              gap: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start"
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <RadioGroup
                label={'Pages to include'}
                name={'pagesToInclude'}
                value={pagesToInclude}
                row={false}
                options={PagesToIncludeOptions}
                onChange={(e)=> {
                  setValue('pagesToInclude', e.currentTarget.value)
                  if (e.currentTarget.value === PagesToInclude.RANGE) {
                    setFocus("pageRange")
                  }
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignSelf: 'flex-end',
                flexDirection: 'column',
                width: '50%'
              }}
            >
              <PrintPageInput
                pageCount={totalPageCount}
                disabled={pagesToInclude !== PagesToInclude.RANGE}
                dataTestId={'print-pdf'}
              />
            </Box>
          </Box>
          <Box
            sx={{
              gap: '16px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <SimpleCheckbox
              label={'Reverse pages'}
              value={reversePages}
              dataTestId={'print-pdf-reverse-pages'}
              onChange={() => setValue('reversePages', !reversePages)}
            />
            <PrintTypeSelect
              name={'printType'}
              label={'Items to include'}
              options={PrintTypeOptions}
              dataTestId={'print-type'}
            />
          </Box>
          <Collapse in={!!printError}>
            <Typography sx={{ color: 'error.main' }}>
              {printError}
            </Typography>
          </Collapse>
        </DialogV2>
      </Form>
    </FormProvider>
  )
}

export const getPageRange = (pagesToInclude: PagesToInclude, totalPageCount: number | undefined, pageRange: string) => {
  if(!totalPageCount) {
    totalPageCount = 1
  }
  switch (pagesToInclude){
    case PagesToInclude.ALL:
      return `1-${totalPageCount}`
    case PagesToInclude.RANGE:
      return pageRange
    default:
      return '1'
  }
}