import React from "react";
import { IconImageTooltip, TableListCellConfig, TableListRowConfig, Typography } from 'saga-library/src'
import moment from "moment-timezone";
import { TaskAssignmentType } from '../../../../types/tasks/TaskAssignment'
import { TaskPriorityMap } from '../../../../types/tasks/TaskPriority'
import { TaskCategory } from '../../../../types/tasks/TaskState'
import { SxProps } from "@mui/material";
import { patientDisplayName } from 'saga-library/src/util'

function FormatRowData(task: TaskAssignmentType, dateFormat): TableListCellConfig[] {
  const patientName = task.patient ? patientDisplayName(task.patient.firstName, task.patient.lastName) : ''
  const priority = task.priority ? TaskPriorityMap.get(task.priority) : undefined
  let priorityIcon
  if (priority) {
    priorityIcon = <IconImageTooltip title={priority.label}>
      {priority.icon}
    </IconImageTooltip>
  }

  const isOverdue = task.dueDate && moment(task.dueDate).isBefore(moment(), 'day')
  const isIncomplete = task.state.category === TaskCategory.INCOMPLETE
  let sx: SxProps | undefined
  if (isOverdue && isIncomplete) {
    sx = { display: 'inline', color: 'error.main', backgroundColor: 'backgrounds.error' }
  }

  return [
    {
      data: priority ? priority.order.toString() : '',
      children: priorityIcon
    },
    {
      data: task.state.name,
      children:
        <Typography variant={'body2'}>
          {task.state.name}
        </Typography>
    },
    {
      data: task.type.type,
      children:
        <Typography variant={'body2'}>
          {task.type.type}
        </Typography>
    },
    {
      data: task.name,
      children:
        <Typography variant={'body2'} lineclamp={1} sx={{ minWidth: '150px' }}>
          {task.name}
        </Typography>
    },
    {
      data: patientName,
      children:
        <Typography variant={'body2'} lineclamp={1}>
          {patientName}
        </Typography>
    },
    {
      data: moment(task.startDate).unix().toString(),
      children:
        <Typography variant={'body2'}>
          {moment(task.startDate).format(dateFormat)}
        </Typography>
    },
    {
      data: moment(task.dueDate).unix().toString(),
      children:
        <Typography variant={'body2'} sx={sx}>
          {moment(task.dueDate).format(dateFormat)}
        </Typography>
    },
  ]
}

export const TaskRow = (task: TaskAssignmentType, onClick, dateFormat, isSelected): TableListRowConfig => {
  return ({
    onClick: onClick,
    rowData: FormatRowData(task, dateFormat),
    key: task.id!,
    isRowClicked: isSelected
  })
}