import { ErrorBoundary } from 'saga-library/src'
import { Box } from '@mui/material'
import React, { Suspense } from 'react'
import { LoadingSpinner } from './LoadingScreen'

export const ComponentSuspenseWrapper = ({ children }) => {
  return (
    <ErrorBoundary
      fallback={<Box sx={{ color: "red" }}>Failed to load</Box> }
    >
      <Suspense fallback={<Box sx={{ display: "flex", flexDirection: "column", height: "100%" }} className={"loading"}>
        <LoadingSpinner sx={{height: "100%"}}/>
      </Box>}>
        { children }
      </Suspense>
    </ErrorBoundary>
  )
}