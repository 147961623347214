import React from "react";
import { createSvgIcon } from "@mui/material";
import { ReactComponent as UrgentSVG } from "../../../assets/UrgentIcon.svg";
import { ReactComponent as HighSVG } from "../../../assets/HighIcon.svg";
import { ReactComponent as MediumSVG } from "../../../assets/MediumIcon.svg";
import { ReactComponent as LowSVG } from "../../../assets/LowIcon.svg";
import { ReactComponent as PrescriptionsSVG } from "../../../assets/PrescriptionsIcon.svg";
import { ReactComponent as LabsImagingSVG } from "../../../assets/LabsImagingIcon.svg";
import { ReactComponent as HistorySVG } from "../../../assets/HistoryIcon.svg";
import { ReactComponent as AllergySVG } from "../../../assets/AllergyIcon.svg";
import { ReactComponent as ConditionAndProceduresSVG } from "../../../assets/ConditionAndProceduresIcon.svg";
import { ReactComponent as LetterSVG } from "../../../assets/LettersIcon.svg";
import { ReactComponent as OtherDocumentsSVG } from "../../../assets/OtherDocumentsIcon.svg";
import { ReactComponent as FormsSVG } from "../../../assets/FormsIcon.svg";
import { ReactComponent as ChartingSVG } from "../../../assets/ChartingIcon.svg";
import { ReactComponent as TimelineSVG } from "../../../assets/TimelineIcon.svg";
import { ReactComponent as PractitionerSVG } from "../../../assets/PractitionerIcon.svg";
import { ReactComponent as ChartDataSVG} from "../../../assets/ChartDataIcon.svg";
import { ReactComponent as SignedIconSVG} from "../../../assets/Sign.svg";
import { ReactComponent as PenIconSVG } from "../../../assets/PenIcon.svg";
import { ReactComponent as BirthdayIconSVG } from "../../../assets/Birthday.svg";
import { ReactComponent as RiskAssessmentIcon} from '../../../assets/RiskAssessmentIcon.svg'
import { ReactComponent as ExpandRightSVG } from '../../../assets/ExpandRightIcon.svg'
import { ReactComponent as UnapproveIconSVG } from '../../../assets/UnapproveIcon.svg'
import { ReactComponent as AddVitalSVG } from '../../../assets/AddVitalIcon.svg'
import PrescriptionRxIconSVG from '../../../assets/PrescriptionRxIcon.svg'

export const UrgentIconImage = () => {
  return <UrgentSVG height={'16px'} width={'16px'} />
}

export const UrgentIcon = createSvgIcon(<UrgentSVG height={'24px'} width={'24px'} />, "Urgent")

export const HighIconImage = () => {
  return <HighSVG height={'16px'} width={'16px'} />
}

export const HighIcon = createSvgIcon(<HighSVG height={'24px'} width={'24px'} />, "High")

export const MediumIconImage = () => {
  return <MediumSVG height={'16px'} width={'16px'} />
}

export const MediumIcon = createSvgIcon(<MediumSVG height={'24px'} width={'24px'} />, "Medium")

export const LowIconImage = () => {
  return <LowSVG height={'16px'} width={'16px'} />
}

export const LowIcon = createSvgIcon(<LowSVG height={'24px'} width={'24px'} />, "Low")

export const PrescriptionRxIcon = () => {
  return <img src={PrescriptionRxIconSVG} height={'92px'} width={'84px'} alt={"Prescription"} />
}

export const HistoryIcon = createSvgIcon(<HistorySVG />, "History")

export const AllergiesIcon = createSvgIcon(<AllergySVG />, "Allergies")

export const PrescriptionIcon = createSvgIcon(<PrescriptionsSVG />, "Prescriptions")

export const ConditionAndProcedureIcon = createSvgIcon(<ConditionAndProceduresSVG />, "Conditions and Procedures")

export const LetterIcon = createSvgIcon(<LetterSVG />, "Letters")

export const FormIcon = createSvgIcon(<FormsSVG />, "Forms")

export const OtherDocumentIcon = createSvgIcon(<OtherDocumentsSVG />, "Other Documents")

export const LabAndInvestigationsIcon = createSvgIcon(<LabsImagingSVG />, "Labs")

export const ChartNotesIcon = createSvgIcon(<ChartingSVG />, "Encounter Notes")

export const TimelineIcon = createSvgIcon(<TimelineSVG />, "Timeline")

export const PractitionerIcon = createSvgIcon(<PractitionerSVG />, "Practitioner")

export const ChartDataIcon = createSvgIcon(<ChartDataSVG height={24} width={24} />, "Chart Data")

export const SignedIcon = createSvgIcon(<SignedIconSVG />, "Signed")

export const PenIcon = createSvgIcon(<PenIconSVG height={24} width={24} />, "Pen")

export const BirthdayIcon = createSvgIcon(<BirthdayIconSVG />, "Date of Birth")

export const RiskIcon = createSvgIcon(<RiskAssessmentIcon />, "Risk Assessment")

export const ExpandRightIcon = createSvgIcon(<ExpandRightSVG />, "Expand right")

export const UnapproveIcon = createSvgIcon(<UnapproveIconSVG />, "Unapprove")

export const AddVitalIcon = createSvgIcon(<AddVitalSVG height={24} width={24} />, "Add Vital")