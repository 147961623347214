import { TaskAssignmentInput, TaskAssignmentType } from '../types/tasks/TaskAssignment'
import { AssignedToOptionType } from '../components/AssignedTo/AssignedTo'

export const cleanupTaskAssignmentData = (taskInput: TaskAssignmentInput) => {
  let inputData: TaskAssignmentInput = { ...taskInput }
  if (inputData.state) {
    inputData.stateId = inputData.state.id
    delete inputData.state
  }
  if (inputData.patient) {
    inputData.patientId = inputData.patient.id
    delete inputData.patient
  }
  inputData.assignedRoleId = inputData.assignedTo?.type === 'role' ? inputData.assignedTo?.value : null
  inputData.assignedUserId = inputData.assignedTo?.type === 'user' ? inputData.assignedTo?.value : null
  delete inputData.assignedTo
  return inputData
}


export const convertTaskToInput = (task: TaskAssignmentType): TaskAssignmentInput => {
  let assignedTo: AssignedToOptionType | undefined = undefined
  if (task.assignedRoleId) {
    assignedTo = { type: 'role', value: task.assignedRoleId }
  } else if (task.assignedUserId) {
    assignedTo = { type: 'user', value: task.assignedUserId }
  }

  return {
    id: task.id,
    name: task.name,
    typeId: task?.type?.id,
    assignedTo: assignedTo,
    stateId: task?.state?.id,
    state: task?.state,
    priority: task.priority,
    startDate: task.startDate,
    dueDate: task.dueDate,
    practitionerId: task.practitionerId,
    patient: task.patient,
    description: task.description,
    version: task.version,
    comments: task.comments,
    comment: null,
    linkedItems: task.linkedItems,
  }
}