import React from 'react'
import { Box } from '@mui/material';
import { TextField, PostalCodeField, AddressLines, ProvinceSelect } from 'saga-library/src'
import { CitySearch } from "./CitySearch";
import { GET_PROVINCES } from "saga-client/src/graphql-definitions";
import { useQuery } from '@apollo/client'

export type AddressRulesType = {
  street: {max: number},
  city: {max: number},
  province: {max: number},
  postalCode: {max: number},
  country: {max: number}
}

export type AddressType = {
  street: string,
  street2: string,
  street3: string,
  city: string,
  province: string,
  postalCode: string,
  country: string
}

export const AddressDefaults = {
  street: '',
  street2: '',
  street3: '',
  city: '',
  province: '',
  postalCode: '',
  country: 'Canada',
}

export interface AddressProps {
  rules?: AddressRulesType,
  dataTestId?: string,
  showAddressLineLabelField?: boolean,
  addressLineLabel?: string,
  showCountry?: boolean
  firstFieldRef?: any
  disabled?: boolean
}

export const Address = ({
  rules,
  dataTestId,
  showAddressLineLabelField = false,
  addressLineLabel,
  showCountry = true,
  firstFieldRef,
  disabled = false
}:AddressProps) => {
  const { data: provinceData, loading: provinceLoading } = useQuery(GET_PROVINCES, { fetchPolicy: 'cache-first' })

  return (
    <>
      <AddressLines
        dataTestId={`${dataTestId}-addressLines`}
        slotProps={{ htmlInput: { maxLength: rules?.street.max } }}
        labels={['Address line 1', 'Address line 2', 'Address line 3']}
        names={['street', 'street2', 'street3']}
        showAddressLabelField={showAddressLineLabelField}
        addressLabel={addressLineLabel}
        firstFieldRef={firstFieldRef}
        disabled={disabled}
      />
      <CitySearch
        dataTestId={`${dataTestId}-city`}
        name={'city'}
        label={'City'}
        disabled={disabled}
      />
      <ProvinceSelect
        dataTestId={`${dataTestId}-province`}
        data={provinceData ? provinceData.static.provinces : []}
        loading={provinceLoading}
        disabled={disabled}
      />
      <Box sx={{ display: 'flex', flexDirection: "row", gap: 2 }}>
        <PostalCodeField
          dataTestId={`${dataTestId}-postalCode`}
          label={'Postal code'}
          name={'postalCode'}
          fullWidth={true}
          disabled={disabled}
        />
        {showCountry && <TextField
          dataTestId={`${dataTestId}-country`}
          label={'Country'}
          name={'country'}
          slotProps={{ htmlInput: { maxLength: rules?.country.max } }}
          fullWidth={true}
          disabled={disabled}
        />}
      </Box>
    </>
  )
}

export default Address