import { useParams } from 'react-router-dom'
import { useSuspenseQuery } from '@apollo/client'
import { GET_PATIENT_ENCOUNTER_NOTES } from '../../../../../graphql-definitions'
import { parseEncounterNoteQueryResults } from '../../../../patients/providers/EncounterNoteProvider'
import { EmptyList, Typography } from 'saga-library/src'
import { EncounterNote } from '../../../../../types/patients'
import { Box } from '@mui/material'
import { SignedIcon } from '../../../../patients/components/ChartIcons'
import React from 'react'
import {DateListItem, ItemType, ListBox, ListMenuItem, ListMenuItemDate} from '../LinkItem'
import { OtherDocumentItem } from './OtherDocuments'
import { LinkType } from '../../../../../types/tasks/TaskAssignment'
import moment from "moment-timezone";
import {displayCategory} from "../../../../patients/util/displayEnum";
import {LinkedDocumentType} from "../../../../../types/Document";

export const EncounterNoteList = ({patientId, onSelect}) => {
  const {tenant_id} = useParams()
  const {data} = useSuspenseQuery(GET_PATIENT_ENCOUNTER_NOTES ,{
    variables:{
      patientId: patientId,
      tenantId: tenant_id
    }
  })

  const notes = parseEncounterNoteQueryResults(data)

  if(notes.length > 0) {
    return (
      <ListBox>
        {notes.map( n => <EncounterNoteItem item={n} onClick={onSelect} key={`link_patient_encounter_note_${n.id}`} />)}
      </ListBox>
    )
  }

  return (
    <EmptyList sx={{p:1, width:"200px"}} message={ `No associated encounter notes`} />
  )
}

export const EncounterNoteItem = ({item, onClick, icon, ...itemTypeProps }:ItemType) => {
  const encounterNote = item as EncounterNote
  if(encounterNote.isLinkedDocument){
    return (
        <DateListItem
            date={ moment(encounterNote.encounterNoteDisplayDate).toDate()}
            label={encounterNote.description || encounterNote.linkedDocument?.category || "Other"}
            onClick={onClick ? (e)=>onClick(e, {...encounterNote, linkType:LinkType.ENCOUNTER_NOTE}) : undefined}
            icon={icon}
            {...itemTypeProps}
        />
    )
  }

  return (
    <ListMenuItem onClick={onClick ? (e)=>onClick(e, {...encounterNote, linkType: LinkType.ENCOUNTER_NOTE}) : undefined} icon={icon} {...itemTypeProps} >
      <ListMenuItemDate date={encounterNote.encounterNoteDate} />
      <Box sx={{display:"flex", flex:"1 1 100%", gap:1, alignItems:"center"}}>
        <Box sx={{ display: "inline-flex", verticalAlign: "middle", flex:"0 0 24px" }}>
          {encounterNote.signOffPit &&
            <SignedIcon sx={{ fontSize: "24px" }} />
          }
        </Box>
        <Typography
          lineclamp={1}
          variant={"body1"}
        >
          {encounterNote.template?.name || encounterNote.description || "Encounter Note"}
        </Typography>
      </Box>
    </ListMenuItem>
  )
}