import { useParams } from 'react-router-dom'
import { useSuspenseQuery } from '@apollo/client'
import { GET_PATIENT_PRESCRIPTIONS } from '../../../../../graphql-definitions'
import { parsePrescriptionQueryResults } from '../../../../patients/providers/PrescriptionProvider'
import { EmptyList, Typography } from 'saga-library/src'
import { PrescriptionStatus, PrescriptionType } from '../../../../../types/Prescription'
import { Box, useTheme } from '@mui/material'
import { usePrescriptionUtils } from '../../../../patients/components/prescriptions/components/PrescriptionUtil'
import moment from 'moment-timezone'
import { Tag } from '../../../../patients/components/Tag'
import React from 'react'
import { ItemType, ListBox, ListMenuItem, ListMenuItemDate } from '../LinkItem'
import { LinkType } from '../../../../../types/tasks/TaskAssignment'

export const PrescriptionList = ({patientId, onSelect}) => {
  const { tenant_id } = useParams()


  const { data } = useSuspenseQuery(GET_PATIENT_PRESCRIPTIONS , {
    variables: {
      patientId: patientId,
      tenantId: tenant_id
    }
  })

  const prescriptions = parsePrescriptionQueryResults(data)

  if (prescriptions.length > 0) {
    return (
      <ListBox>
        {prescriptions.filter(p => p.status !== PrescriptionStatus.INACTIVE).map(p =>
          (
            <PrescriptionItem onClick={onSelect} item={p} key={`link_patient_prescription_${p.id}`}  />
          )
        )}
      </ListBox>
    )
  }

  return (
    <EmptyList sx={{ p: 1, width: "200px" }} message={`No associated prescriptions`} />
  )
}

export const PrescriptionItem = ({item, onClick, icon, ...itemTypeProps }:ItemType) => {
  const prescription = item as PrescriptionType
  const theme = useTheme()
  const { buildSummary } = usePrescriptionUtils()

  const dosageSummary = buildSummary(prescription)

  const today = moment().startOf('day')
  const startDate = moment(prescription.startDate)
  const displayDate = startDate.clone().startOf('day').isSame((today)) ?
    <Typography> Today </Typography> :
    <ListMenuItemDate date={prescription.startDate} />

  return (
    <ListMenuItem
      onClick={onClick ? (e)=>onClick(e, {...prescription, linkType:LinkType.PRESCRIPTION}) : undefined}
      icon={icon}
      {...itemTypeProps}
    >
      <Box sx={{flex: "0 0 85px"}}>
        {prescription.status === PrescriptionStatus.DRAFT &&
          <Tag label={'Draft'} color={theme.palette.chart.yellowTag} />
        }

        {prescription.status === PrescriptionStatus.ACTIVE && (
          <>{displayDate}</>
        )}
      </Box>
      <Box sx={{ display: "flex", flexDirection:"column", lineHeight: "16px", flex:"1 1 100%" }} gap={0}>
        <Typography lineclamp={1} >
          {prescription.drug.name}
        </Typography>
        <Typography lineclamp={1} variant={'body2'} >
          {dosageSummary}
        </Typography>
      </Box>
    </ListMenuItem>
  )
}