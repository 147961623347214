import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import { Button, Section, Typography } from 'saga-library/src'
import React, { useState } from 'react'
import { Box, createSvgIcon, Divider } from '@mui/material'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import { ReactComponent as RecentSVG } from '../../../../assets/RecentIcon.svg'
import { MarkLabReviewedDialog } from './MarkLabReviewedDialog'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { useUpdateCount } from '../../../../hooks/useUpdateCount'

const RecentIcon = createSvgIcon(<RecentSVG />, "Update")

type LabsMultipleSelectedProps = {
  selectedLabs: string[]
  clearSelected: () => void
  onSuccess: () => void
}

export const LabsMultipleSelected = ({
  selectedLabs = [],
  clearSelected,
  onSuccess
}: LabsMultipleSelectedProps) => {
  const { showSuccessAlert } = useAlerts()
  const { updateLabCount } = useUpdateCount()
  const [ openDialog, setOpenDialog ] = useState<boolean>(false)

  const handleOnSuccess = () => {
    onSuccess()
    showSuccessAlert(`${selectedLabs.length} labs(s) and investigation(s) have been reviewed.`)
    updateLabCount(-selectedLabs.length)
  }

  return (
    <>
      <Section.Column rightPadding={1} sx={{ flex: "1 1 100%" }}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 4, height: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: "inherit", alignItems: "inherit" }}>
            <AssignmentTurnedInOutlinedIcon sx={{ color: (theme) => theme.palette.greys.medium, fontSize: "88px" }} />
            <Typography variant={"h2"} dataTestId={'labs-seleted-count'}>{selectedLabs.length} labs(s) or investigation(s) selected</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "inherit", gap: 2, width: "200px" }}>
            <Button
              name={"mark-labs-reviewed"}
              variant={"text"}
              startIcon={<RecentIcon />}
              onClick={() => setOpenDialog(true)}
              dataTestId={"mark-labs-reviewed-button"}
            >
              MARK AS REVIEWED
            </Button>
            <Divider sx={{ borderColor: "rgba(32, 41, 49, 0.32)" }} />
            <Button
              name={"cancel-labs-selection"}
              variant={"text"}
              startIcon={<ClearOutlinedIcon />}
              onClick={clearSelected}
              dataTestId={"cancel-labs-selection-button"}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Section.Column>
      <MarkLabReviewedDialog
        open={openDialog}
        onSuccess={handleOnSuccess}
        selectedLabs={selectedLabs}
        onClose={() => setOpenDialog(false)}
      />
    </>
  )
}