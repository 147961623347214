import React, { useEffect, useState } from 'react'
import { Box, Collapse } from '@mui/material'
import { Section, Typography, Button, Form } from 'saga-library/src'
import { Logo } from '../../../components/Logo'
import { useForm, FormProvider } from "saga-library/src/components/Form"
import * as yup from 'yup'
import { useSagaAuthentication } from '../../../providers/Auth'
import { useNavigate } from 'react-router-dom'

type ResetEmailFormProps = {
  newEmail?: string | null
  token?: string | null
  userId?: string | null
  tokenId?: string | null
}

function ResetEmailForm({
  newEmail,
  token,
  userId,
  tokenId
}: ResetEmailFormProps) {
  const [EmailReset, setEmailReset] = useState<boolean>(false)
  let invalid = (!newEmail || !token || !userId || !tokenId)
  const { performChangeEmail, friendlyEmailErrorMessage } = useSagaAuthentication()
  const navigate = useNavigate();

  const schema = yup.object().shape({
    newEmail: yup.string().required("New Email is required"),
    token: yup.string().required()
  }).required();

  const formMethods = useForm<FormValues>({
    schema: schema
  })

  const {
    handleSubmit,
    register
  } = formMethods

  register("userId", { value: userId })
  register("tokenId", { value: tokenId })

  useEffect(() => {
    const changeEmail = async () => {
      performChangeEmail({
        userId: userId,
        tokenId: tokenId,
        token: token,
        newEmail: newEmail,
      }).then((success) => {
        if(success) setEmailReset(true)
      }).catch((error) => {
        console.error(error)
      })
    }

    changeEmail()
  }, [])

  if (invalid) {
    return (
      <>
        <Box sx={{ mt: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Section.Column>
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, justifyContent: 'center', alignItems: 'center' }}>
              <Logo sx={{ width: 180, height: 70 }} />
              <Section.Header sx={{ mt: 2 }}>
                Reset Email
              </Section.Header>

              <Typography sx={{ mt: 2, mb: 1, color: "#C41923" }} variant={'h3'}>
                The reset Email link is not valid.
              </Typography>

            </Box>
          </Section.Column>
        </Box>
      </>
    )
  }

  return (
    <FormProvider {...formMethods}>
      <Box sx={{ mt: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Form id={'reset-email-form'} onSubmit={handleSubmit} autoComplete={'off'}>
          <Section.Column>
            <Box sx={{ pl: 2, pr: 3, display: 'flex', flexDirection: 'column', mt: 2, justifyContent: 'center', alignItems: 'center' }}>
              <Logo sx={{ width: 180, height: 70 }} />
              <Section.Header sx={{ mt: 2 }}>
                Reset Email
              </Section.Header>

              {EmailReset ?
                <>
                  <Typography variant={'body1'}>
                    Your email has been reset.
                  </Typography>
                  <Box sx={{ my: 2 }}>
                    <Button
                      name={'Ok'}
                      type={'button'}
                      variant={'contained'}
                      onClick={() => { navigate('/') }}
                    >
                      Ok
                    </Button>
                  </Box>
                </>
                :
                <>
                  <Collapse in={!!friendlyEmailErrorMessage} sx={{ color: "#C41923" }}>
                    {friendlyEmailErrorMessage}
                  </Collapse>
                  <input {...register("newEmail")} type="hidden" />
                  <input {...register("token")} type="hidden" />
                </>
              }
            </Box>
          </Section.Column>
        </Form>
      </Box>
    </FormProvider>
  )
}

type FormValues = {
  newEmail?: string | null
  token?: string | null
  userId?: string | null
  tokenId?: string | null
}

export default ResetEmailForm