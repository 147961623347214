import { Controller, useFormContext } from "saga-library/src/components/Form";
import {
  useTheme,
  ToggleButtonGroup as MuiToggleButtonGroup,
  Typography,
  Box,
  SxProps,
  Theme
} from "@mui/material";
import MuiToggleButton from '@mui/material/ToggleButton';
import React from "react";

export type ToggleButtonType = {
  value: string
  icon?: React.ReactNode
  children: React.ReactNode | string
  sx?: SxProps<Theme>
}

export interface ToggleButtonGroupProps {
  name: string
  label?: string
  buttons: ToggleButtonType[]
  disabled?: boolean
  size?: "small" | "medium" | "large"
  fullWidth?: boolean
  required?: boolean
  dataTestId?: string
}

export const ControlledToggleButtonGroup = ({
  name,
  label = "",
  buttons,
  disabled,
  size,
  fullWidth,
  required = false,
  dataTestId
}: ToggleButtonGroupProps) => {
  const { control } = useFormContext();

  return <Controller
    name={name}
    control={control}
    render={({ field: { value, onChange }, fieldState: {error} }) => {
      return (
        <SimpleToggleButtonGroup
          dataTestId={dataTestId}
          label={label}
          buttons={buttons}
          disabled={disabled}
          size={size}
          fullWidth={fullWidth}
          onChange={(e,v) => {
            // This check here, if required, will not allow the SimpleToggleButtonGroup to be unselected.
            if(required && v === null) {
              return
            }
            onChange(v)
          }}
          value={value}
        />
      )
    }}
  />
}


export interface SimpleToggleButtonGroupProps {
  label?: string
  buttons: ToggleButtonType[]
  disabled?: boolean
  size?: "small" | "medium" | "large"
  fullWidth?: boolean
  onChange: (e,v) => void
  value: any
  dataTestId?: string
}

export const SimpleToggleButtonGroup = ({
  label = "",
  buttons,
  disabled = false,
  size,
  fullWidth = false,
  onChange,
  value,
  dataTestId
}: SimpleToggleButtonGroupProps)  => {
  const theme = useTheme()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {label && <Typography variant={'h5'}>{ label }</Typography>}
      <MuiToggleButtonGroup
        data-testid={dataTestId}
        value={value}
        exclusive
        onChange={(e, newValue) => {
          onChange(e, newValue)
        }}
        sx={size !== 'small' ? {height: "40px"} : {}}
        disabled={disabled}
        size={size}
      >
        { buttons.map((button, index) => {
          return (
            <MuiToggleButton
              data-testid={`${dataTestId}-${index}`}
              key={button.value}
              value={button.value}
              sx={{
                borderRadius: '8px',
                gap: !!button.icon ? '8px' : 0,
                width: fullWidth ? '100%' : 'auto',
                '&.Mui-selected': {
                  backgroundColor: theme.palette.primary.main,
                  color: 'white'
                },
                '&.Mui-selected:hover': {
                  backgroundColor: theme.palette.primary.dark,
                  color: 'white',
                },
                '&.Mui-disabled': {
                  '&.Mui-selected': {
                    backgroundColor: theme.palette.greys.medium,
                    color: 'white'
                  },
                },
                '&:hover': {
                  backgroundColor: theme.palette.backgrounds.hover,
                },
                ...button.sx
              }}
            >
              {button.icon}
              {button.children}
            </MuiToggleButton>
          )
        })
        }
      </MuiToggleButtonGroup>
    </Box>
  )
}

export default ControlledToggleButtonGroup
