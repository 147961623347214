import React from 'react'
import { Box } from '@mui/material'
import { Typography } from '../Typography'
import moment from 'moment-timezone'
import { Button, IconButton } from '../Button'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

export interface AgendaEntryType {
  background?: string
  typeColor?: string
  allDay?: boolean
  start: string
  end: string
  location?: string
  openInNewTab?: () => void
  title: React.ReactNode
  description?: React.ReactNode
  state?: string | React.ReactNode
  viewChart?: () => void
  actions?: React.ReactNode[]
}

export const AgendaEntry = ({
  background,
  typeColor,
  allDay,
  start,
  end,
  title,
  description,
  state,
  location,
  openInNewTab,
  viewChart,
  actions
}: AgendaEntryType) => {
  const isPast = !allDay
    ? moment(end).isSameOrBefore(moment())
    : moment(end).endOf('day').isSameOrBefore(moment())

  return (
    <Box
      sx={{
        borderLeft: typeColor ? `16px inset ${typeColor}` : "",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 1,
        pr: 1,
        pl: typeColor ? 1 : 3,
        py: "10px",
        background: background ? background : (theme) => theme.palette.greys.extraLight,
        flexGrow: 1,
        opacity: isPast ? "0.5" : ""
      }}
      className={"md-custom-event-cont"}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", flexShrink: 0, px: "2px", width: "120px" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
            <Typography variant={"h5"}>{allDay ? "ALL-DAY" : moment(start).format('h:mm A')}</Typography>
            {openInNewTab && (
              <IconButton
                icon={<OpenInNewIcon fontSize={"inherit"} />}
                onClick={(e) => {
                  e.stopPropagation()
                  openInNewTab()
                }}
                sx={{ fontSize: "16px", my: "-4px", p: "4px", visibility: "hidden" }}
                className={"md-custom-event-button"}
              />
            )}
          </Box>
          {location && <Typography variant={"p2"} lineclamp={1}>{location}</Typography>}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", flexShrink: 0, width: "288px" }}>
          {title}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", flexShrink: 0, width: "208px" }}>
          {description}
        </Box>
        {state}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
        {viewChart && (
          <Button
            name={"viewChart"}
            variant={"outlined"}
            size={"small"}
            onClick={(e) => {
              e.stopPropagation()
              viewChart()
            }}
            sx={{
              boxSizing: "content-box",
              flexShrink: 0,
              visibility: "hidden"
            }}
            className={"md-custom-event-button"}
          >
            View chart
          </Button>
        )}
        {actions && actions.length > 0 && (
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
            {actions.map((action) => action)}
          </Box>
        )}
      </Box>
    </Box>
  )
}