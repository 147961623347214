import React from 'react'
import {
  EntryDescription,
  EntryTitle,
  ResultOptionsWrapper
} from "saga-library/src";
import { AbClaimServiceCode } from "../../../types/billing/AbClaim/AbClaimServiceCode";
import { Box } from "@mui/material";

interface ServiceCodeResultProps {
  serviceCode: AbClaimServiceCode
  searchText: string
}

export const ServiceCodeResult = ({
  serviceCode,
  searchText
}: ServiceCodeResultProps) => {
  return (
    <ResultOptionsWrapper>
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box>
          <EntryTitle
            title={serviceCode.serviceCode}
            searchText={searchText}
          />
          <EntryDescription
            text={serviceCode.description}
            searchText={searchText}
            maxLines={2}
          />
        </Box>
        <Box>
          <EntryTitle
            title={
              serviceCode.baseAmount.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
              })
            }
            searchText={searchText}
          />
        </Box>
      </Box>
    </ResultOptionsWrapper>
  )
}
