import { useParams } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { GET_REVIEW_COUNTS } from '../graphql-definitions'
import _cloneDeep from 'lodash/cloneDeep'

type ReviewCountVariables = {
  tenant_id?: string,
  user_id?: string,
  role_id?: string
}
export const useUpdateCount = () => {
  const {tenant_id, user_id, role_id} = useParams()
  const client = useApolloClient()

  const queryOptions = {
    query: GET_REVIEW_COUNTS,
    variables: {
      tenantId: tenant_id,
      userId: user_id,
      roleId: role_id,
    }
  }

  const updateCount = (section, offset, variables?: ReviewCountVariables) => {
    try {
      const options = !variables
        ? queryOptions
        : {
            query: GET_REVIEW_COUNTS,
            variables: {
              tenantId: variables?.tenant_id,
              userId: variables?.user_id,
              roleId: variables?.role_id
            }
          }
      const readData = client.readQuery(options)
      let updatedData = _cloneDeep(readData)
      updatedData.tenant.review.inboxCounts[section] += offset

      client.writeQuery({
        ...options,
        data: {
          ...updatedData
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  const updateTaskCount = (offset: number, variables?: ReviewCountVariables) =>{
    updateCount("tasks", offset, variables)
  }

  const updateLabCount = (offset: number) =>{
    updateCount("labs", offset)
  }

  const updateDocumentCount = (offset: number) =>{
    updateCount("documents", offset)
  }

  return {
    updateTaskCount,
    updateDocumentCount,
    updateLabCount
  }
}