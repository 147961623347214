import React, { useEffect } from "react";
import { LoadingScreen } from "../components/LoadingScreen";
import { useSearchParams } from "react-router-dom";
import { Error } from "saga-library/src/components/Snackbar/Snackbar.stories";
import { useConfigurationContext } from "../providers/Configuration";
import axios, { AxiosError } from "axios";

export const SupportSignIn = () => {
  const [searchParams] = useSearchParams();
  const { getConfigValue } = useConfigurationContext()
  const SAGA_REST_ENDPOINT = getConfigValue('SAGA_REST_ENDPOINT');
  const [error, setError] = React.useState<Error | AxiosError>();

  const token = searchParams.get("token")

  useEffect(() => {
    axios.get(
      SAGA_REST_ENDPOINT + '/identity/supportsignin?token=' + token,
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'json',
        validateStatus: (status) => {
          return status === 200
        },
      })
      .then(response => {
        localStorage.setItem("x-csrf-token", response.headers?.["x-csrf-token"] ?? "")
        window.location = response.data
      })
      .catch((error: Error | AxiosError) => {
        localStorage.removeItem("x-csrf-token")
        console.log('support sign in error', error)
        setError(error)
      })
  }, [searchParams, SAGA_REST_ENDPOINT, token]);

  if (!token) {
    return (
      <Error message={"Invalid link"} />
    )
  }

  return error ? (
      <Error message={`An error occurred. ${error}`} />
    ) : (
    <LoadingScreen message={"Please wait, redirect in progress..."} />
  )
}
