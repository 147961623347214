import React from 'react'
import Box from "@mui/material/Box";
import { Typography } from "saga-library/src";
import { AssessmentStatusBadge } from "./AssessedClaimRow";
import { ClaimBase } from "../ClaimBase";
import { useTheme } from "@mui/material";
import { ExplanatoryCodeInfoTooltip } from "../ClaimInfoTooltips";
import { useFormContext } from "saga-library/src/components/Form";
import { useFormattedDate } from '../../../../hooks/FormattedDate'

export const AssessedClaimBase = ({ dataTestId }) => {
  const { getValues } = useFormContext()
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <ClaimBase isAssessed={true} dataTestId={dataTestId} />
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, mt: 3 }}>
        <Box>
          <Typography variant={'p2'} sx={{ color: theme.palette.greys.medium }}>
            Outcome
          </Typography>
          <AssessmentStatusBadge claim={getValues()} />
        </Box>
        <Box>
          <Typography variant={'p2'} sx={{ color: theme.palette.greys.medium }}>
            Explanatory codes
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            {getValues('explanatoryCodes')?.map(code => {
              return (
                <Box key={code.id} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px' }}>
                  <Typography>
                    {code.explanatoryCode}
                  </Typography>
                  <ExplanatoryCodeInfoTooltip explanatoryCode={code} />
                </Box>
              )
            })}
          </Box>
        </Box>
        <Box>
          <Typography variant={'p2'} sx={{ color: theme.palette.greys.medium }}>
            Paid
          </Typography>
          <Typography sx={{ py: '2px' }}>
            {getValues('assessmentAmount')?.toFixed(2)}
          </Typography>
        </Box>
        <Box>
          <Typography variant={'p2'} sx={{ color: theme.palette.greys.medium }}>
            Date paid
          </Typography>
          <Typography sx={{ my: '2px' }}>
            {useFormattedDate(getValues('expectedPaymentDate'))}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}