import { PermissionForm } from '../../../../components/PermissionForm'
import { PermissionType } from '../../../../types/settings/Permission'
import { TextField } from 'saga-library/src'
import { DocumentViewer } from '../../../../components/StandaloneDocumentViewer'
import React, { useCallback, useState, useEffect } from 'react'
import { AbLabResult, AbLabResultObservationResult, AbLabResultUpdate } from '../../../../types/patients'
import { useFormContext } from 'saga-library/src/components/Form'
import { useLabAndInvestigationContext } from '../../providers/LabAndInvestigationProvider'
import { LabAndInvestigationDetails } from './components/LabAndInvestigationDetails'
import { LabValueGraph } from './LabValueGraph'
import { LabAndInvestigationFooter } from './components/LabAndInvestigationFooter'
import { useReviewDocumentStateContext } from '../../../inbox/providers/ReviewDocumentStateProvider'
import {useLabAndInvestigationStateContext} from "../../../../providers/LabAndInvestigationStateProvider";

export interface LabAndInvestigationFormProps {
  formName: string,
  onSuccess?: (updatedLab: AbLabResult) => void,
  dataTestId: string,
  updateLabAndInvestigationMethod?: (labAndInvestigationInput: AbLabResultUpdate, onSuccess: (result: AbLabResult) => void) => Promise<void>
  documentStateContext?: ReturnType<typeof useReviewDocumentStateContext>,
  setLoading?: (loading: boolean) => void
}

export const LabAndInvestigationForm = ({
  formName,
  onSuccess,
  dataTestId,
  updateLabAndInvestigationMethod,
  documentStateContext,
  setLoading
}: LabAndInvestigationFormProps) => {
  const { updateLabAndInvestigation } = useLabAndInvestigationContext()
  const { selectedFile, selectedLabAndInvestigation, labAndInvestigation } = useLabAndInvestigationStateContext()

  const [observationResult, setObservationResult] = useState<AbLabResultObservationResult | null>(null)
  const { handleSubmit, reset } = useFormContext()

  const onSubmit = handleSubmit(
    async (data) => {
      updateLabAndInvestigationMethod
        ? await updateLabAndInvestigationMethod(
          data as AbLabResultUpdate,
          (updatedLab) => {
            onSuccess?.(updatedLab)
          }
        )
        : await updateLabAndInvestigation(
          data as AbLabResultUpdate,
          (updatedLab) => {
            if (onSuccess) {
              onSuccess(updatedLab)
            }
          }
        )
    },
    (errors) => {
      console.error('errors: ', errors)
    }
  )

  const LabView = useCallback(() => {
    if (selectedFile) {
      return (
        <DocumentViewer
          fileRecord={selectedFile}
          variant={'labs'}
          setLoading={setLoading}
          documentStateContext={documentStateContext}
        />
      )
    }

    if (selectedLabAndInvestigation) {
      setLoading?.(false)
      return (
        <>
          <LabAndInvestigationDetails
            lab={selectedLabAndInvestigation!}
            segments={[
              ...(selectedLabAndInvestigation?.abLabResultObservationResults ?? []),
              ...(selectedLabAndInvestigation?.abLabResultNoteAndComments ?? [])
            ]}
            onGraphClick={(result) => setObservationResult(result)}
            dataTestId={`${dataTestId}-labAndInvestigation`}
          />
          <LabAndInvestigationFooter lab={selectedLabAndInvestigation!} />
        </>
      )
    }

    return <></>
  }, [selectedFile, selectedLabAndInvestigation])

  useEffect(() => {
    if (selectedLabAndInvestigation) {
      reset({
        id: selectedLabAndInvestigation.id,
        notes: selectedLabAndInvestigation.notes,
        version: selectedLabAndInvestigation.version
      })
    } else {
      reset({})
    }
  }, [selectedLabAndInvestigation])

  return (
    <>
      <PermissionForm
        id={formName}
        name={formName}
        onSubmit={onSubmit}
        requiredPermissionType={PermissionType.Chart}
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          paddingRight: '8px',
          gap: '16px',
          overflow: 'auto'
        }}
        formDisabled={selectedLabAndInvestigation?.id !== labAndInvestigation?.id}
      >
        <TextField label={'Notes'} name={'notes'} dataTestId={`${dataTestId}-notes`} sx={{ height: '48px' }} />
        <LabView />
      </PermissionForm>
      <LabValueGraph
        result={observationResult}
        setResult={setObservationResult}
        dataTestId={`${dataTestId}-labValueGraph`}
      />
    </>
  )
}