import { useParams } from 'react-router-dom'
import { useSuspenseQuery } from '@apollo/client'
import {
  GET_PATIENT_CONDITION_AND_PROCEDURES
} from '../../../../../graphql-definitions/tenant/patient/ConditionAndProceduresQueries'
import { parseConditionAndProcedureQueryResults } from '../../../../patients/providers/ConditionAndProcedureProvider'
import { EmptyList, IconImageTooltip, Typography } from 'saga-library/src'
import {
  ConditionAndProcedureType,
  ConditionSeverityMap,
  ConditionStatusMap
} from '../../../../../types/ConditionAndProcedure'
import React from 'react'
import { ItemType, ListBox, ListMenuItem } from '../LinkItem'
import { LinkType } from '../../../../../types/tasks/TaskAssignment'

export const ConditionAndProcedureList = ({patientId, onSelect}) => {
  const {tenant_id} = useParams()
  const {data} = useSuspenseQuery(GET_PATIENT_CONDITION_AND_PROCEDURES,{
    variables:{
      patientId: patientId,
      tenantId: tenant_id
    }
  })

  const conditions = parseConditionAndProcedureQueryResults(data)

  if(conditions.length > 0) {
    return (
      <ListBox>
        {conditions.map(c => {
          const itemName = `link_patient_condition_${c.id}`
          return (
            <ConditionAndProcedureItem
              item={c}
              onClick={onSelect}
              key={itemName}
            />
          )}
        )}
      </ListBox>
    )
  }

  return (
    <EmptyList sx={{p:1, width:"200px"}} message={ `No associated conditions and procedures`} />
  )
}

export const ConditionAndProcedureItem = ({ item, onClick, icon, ...itemTypeProps  }:ItemType) => {
  const condition = item as ConditionAndProcedureType
  const status = condition.status || condition.conditionStatus
  const severity = condition.severity || condition.conditionSeverity
  return (
    <ListMenuItem onClick={onClick ? (e)=>onClick(e, {...condition, linkType: LinkType.CONDITION_PROCEDURE}) : undefined } icon={icon} {...itemTypeProps}>
      <Typography lineclamp={1} sx={{flex:"0 0 164px"}}>
        {condition.description || condition.conditionDescription}
      </Typography>
      {severity && <IconImageTooltip title={ConditionSeverityMap.get(severity!)!.label}>
        {ConditionSeverityMap.get(severity!)!.icon}
      </IconImageTooltip>}
      {status && <Typography sx={{flex:"1 1 100%"}}>
        {ConditionStatusMap.get(status!)!.label}
      </Typography>}
    </ListMenuItem>
  )
}