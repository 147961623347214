import {
  Order,
  TableList,
  TableListHeaderConfig
} from 'saga-library/src'
import React, { useEffect, useState } from 'react'
import { TASKS_SCROLL_KEY } from '../../../../providers/UserInteractionContext'
import { TaskCategory } from '../../../../types/tasks/TaskState'
import { TaskRow } from './TaskRow'
import { TaskAssignmentType } from '../../../../types/tasks/TaskAssignment'
import { useReadQuery } from '@apollo/client'
import { DATE_FORMAT } from '../../../../utils/SettingsConstants'
import { useAccountContext } from '../../../../providers/AccountContext'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useReviewContext } from '../../providers/ReviewProvider'
import { flushSync } from 'react-dom'
import { FORM_NAME as UPDATE_FORM_NAME } from './UpdateTask'
import { usePrompt } from '../../../../providers/NavigationPrompt'

const columns: TableListHeaderConfig[] = [
  { name: 'Priority', sortable: true },
  { name: 'State', sortable: true },
  { name: 'Type', sortable: true },
  { name: 'Name', sortable: true },
  { name: 'Patient', sortable: true },
  { name: 'Start date', sortable: true },
  { name: 'Due date', sortable: true }
]

const getSortColumn = (column: string | null) => {
  if (column === 'priority') {
    return 0
  }
  return -1
}

export const TaskList = ({ setSelectedTasks, clearSelectedTasks, setClearSelectedTasks, setNextTaskId }) => {
  const { task_id, user_id, role_id, tenant_id } = useParams()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const navigate = useNavigate()
  const { getUserSetting, buildTenantRoute } = useAccountContext()
  let dateFormat = getUserSetting(DATE_FORMAT) as string
  const { clearNavigationPrompt } = usePrompt()

  const { taskFilters, tasksRef, parseTaskQueryResults } = useReviewContext()
  const { data } = useReadQuery(tasksRef)

  const [ filteredTaskList, setFilteredTaskList ] = useState<TaskAssignmentType[]>([])

  useEffect(() => {
    updateFilteredTaskList(true)
  }, [data])

  useEffect(() => {
    updateFilteredTaskList()
  }, [taskFilters, task_id, data])

  const updateFilteredTaskList = (dataUpdated: boolean = false) => {
    const parsedData = parseTaskQueryResults(data)
    if(!parsedData) return

    let tempTasks = parsedData
    switch (taskFilters.state) {
      case TaskCategory.INCOMPLETE:
        tempTasks = tempTasks.filter((task: TaskAssignmentType) => task.state.category === TaskCategory.INCOMPLETE)
        break
      default:
        tempTasks = tempTasks.filter((task: TaskAssignmentType) => task.state.id === taskFilters.state)
    }
    switch (taskFilters.type) {
      case "*":
        break
      default:
        tempTasks = tempTasks.filter((task: TaskAssignmentType) => task.type.id === taskFilters.type)
    }

    if(task_id){
      const tempTask = tempTasks.find(task => task.id === task_id)
      if(!tempTask){
        redirectList(dataUpdated)
      }
    }
    setFilteredTaskList(tempTasks)
  }

  const redirectList = (dataUpdated: boolean = false) => {
    queueMicrotask(() => {
      if (dataUpdated) {
        flushSync(() => {
          clearNavigationPrompt(UPDATE_FORM_NAME)
        })
      }
      navigate(buildTenantRoute(`inbox/${role_id? `r/${role_id}`: `u/${user_id}`}/tasks/`, tenant_id))
    })
  }

  const checkboxSelectionChanged = (selectedRowsId: string[]) => {
    setSelectedTasks(selectedRowsId)
    if (selectedRowsId.length === 0) return
    let lastIndex = -1
    selectedRowsId.forEach((selectedRowId) => {
      const taskIndex = filteredTaskList.findIndex(task => task.id === selectedRowId)
      if (taskIndex === -1) return
      if (taskIndex > lastIndex) {
        lastIndex = taskIndex
      }
    })

    if (lastIndex + 1 > filteredTaskList.length) return

    const nextTask = filteredTaskList[lastIndex + 1] ?? undefined
    setNextTaskId(nextTask?.id)
  }

  const onClick = (task) => {
    navigate(buildTenantRoute(`inbox/${role_id? `r/${role_id}`: `u/${user_id}`}/tasks/t/${task.id}`, tenant_id))
  }

  return (
    <TableList
      dataTestId={'task-table'}
      scrollKey={TASKS_SCROLL_KEY}
      showCheckbox={true}
      enableSorting={true}
      defaultSortColumn={getSortColumn(queryParams.get('sortBy'))}
      defaultSortOrder={queryParams.get('order') as Order}
      columns={columns}
      rows={[
        ...filteredTaskList.map((task) => TaskRow(task, ()=>onClick(task), dateFormat, task.id === task_id))
      ]}
      onCheckboxSelectionChanged={checkboxSelectionChanged}
      clearSelectedRows={clearSelectedTasks}
      setClearSelectedRows={setClearSelectedTasks}
      sx={{height: '100%'}}
      emptyListComponent={ { message: "Tasks appear here once created." } }
    />
  )
}