import { gql } from "@apollo/client";
import { SCHEDULE_ROOMS_FRAGMENT } from "./AppointmentRoomQueries";

const SCHEDULE_FRAGMENT = gql`
  fragment Schedule on Schedule {
    version
    practitionerId
    defaultAppointmentTypeId
    endDate
    sundayOpen
    sundayStart
    sundayEnd
    mondayOpen
    mondayStart
    mondayEnd
    tuesdayOpen
    tuesdayStart
    tuesdayEnd
    wednesdayOpen
    wednesdayStart
    wednesdayEnd
    thursdayOpen
    thursdayStart
    thursdayEnd
    fridayOpen
    fridayStart
    fridayEnd
    saturdayOpen
    saturdayStart
    saturdayEnd
  }
`
export const GET_SCHEDULE = gql`
    ${SCHEDULE_FRAGMENT}
    query GetSchedule ($tenantId: ID!, $scheduleId: ID!) {
        tenant(id: $tenantId) {
            schedule {
                schedule(id: $scheduleId) {
                    ...Schedule
                }
            }
        }
    }
`

export const UPDATE_SCHEDULE = gql`
  ${SCHEDULE_FRAGMENT}
  mutation UpdateSchedule ($tenantId: ID!, $scheduleId: ID!, $scheduleInput: ScheduleInput!) {
    tenant(id: $tenantId) {
      schedule {
        update(id: $scheduleId, input: $scheduleInput) {
          id
          locationId
          active
          location {
              name
          }
          practitionerFirstName
          practitionerLastName
          ...Schedule
        }
      }
    }
  }
`

export const APPOINTMENT_FRAGMENT = gql`
fragment Appointment on Appointment {
  id
  resource: scheduleId
  allDay
  start
  end
  notes
  reasonForVisit
  method
  itemDate
  type {
      byPhone
      color
      duration
      id
      inPerson
      isDeleted
      name
      videoCall
  }
  state {
      blocksSchedule
      canEnterExamRoom
      category
      color
      id
      isDefault
      isDeleted
      name
  }
  patient {
      id
      firstName
      lastName
      primaryIdentifierLabel
      primaryIdentifier
      dob
      gender {
        name
      }
  }
  location {
    id
  }
  practitioner {
    id
  }
  inProgressPit
  version
}
`

export const SCHEDULE_ITEMS_FRAGMENT = gql`
  ${APPOINTMENT_FRAGMENT}
  fragment ScheduleItemList on ScheduleItem {
      ... on Appointment {
          ...Appointment
      }
      ... on BookingPreference {
          id
          resource: scheduleId
          version
          start
          end
          title
          scheduleId
          appointmentTypes {
              id
              name
          }
      }
      ... on ScheduleEvent {
          id
          resource: scheduleId
          version
          start
          end
          title
          allDay
      }
      ... on TemplateEvent {
        templateEventId: id
        resource: scheduleId
        title
        allDay
        startTimeSpan
        endTimeSpan
        scheduleDate
        templateName
      }
      ... on TemplateBookingPreference {
        templateBookingPreferenceId: id
        resource: scheduleId
        title
        startTimeSpan
        endTimeSpan
        length
        scheduleDate
        templateName
        appointmentTypes {
          id
          name
        }
      }
  }
`

export const SCHEDULE_SETTINGS_FRAGMENT = gql`
  fragment ScheduleSettings on Schedule {
    version
    id
    endDate
    sundayOpen
    sundayStart
    sundayEnd
    mondayOpen
    mondayStart
    mondayEnd
    tuesdayOpen
    tuesdayStart
    tuesdayEnd
    wednesdayOpen
    wednesdayStart
    wednesdayEnd
    thursdayOpen
    thursdayStart
    thursdayEnd
    fridayOpen
    fridayStart
    fridayEnd
    saturdayOpen
    saturdayStart
    saturdayEnd
    defaultAppointmentTypeId
  }
`

export const GET_SCHEDULES = gql`
  ${SCHEDULE_SETTINGS_FRAGMENT}
  ${SCHEDULE_ROOMS_FRAGMENT}
  query GetSchedules ($tenantId: ID!, $scheduleIdList: [ID!]!) {
    tenant(id: $tenantId) {
      schedule {
        schedules(ids: $scheduleIdList) {
          ...ScheduleSettings
        }
        room {
          rooms(scheduleIds: $scheduleIdList) {
            ...ScheduleRooms
          }
        }
      }
    }
  }
`

export const GET_SCHEDULE_ITEMS = gql`
  ${SCHEDULE_ITEMS_FRAGMENT}
  query GetScheduleItems ($tenantId: ID!, $scheduleIdList: [ID!]!, $start: Date!, $end: Date!) {
    tenant(id: $tenantId) {
      schedule {
        scheduleItems(scheduleIds: $scheduleIdList, startDate: $start, endDate:$end) {
            key
            value {
              ...ScheduleItemList
            }
          }
         scheduleTemplateDays(scheduleIds: $scheduleIdList, startDate: $start, endDate: $end) {
            resource: scheduleId
            templateName
            scheduleDate
            templateId
            startTime
            endTime
         }
      }
    }
  }
`

export const LIST_SCHEDULES = gql`
query ListSchedules($tenantId: ID!) {
  tenant(id: $tenantId) {
    schedule {
      listSchedules {
        id
        version
        practitionerId
        practitionerFirstName
        practitionerLastName
        locationId
        location {
          name
        }
      }
    }
  }
}
`

export const APPOINTMENT_ROOM_ON_CHANGED = gql`
  subscription ($tenantId: ID!, $roomId: ID!) {
    appointmentRoomOnChanged(tenantId: $tenantId, roomId: $roomId) {
      id
      name
      practitioner {
        id
        firstName
        lastName
      }
      location {
        id
        name
      }
      appointment {
        id
        reasonForVisit
        type {
          id
          name
          color
        }
        state {
          id
          color
          canEnterExamRoom
        }
        patient {
          id
          lastName
          firstName
          primaryIdentifierLabel
          primaryIdentifier
          dob
        }
        version    
      }
      version
    }
  }
`

export const SCHEDULE_ITEMS_ON_CHANGED = gql`
${APPOINTMENT_FRAGMENT}
subscription ($tenantId: ID!, $scheduleId: ID!) {
  scheduleItemsOnChanged(tenantId: $tenantId, scheduleId: $scheduleId) {
    eventType
    scheduleItem {
      __typename
      ... on Appointment {
          ...Appointment
      }
      ... on BookingPreference {
          id
          resource: scheduleId
          version
          start
          end
          title
          scheduleId
          appointmentTypes {
              id
              name
          }
      }
      ... on ScheduleEvent {
          id
          resource: scheduleId
          version
          start
          end
          title
          allDay
      }
      ... on TemplateEvent {
        id
        title
        allDay
        startTimeSpan
        endTimeSpan
        scheduleDate
        templateName
      }
      ... on TemplateBookingPreference {
        id
        title
        startTimeSpan
        endTimeSpan
        length
        scheduleDate
        templateName
        appointmentTypes {
          id
          name
        }
      }
    }
  }
}
`

export const SCHEDULE_ON_CHANGED = gql`
  subscription ($tenantId: ID!, $scheduleId: ID!) {
    scheduleOnChanged(tenantId: $tenantId, scheduleId: $scheduleId) {
      id
      version
      endDate
      sundayOpen
      sundayStart
      sundayEnd
      mondayOpen
      mondayStart
      mondayEnd
      tuesdayOpen
      tuesdayStart
      tuesdayEnd
      wednesdayOpen
      wednesdayStart
      wednesdayEnd
      thursdayOpen
      thursdayStart
      thursdayEnd
      fridayOpen
      fridayStart
      fridayEnd
      saturdayOpen
      saturdayStart
      saturdayEnd
      defaultAppointmentTypeId
      practitionerId
    }
  }
`