import React from 'react'
import { Typography } from 'saga-library/src'
import { Box, Chip, SvgIconProps, SxProps, Theme } from '@mui/material'
import { AbClaimStatus } from '../../../../utils/ABClaimConstants'
import { ErrorIcon, HoldIcon, ReadyIcon } from '../ClaimStatusIcons'

interface UpdateClaimDetailsHeaderProps {
  identifier: string,
  status: string,
}
export const EditUnsubmittedClaimHeader = ({ identifier, status } : UpdateClaimDetailsHeaderProps ) => {
  return (
    <Box>
      <Typography variant={'h2'}>
        {identifier}
      </Typography>
      {status === AbClaimStatus.DRAFT &&
        <UnsubmittedClaimChip
          label={'Contains errors'}
          Icon={ErrorIcon}
          sx={{
            backgroundColor: (theme) => theme.palette.warning.main
          }}
        />
      }
      {status === AbClaimStatus.READY &&
        <UnsubmittedClaimChip
          label={'Ready to submit'}
          Icon={ReadyIcon}
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main
          }}
        />
      }
      {status === AbClaimStatus.HOLD &&
        <UnsubmittedClaimChip
          label={'Held'}
          Icon={HoldIcon}
          sx={{
            backgroundColor: (theme) => theme.palette.greys.medium
          }}
        />
      }
    </Box>
  )
}

const UnsubmittedClaimChip = ({ label, Icon, sx }: {
  label: string,
  Icon: (props: SvgIconProps) => JSX.Element,
  sx: SxProps<Theme>
}) => {
  return (
    <Chip
      size={'small'}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <Icon sx={{ color: 'inherit', fontSize: '16px' }} />
          <Typography variant={'p2'} sx={{ textTransform: 'uppercase' }}>{label}</Typography>
        </Box>
      }
      sx={{
        color: 'white',
        ...sx
      }}
    />
  )
}