import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import _get from 'lodash/get'
import {
  LIST_TASK_STATES,
  LIST_TASK_TYPES,
  UPDATE_TASK_ASSIGNMENT_STATUSES,
  LIST_TASK_ASSIGNMENTS,
  ASSIGN_TASK,
  UPDATE_TASK
} from '../graphql-definitions'
import { TaskType } from '../types/tasks/TaskType'
import { TaskState } from '../types/tasks/TaskState'
import { TaskAssignmentInput } from '../types/tasks/TaskAssignment'
import { useParams } from 'react-router-dom'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import _cloneDeep from 'lodash/cloneDeep'
import { DELETE_TASK } from '../graphql-definitions/tenant/inbox/TaskQueries'
import { cleanupTaskAssignmentData } from '../utils/TaskUtils'

interface UpdateParams {
  tenant_id?: string | undefined
  user_id?: string | undefined
  role_id?: string | undefined
}

interface TaskContextInterface {
  taskTypes: TaskType[],
  taskStates: TaskState[],
  createTask: (
    task: TaskAssignmentInput,
    onComplete?: (data) => void,
    updateParams?: UpdateParams
  ) => Promise<void>,
  updateTask: (
    task: TaskAssignmentInput,
    onComplete?: (data) => void
  ) => Promise<void>,
  deleteTask: (
    taskId: string,
    version: string,
    onComplete?: (data) => void
  )=> Promise<void>,
  updateTaskStatuses: (ids: string[], stateId: string, onSuccess: ()  => void) => Promise<void>
}

const defaultTaskContext: TaskContextInterface = {
  taskTypes: [],
  taskStates: [],
  createTask: (
    task: TaskAssignmentInput,
    onComplete?: (data) => void,
    updateParams?: UpdateParams
  ) => Promise.resolve(),
  updateTask: (
    task: TaskAssignmentInput,
    onComplete?: (data) => void
  ) => Promise.resolve(),
  deleteTask:(
    taskId: string,
    version: string,
  ) => Promise.resolve(),
  updateTaskStatuses: (ids: string[], stateId: string, onSuccess: () => void) => Promise.resolve()
}

const TaskContext = React.createContext(defaultTaskContext)

export const TaskProvider = ({ children }) => {
  const { tenant_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const [ taskTypes, setTaskTypes ] = useState<TaskType[]>([])

  const { data } =useQuery(LIST_TASK_STATES, {
    variables: { tenantId: tenant_id },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
  })

  useQuery(LIST_TASK_TYPES, {
    variables: { tenantId: tenant_id },
    onCompleted: (data) => {
      setTaskTypes(_get(data, 'tenant.task.type.list', []))
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
  })

  const [ createTaskAssignment ] = useMutation(ASSIGN_TASK)
  const [ updateTaskAssignment ] = useMutation(UPDATE_TASK)
  const [ updateTaskAssignmentStatuses ] = useMutation(UPDATE_TASK_ASSIGNMENT_STATUSES)
  const [ deleteTaskAssignment ] = useMutation(DELETE_TASK)

  const createTask = async (taskInput: TaskAssignmentInput, onComplete, updateParams?: UpdateParams) => {
    let input: TaskAssignmentInput = cleanupTaskAssignmentData(taskInput)

    await createTaskAssignment({
      variables:{
        tenantId: tenant_id,
        input: input
      },
      onCompleted: (data) => {
        showSuccessAlert("Task has been saved.")
        if(onComplete){
          onComplete(_get(data, 'tenant.task.assignment.create'))
        }
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert("Task couldn't be saved.")
      },
      update: (cache, { data }) => {
        if (updateParams) {
          updateCache(cache, data, updateParams)
        }
      }
    })
  }

  const updateTask = async (taskInput: TaskAssignmentInput, onComplete) => {
    let input: TaskAssignmentInput = cleanupTaskAssignmentData(taskInput)
    const taskId = input.id
    delete input.id
    delete input.type

    await updateTaskAssignment({
      variables: {
        tenantId: tenant_id,
        id: taskId,
        input: input
      },
      onCompleted: (data) => {
        showSuccessAlert("Task has been saved.")
        if (onComplete) {
          onComplete(_get(data, 'tenant.task.assignment.update'))
        }
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert("Task couldn't be saved.")
      }
    })
  }

  const updateTaskStatuses = async (ids: string[], stateId: string, onSuccess: () => void) => {
    await updateTaskAssignmentStatuses({
      variables: {
        tenantId: tenant_id,
        ids: ids,
        stateId: stateId
      },
      onCompleted: () => {
        showSuccessAlert(`${ids.length} task(s) have been updated.`)
        onSuccess()
      },
      onError: (e) => {
        console.error(e)
        showErrorAlert("Task(s) couldn't be updated.")
      },
    })
  }

  const deleteTask = async (taskId: string, version: string, onComplete) => {
    await deleteTaskAssignment({
      variables: {
        tenantId: tenant_id,
        id: taskId,
        version: version
      },
      onCompleted: () => {
        showSuccessAlert(`Task has been deleted.`)
        if(onComplete) {
          onComplete()
        }
      },
      onError: () => {
        showErrorAlert("Task couldn't be deleted.")
      },
      update: (cache, { data }) => {
        const normalizedId = cache.identify({
          id: taskId,
          __typename: "TaskAssignment"
        })
        cache.evict({ id: normalizedId })
        cache.gc()
      }
    })
  }

  const providerValues = {
    taskTypes,
    taskStates: _get(data, 'tenant.task.state.list', []),
    createTask,
    updateTask,
    deleteTask,
    updateTaskStatuses
  }

  return (
    <TaskContext.Provider value={providerValues}>
      { children }
    </TaskContext.Provider>
  )
}

const updateCache = (cache, data, params) => {
  const { tenant_id, user_id, role_id } = params
  const newTask = _get(data, 'tenant.task.assignment.create', null)
  if((newTask.assignedUserId && user_id === newTask.assignedUserId) || (newTask.assignedRoleId && role_id === newTask.assignedRoleId)) {
    cache.updateQuery({
      query: LIST_TASK_ASSIGNMENTS,
      variables: {
        tenantId: tenant_id,
        userId: user_id,
        roleId: role_id,
      }
    }, (cachedata) => {
      const tempData = _cloneDeep(cachedata)
      if(!tempData) return
      const existingTasks: any = _get(cachedata, 'tenant.task.assignment.list', [])
      tempData.tenant.task.assignment.list = [...existingTasks, newTask]
      return tempData
    })
  }
}

export const useTaskContext = () => {
  return React.useContext(TaskContext)
}