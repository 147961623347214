import React from 'react'
import Menu from '@mui/material/Menu'
import Box from '@mui/material/Box'
import { SxProps, useTheme, Theme } from "@mui/material";

interface AppBarMenuProps {
  open: boolean
  anchorEl?: HTMLAnchorElement | null
  children?: React.ReactNode
  handleClose?: () => void
}

export const AppBarMenu = ({
  anchorEl,
  handleClose,
  open,
  children,
}: AppBarMenuProps) => {
  return (
    <Menu
      data-testid={"appBarMenu"}
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
      sx={{
        textAlign:"center",
        fontWeight: 700
      }}
      MenuListProps={{
        sx: {
          p: 1
        }
      }}
    >
      {children}
    </Menu>
  )
}

export const MenuHeader = ({ children }) => {
  const theme = useTheme()
  return (
    <Box sx={{ margin: theme.spacing(1, 2, 3,2) }}>
      {children}
    </Box>
  )
}

export const MenuSection = ({sx= {}, children }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, ...sx }}>
      {children}
    </Box>
  )
}

type MenuRowProps = {
  sx?: SxProps<Theme>,
  children: React.ReactNode
}
export const MenuRow = ({ sx = {}, children }: MenuRowProps) => {
  return <Box sx={{ ...sx }}>{children}</Box>
}

export const MainAppBarMenuRow = ({sx = {}, children}: MenuRowProps) => {
  return <MenuRow sx={{...sx}} children={children}/>
}

export const PrivacyAndTerms = ({ children }) => {
  return (
    <Box
      sx={ theme => ({
        color: theme.palette.greys.medium,
        display: 'flex',
        justifyContent: 'center',
        gap: '37px',
      })}
    >
      {children}
    </Box>
  )
}

export default AppBarMenu
