import React from "react";
import { Box } from "@mui/material";
import { Section } from "saga-library/src";
import { FamilyRelationship } from '../../../types/patients/Patient'
import { FamilyRelationshipsArray } from '../../../components/SearchControls/Patient/FamilyRelationshipsArray'

export type PersonalRelationshipDetailsType = {
  familyRelationships?: FamilyRelationship[]
}

const defaults: PersonalRelationshipDetailsType = {
  familyRelationships: []
}

const Form = (props) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      <Section.SubHeader>Personal relationships</Section.SubHeader>
      <FamilyRelationshipsArray
        dataTestId={"patient-familyRelationships"}
        name={'familyRelationships'}
      />
    </Box>
  )
}

export const PersonalRelationshipDetails = {
  Form: Form,
  defaults: defaults,
}
