import React from 'react'
import { useParams } from 'react-router-dom'
import { Permission, PermissionType } from '../types/settings/Permission'
import { useAccountContext } from '../providers/AccountContext'
import { usePageTitleContext } from '../providers/PageTitleContextProvider'
import { Box } from '@mui/material'
import { Section } from 'saga-library/src'
import {
  LabsAndInvestigationsDashboardException
} from '../apps/dashboard/components/LabsAndInvestigations/LabsAndInvestigationsDashboardException'
import { AppointmentsDashboard } from '../apps/dashboard/components/Appointments/AppointmentsDashboard'
import { useTenantContext } from '../providers/TenantContextProvider'
import { AbClaimsDashboard } from '../apps/dashboard/components/AbClaims/AbClaimsDashboard'
import { InboxDashboard } from '../apps/dashboard/components/Inbox/InboxDashboard'

export const Dashboard = React.memo(() => {
  const { tenant_id } = useParams()

  const { userId } = useAccountContext()
  const { setAreaTitle } = usePageTitleContext()

  const { userHasPermission } = useAccountContext()
  const hasChartPermissions = (!!tenant_id && userHasPermission(tenant_id, PermissionType.Chart, Permission.READWRITE))
  const hasBillingPermissions = (!!tenant_id && userHasPermission(tenant_id, PermissionType.Billing, Permission.READONLY))

  const { practitioners } = useTenantContext()
  const userPractitionerId = practitioners.find(p => p.userId === userId)?.id

  React.useEffect(() => {
    setAreaTitle('Dashboard')
  }, [setAreaTitle])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
      {!!userPractitionerId && <AppointmentsDashboard practitionerId={userPractitionerId} />}
      <Section.Container sx={{ flexDirection: "column", gap: 1, maxWidth: '780px', minWidth: '500px' }}>
        {hasChartPermissions && <LabsAndInvestigationsDashboardException />}
        {hasBillingPermissions && <AbClaimsDashboard practitionerId={userPractitionerId} />}
        <InboxDashboard />
      </Section.Container>
    </Box>
  )
})
