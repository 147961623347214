import React, { useState } from 'react'
import { useApolloClient, useLoadableQuery, useMutation } from '@apollo/client'
import {
  GET_PRACTITIONER_LAB_AND_INVESTIGATIONS, MARK_LABS_REVIEWED
} from '../../../graphql-definitions'
import { ReviewLab } from "types/inbox/ReviewLab";
import _get from 'lodash/get'
import { useNavigate, useParams } from 'react-router-dom'
import { useAccountContext } from '../../../providers/AccountContext'
import { forEach } from 'lodash'
import { useAlerts } from 'saga-library/src/providers/Alerts'

export type ReviewLabFilters = {
  normalAndAbnormal: string
  unreviewed: string
  labsAndInvestigations: string
}

const getPractitionerLabQueryResults = (data) => {
  return [..._get(data, 'tenant.review.lab.list', []) as Array<ReviewLab>]
}

const parsePractitionerLabQueryResults = (data) => {
  return getPractitionerLabQueryResults(data)
}

interface ReviewLabsContextInterface {
  practitionerLabs: any,
  loadPractitionerLabs: any,
  refetchPractitionerLabs: any,
  parsePractitionerLabQueryResults: (any) => ReviewLab[],
  labFilters: ReviewLabFilters,
  setLabFilters: (filters: Partial<ReviewLabFilters>) => void,
  selectNextRow: (currentLabId: string, userId: string | undefined, selectReviewed?: boolean) => void,
  markLabsAsReviewed: (labsIds: string[], onSuccess: () => void) => void,
}

const defaultReviewLabsContext: ReviewLabsContextInterface = {
  practitionerLabs: [],
  loadPractitionerLabs: () => {},
  refetchPractitionerLabs: () => {},
  parsePractitionerLabQueryResults: parsePractitionerLabQueryResults,
  labFilters: {
    normalAndAbnormal: '*',
    unreviewed: 'unreviewed',
    labsAndInvestigations: '*'
  },
  setLabFilters: (filters: Partial<ReviewLabFilters>) => {},
  selectNextRow: (currentLabId: string, userId: string | undefined, selectReviewed?: boolean) => {},
  markLabsAsReviewed: (labIds: string[], onSuccess: () => void) => {},
}

const ReviewLabsContext = React.createContext(defaultReviewLabsContext)

export const ReviewLabsProvider = ({ children }) => {
  const { tenant_id } = useParams()
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()
  const { showErrorAlert } = useAlerts()

  const client = useApolloClient()

  const [ labFilters, setLabFilters ] = useState<ReviewLabFilters>({
    normalAndAbnormal: '*',
    unreviewed: 'unreviewed',
    labsAndInvestigations: '*'
  })

  const [markLabsAsReviewedMutation] = useMutation(MARK_LABS_REVIEWED)
  const [loadPractitionerLabs, practitionerLabs,  { refetch: refetchPractitionerLabs, }] = useLoadableQuery(GET_PRACTITIONER_LAB_AND_INVESTIGATIONS,
    {fetchPolicy: 'cache-and-network'}
  )

  const markLabsAsReviewed = async (labIds: string[], onSuccess: () => void) => {
    markLabsAsReviewedMutation({
      variables: {
        labIds: labIds,
        tenantId: tenant_id
      },
      update: (cache, data) => {
        forEach(labIds, (labId) => {
          cache.modify({
            id: cache.identify({ __typename: 'ReviewLab', id: labId }),
            fields: {
              isReviewed: () => true
            }
          })
        })
      }
    }).then(() => {
      onSuccess()
    }).catch((error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert("Lab(s) or investigation(s) couldn't be marked as reviewed.")
    })
  }

  const selectNextRow = (currentLabId:  string, userId: string | undefined, selectReviewed?: boolean) => {
    if(!currentLabId || !userId) return
    const labsResult = client.cache.readQuery({
      query: GET_PRACTITIONER_LAB_AND_INVESTIGATIONS,
      variables: {
        tenantId: tenant_id,
        userId: userId,
      },
    })

    let labs = getPractitionerLabQueryResults(labsResult)

    if(!labs) {
      navigate(buildTenantRoute(`inbox/u/${userId}/labs/`, tenant_id))
      return
    }

    const currentLabIndex = labs.findIndex((lab: ReviewLab) => lab.id === currentLabId)
    if(!labs[currentLabIndex + 1]) {
      navigate(buildTenantRoute(`inbox/u/${userId}/labs/`, tenant_id))
      return
    }

    const nextLab =  labs[currentLabIndex + 1]
    const filteredLabs = labs.filter((lab: ReviewLab) => !!selectReviewed ? lab.isReviewed : !lab.isReviewed)

    if(filteredLabs.includes(nextLab))
    {
      navigate(buildTenantRoute(`inbox/u/${userId}/labs/l/${nextLab.id}`, tenant_id))
      return
    }

    if(filteredLabs.length === 1) {
      const firstLab = filteredLabs[0]
      navigate(buildTenantRoute(`inbox/u/${userId}/labs/l/${firstLab.id}`, tenant_id))
      return
    }

    navigate(buildTenantRoute(`inbox/u/${userId}/labs/`, tenant_id))
    return
  }

  const setFilters = (filters: Partial<ReviewLabFilters>) => {
    setLabFilters({ ...labFilters, ...filters })
  }

  const providerValues = {
    practitionerLabs,
    loadPractitionerLabs,
    refetchPractitionerLabs,
    parsePractitionerLabQueryResults,
    labFilters,
    setLabFilters: setFilters,
    selectNextRow,
    markLabsAsReviewed,
  }

  return (
    <ReviewLabsContext.Provider value={providerValues}>
      { children }
    </ReviewLabsContext.Provider>
  )
}

export const useReviewLabsContext = () => {
  return React.useContext(ReviewLabsContext)
}