import {
  TableList,
  TableListHeaderConfig
} from 'saga-library/src'
import React, { useEffect, useMemo, useState } from 'react'
import { LabsRow } from './LabsRow'
import { useReadQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { ReviewLabFilters, useReviewLabsContext } from '../../providers/ReviewLabsProvider'
import { ReviewLab } from '../../../../types/inbox/ReviewLab'

interface LabsListProps {
  onClick: (lab: any) => void,
  dataTestId: string,
  setNextLabId: (nextLabId: string | undefined) => void,
  setSelectedLabs: (selected: string[]) => void,
  clearSelectedLabs?: boolean,
  setClearSelectedLabs?: (value: (((prevState: boolean) => boolean) | boolean)) => void
}

export const LabsList = ({
  onClick,
  dataTestId,
  setNextLabId,
  setSelectedLabs,
  clearSelectedLabs,
  setClearSelectedLabs,
}: LabsListProps) => {
  const { lab_id} = useParams()

  const { labFilters, practitionerLabs, parsePractitionerLabQueryResults } = useReviewLabsContext()
  const { data } = useReadQuery(practitionerLabs)
  const parsedData = useMemo(() => parsePractitionerLabQueryResults(data),
    [data, parsePractitionerLabQueryResults]
  )

  const [filteredLabs, setFilteredLabs] = useState<ReviewLab[]>(filterList(parsedData, labFilters))

  const hasAbnormal = filteredLabs.some((lab) => lab?.abLabResult?.isAbnormal)
  const columns: TableListHeaderConfig[] = [
    { name: 'Date', sortable: true },
    { name: 'Patient', sortable: true },
    ...hasAbnormal ? [{ name: '' }] : [],
    { name: 'Description' }
  ]

  const isRowClicked = (lab) => lab.id === lab_id

  useEffect(() => {
    setFilteredLabs(filterList(parsedData, labFilters))
  }, [labFilters, parsedData])

  const checkboxSelectionChanged = (selectedRowsId: string[]) => {
    setSelectedLabs(selectedRowsId)
    if (selectedRowsId.length === 0) return
    let lastRowId = selectedRowsId[selectedRowsId.length - 1]
    let lastIndex = filteredLabs.findIndex(lab => lab.id === lastRowId)
    if (lastIndex === -1 || lastIndex + 1 > filteredLabs.length) return
    let nextLab = filteredLabs[lastIndex + 1] ?? undefined
    setNextLabId(nextLab?.id)
  }

  return (
    <TableList
      columns={columns}
      showCheckbox={true}
      enableSorting={true}
      clearSelectedRows={clearSelectedLabs}
      setClearSelectedRows={setClearSelectedLabs}
      onCheckboxSelectionChanged={checkboxSelectionChanged}
      emptyListComponent={{ message: 'Labs and investigations appear here once received.' }}
      rows={
        [...filteredLabs.map((lab, index) =>
          LabsRow(lab, index, onClick, isRowClicked, hasAbnormal, dataTestId))]
      }
      dataTestId={`${dataTestId}-labs-list`}
    />
  )
}

const filterList = (filtered: ReviewLab[], labFilters: ReviewLabFilters): ReviewLab[] => {
  if (labFilters.labsAndInvestigations !== '*') {
    switch (labFilters.labsAndInvestigations) {
      case 'labs':
        filtered = filtered.filter((review) => review?.abLabResult?.isLab)
        break
      case 'investigations':
        filtered = filtered.filter((review) => !review?.abLabResult?.isLab)
        break
    }
  }

  switch (labFilters.unreviewed) {
    case 'unreviewed':
      filtered = filtered.filter((review) => !review?.isReviewed)
      break
    case 'reviewed':
      filtered = filtered.filter((review) => review?.isReviewed)
      break
  }

  if (labFilters.normalAndAbnormal !== '*') {
    switch (labFilters.normalAndAbnormal) {
      case 'normal':
        filtered = filtered.filter((review) => !review?.abLabResult?.isAbnormal)
        break
      case 'abnormal':
        filtered = filtered.filter((review) => review?.abLabResult?.isAbnormal)
        break
    }
  }

  return filtered
}