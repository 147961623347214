import { Audit } from './Audit'
import { Location } from './Location'
import { FileType } from './tasks'

type PrescriptionBaseType = {
  id?: string,
  drug: Drug,
  startDate: Date,
  endDate?: Date,
  historical: boolean,
  status?: PrescriptionStatus,
  version: string,
  dosages: DosageType[],
  quantityAmount: number,
  quantityUnits: number,
  substitutions: string,
  link: PrescriptionDosageLink,
  usage: PrescriptionUsage
  renewedOn?: Date,
  renewedFrom?: number,
  renewedFromVersion?: string,
  additionalInstructions?: string,
  dosageForm?: string,
  indication?: string,
  refills?: number,
  refillsExpire?: Date,
  usageCount?: number,
  audit?: Audit
}

export type PrescriptionType = {
  practitioner?: {
    practitionerId: string,
    practitionerName: string,
    locationId?: string,
    locationName?: string
  },
} & PrescriptionBaseType

export type PrescriptionPrintType = {
  practitioner?: {
    practitionerId: string,
    practitionerName: string,
    locationId?: string,
    locationName?: string
    location?: Location
    signatureFile?: FileType
  }
} & PrescriptionBaseType

export type PrescriptionFavouriteType = {
  id: string
  drug: Drug
  version: string
  dosages: DosageType[]
  quantityAmount: number
  quantityUnits: number
  substitutions: string
  link: PrescriptionDosageLink
  usage: PrescriptionUsage
  audit?: Audit
}

export type PrescriptionFavouriteAndRecentResultType = {
  id?: string
  drug: Drug
  endDate?: Date
  version: string
  dosages: DosageType[]
  quantityAmount: number
  quantityUnits: number
  substitutions: string
  link: PrescriptionDosageLink
  usage: PrescriptionUsage
  audit?: Audit
}

export enum PrescriptionUserType {
  RECENT = 'RECENT',
  FAVOURITE = 'FAVOURITE',
}

export type DosageType = {
  id?: string,
  dosageRange?: string,
  dosageUnits?: number,
  durationAmount?: number,
  durationUnits?: number,
  frequency?: string,
  prn?: boolean,
  version?: string,
  dosageOrder: number
}

export type Drug = {
  name: string,
  code?: string | null,
  codeType?: CcddMpType | null,
  brand?: string | null,
  drug?: string | null,
  ingredient?: string | null
}

export enum PrescriptionStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum PrescriptionUsage {
  CONTINUOUS = 'CONTINUOUS',
  SHORT_TERM = 'SHORT_TERM',
}

export enum PrescriptionDosageLink {
  AND = 'AND',
  THEN = 'THEN',
}

export enum CcddMpType {
  DIN = "DIN",
  NPN = "NPN",
  CCDD = "CCDD"
}

export const PrescriptionStatusMap: Map<PrescriptionStatus, {label: string, order: number}> = new Map ([
  [PrescriptionStatus.DRAFT, {label: "Draft", order: 0}],
  [PrescriptionStatus.ACTIVE, {label: "Active", order: 1}],
  [PrescriptionStatus.INACTIVE, {label: "Inactive", order: 2}]
])