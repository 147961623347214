import React, { Suspense } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box } from '@mui/material'
import { EmptyList, ErrorBoundary } from 'saga-library/src'
import { LoadingSpinner } from '../../../../components/LoadingScreen'

export interface NestedMenuItemProps extends MenuItemProps {
  parentMenuOpen?: boolean // Determines if the parent menu is open
  label?: React.ReactNode // Label displayed in the menu item
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode // Custom icon shown on the right
  keepOpen?: boolean // Keeps the submenu open
  className?: string // Additional class names for styling
  tabIndex?: number // Tab index for keyboard navigation
  ContainerProps?: React.HTMLAttributes<HTMLDivElement> & {
    ref?: React.RefObject<HTMLDivElement>
  } // Props for the container div
  rightAnchored?: boolean // Whether the submenu is anchored to the right
  children?: React.ReactNode // Submenu items
  items?: React.ReactNode[]
  onOpen?: ()=>void
  getMenuItemComponent?: (item:any)=>React.ReactNode
  onNestedItemClick?: (e: any, v:any) => void
}

const NestedDropdownItem = React.forwardRef<HTMLElement, NestedMenuItemProps>((props, ref) => {
  const {
    parentMenuOpen,
    label,
    leftIcon,
    rightIcon = <ChevronRightIcon />,
    keepOpen,
    items=[],
    children,
    className,
    tabIndex: tabIndexProp,
    ContainerProps: ContainerPropsProp = {},
    rightAnchored,
    getMenuItemComponent,
    onOpen,
    onClick,
    onNestedItemClick,
    ...MenuItemProps
  } = props

  const { ref: containerRefProp, ...ContainerProps } =
    ContainerPropsProp

  const menuItemRef: React.RefObject<HTMLLIElement> = React.useRef(null)
  React.useImperativeHandle(ref, () => menuItemRef.current as HTMLLIElement)

  const containerRef: React.RefObject<HTMLDivElement> = React.useRef(null)
  React.useImperativeHandle(
    containerRefProp,
    () => containerRef.current as HTMLDivElement
  )

  const menuContainerRef: React.RefObject<HTMLDivElement> = React.useRef(null)

  const [isSubMenuOpen, setIsSubMenuOpen] = React.useState(false)

  const handleMouseEnter = (event) => {
    setIsSubMenuOpen(true)
    if(onOpen){
      onOpen()
    }
    if (ContainerProps?.onMouseEnter) {
      ContainerProps.onMouseEnter(event)
    }
  }

  const handleMouseLeave = (event) => {
    setIsSubMenuOpen(false)

    if (ContainerProps?.onMouseLeave) {
      ContainerProps.onMouseLeave(event)
    }
  }

  const isSubmenuFocused = () => {
    const active = containerRef.current?.ownerDocument?.activeElement
    const children = Array.from(menuContainerRef.current?.children || [])

    for (const child of children) {
      if (child === active) {
        return true
      }
    }
    return false
  }

  const handleFocus = (event) => {
    if (event.target === containerRef.current) {
      setIsSubMenuOpen(true)
    }

    if (ContainerProps?.onFocus) {
      ContainerProps.onFocus(event)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      return
    }

    if (isSubmenuFocused()) {
      event.stopPropagation()
    }

    const active = containerRef.current?.ownerDocument?.activeElement

    if (event.key === 'ArrowLeft' && isSubmenuFocused()) {
      containerRef.current?.focus()
    }

    if (
      event.key === 'ArrowRight' &&
      event.target === containerRef.current &&
      event.target === active
    ) {
      const firstChild = menuContainerRef.current?.children[0] as HTMLElement
      firstChild?.focus()
    }
  }

  const open = isSubMenuOpen && parentMenuOpen

  let tabIndex
  if (!props.disabled) {
    tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1
  }

  return (
    <Box
      {...ContainerProps}
      ref={containerRef}
      onFocus={handleFocus}
      tabIndex={tabIndex}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onKeyDown={handleKeyDown}
    >
      <MenuItem
        {...MenuItemProps}
        data-open={!!open || undefined}
        className={className}
        ref={menuItemRef}
        sx={{gap:1}}
        onClick={onClick}
      >
        {leftIcon}
        <Box sx={{ flexGrow: 1 }}>
          {label}
        </Box>
        {rightIcon}
      </MenuItem>
      <Menu
        hideBackdrop
        sx={{ pointerEvents: 'none' }}
        anchorEl={menuItemRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: rightAnchored ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: rightAnchored ? 'right' : 'left',
        }}
        open={!!open}
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
        onClose={() => {
          setIsSubMenuOpen(false)
        }}
      >
        <ErrorBoundary
          fallback={<Box sx={{ color: (theme) => theme.palette.error.main }}>Failed to load</Box>}
        >
          <Suspense
            fallback={
              <Box sx={{ width: "200px", p: 1 }}>
                <LoadingSpinner label={null} />
              </Box>
            }
          >
            <Box ref={menuContainerRef} sx={{ pointerEvents: 'auto' }}>
              {items.length === 0 &&  <EmptyList sx={{p:1, width:"200px"}} message={ `No associated ${label}`} />}
              {items.map((item, index) => {
                  if (getMenuItemComponent) {
                    return getMenuItemComponent(item)
                  }

                return React.isValidElement(item)
                  // @ts-ignore
                    ? React.cloneElement(item, {key:`item-select-item-${item.type}-${index}`, onSelect:(e, v) => onNestedItemClick(e, v) })
                    : item
                }
              )}
            </Box>
          </Suspense>
        </ErrorBoundary>
      </Menu>
    </Box>
  )
})

export default NestedDropdownItem