import { Box } from "@mui/material";
import React from "react";

export const FormRow = ({ children, sx={} }) => {
  return <Box
    sx={{
      display: 'flex',
      alignItems: 'start',
      gap: 2,
      ...sx
    }}>
    {children}
  </Box>
}