import React, { Suspense, useEffect, useState } from 'react'
import {
  TaskAssignmentInput,
  TaskAssignmentType,
} from '../../../../types/tasks/TaskAssignment'
import { FormProvider, useForm } from 'saga-library/src/components/Form'
import { TaskForm } from '../../../inbox/components/Tasks/TaskForm'
import { ConfirmationDialog, DialogV2, RemoveButton } from 'saga-library/src'
import { taskDefaults } from '../../../inbox/components/Tasks/TaskDefaultValues'
import { schema } from '../../../inbox/components/Tasks/TaskValidationsSchema'
import { usePrompt } from '../../../../providers/NavigationPrompt'
import { usePatientTaskContext } from '../../providers/PatientTaskProvider'
import { convertTaskToInput } from '../../../../utils/TaskUtils'
import { useSuspenseQuery } from '@apollo/client'
import { GET_TASK_ASSIGNMENT } from '../../../../graphql-definitions'
import { useParams } from 'react-router-dom'
import _get from 'lodash/get'
import { LoadingSpinner } from '../../../../components/LoadingScreen'
import { useFormContext } from 'react-hook-form'

const FORM_NAME = "update_task_form"

interface UpdateTaskModalProps {
  task?: TaskAssignmentType
  setTask: (open?: TaskAssignmentType) => void
}

export const UpdateTaskModal = (
{
  task,
  setTask
}: UpdateTaskModalProps) => {
  const { enableNavigationPrompt } = usePrompt()
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)
  const { updateTaskFromChart, deleteTaskFromChart } = usePatientTaskContext()

  const formMethods = useForm<TaskAssignmentInput>({
    defaultValues: taskDefaults,
    schema: schema
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset
  } = formMethods

  useEffect(() => {
    if (!!task) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [task, Object.keys(dirtyFields).length, openNavigationPrompt])

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        onModalClose()
      }
    }
  }

  const onModalClose = () => {
    setTask(undefined)
    reset(taskDefaults)
  }

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      onModalClose()
    }
  }

  const onSave =  handleSubmit(async(data: TaskAssignmentInput) => {
    await updateTaskFromChart(data, onModalClose)
  },
    (error) => console.log(error))

  const onDelete = async () => {
    await deleteTaskFromChart(task?.id!, task?.version!, onModalClose)
  }

  return <DialogV2
    title={'Edit task'}
    open={!!task}
    onClose={onCancel}
    formName={FORM_NAME}
    submitting={isSubmitting}
    headerActions={[
      <RemoveButton
        key={'update-task-modal-delete'}
        onClick={() => setOpenConfirmation(true)}
        dataTestId={'update-task-modal'}
      />
    ]}
    sx={{maxHeight:"940px"}}
  >
    {task &&
    <Suspense fallback={<LoadingSpinner />}>
      <FormProvider {...formMethods}>
        <QueryTaskForm task={task} onSave={onSave} />
      </FormProvider>
    </Suspense>}

    <ConfirmationDialog
      open={openConfirmation}
      onClose={() => setOpenConfirmation(false)}
      title={'Delete task?'}
      message={'This action can\'t be undone.'}
      primaryAction={() => {
        setOpenConfirmation(false)
        onDelete()
      }}
      primaryLabel={'Delete'}
      dataTestId={'update-task-modal-dialog-delete'}
    />
  </DialogV2>
}

const QueryTaskForm = ({task, onSave}) => {
  const { tenant_id } = useParams()

  const formMethods = useFormContext()

  const { data } = useSuspenseQuery(GET_TASK_ASSIGNMENT,
    {variables: {
        tenantId: tenant_id,
        taskId: task.id,
      }}
  )

  const { reset } = formMethods

  useEffect(() => {
    if(data){
      const fullTaskData = _get(data, 'tenant.task.assignment.get', null)
      if(fullTaskData) {
        reset(convertTaskToInput(fullTaskData!))
      }
    }
  }, [data])

  return (
      <TaskForm
        onSubmit={onSave}
        formName={FORM_NAME}
        canClearPatient={false}
        showTemplateSelect={false}
        dataTestId={'update-task-modal-form'}
      />
  )
}