import React, { useEffect, useState } from 'react'
import { TextField, Select, IconButton } from 'saga-library/src'
import {
  EncounterNoteField,
  EncounterNoteFieldProps,
  EncounterNoteTemplateFieldTree
} from '../../../../../types/patients/EncounterNote'
import { Box, InputAdornment, Menu, MenuItem } from '@mui/material'
import { LengthHeightUnitList, LengthHeightUnits, WeightUnitList, WeightUnits } from '../../vitals/VitalTypes'
import { AddVitalIcon } from '../../ChartIcons'
import { getInputNameForField, VitalEntryTypeList } from '../EncounterNoteUtil'
import { EncounterNoteContainer } from './EncounterNoteContainer'
import { useFormContext, useWatch } from 'react-hook-form'

const VitalAction = ({disabled, dataTestId, onClick}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleAddButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, type: string) => {
    handleMenuClose(event)
    onClick(type)
  }

  return (
    <>
      <IconButton
        icon={<AddVitalIcon />}
        dataTestId={`${dataTestId}-add-vital-button`}
        onClick={handleAddButtonClick}
        disabled={disabled}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={(e) => handleMenuItemClick(e, 'bloodPressure')}>Additional blood pressure measurement</MenuItem>
        <MenuItem onClick={(e) => handleMenuItemClick(e, 'heartRate')}>Additional heart rate measurement</MenuItem>
      </Menu>
    </>
  )
}

const UnitSelect = ({ units, name, dataTestId, isSigned, ...props }) => {
  const { getValues } = useFormContext()
  if (isSigned) {
    return (
      <Box sx={{ marginRight: '8px' }} data-testid={dataTestId}>
        {units.find((unit) => unit.value === getValues(name))?.label}
      </Box>
    )
  }
  return (
    <Select
      label={''}
      name={name}
      dataTestId={dataTestId}
      sx={{
        color: 'greys.medium',
        border: 'none',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        px: 0,
      }}
      {...props}
    >
      {units.map((unit) => (
        <MenuItem key={unit.value} value={unit.value}>
          {unit.label}
        </MenuItem>
      ))}
    </Select>
  )
}


const unitsSelector = (unitType, name, disabled, isSigned, dataTestId) => {
  switch (unitType) {
    case 'lengthHeight':
      return (
        <UnitSelect
          name={`${name}.lengthHeightUnit`}
          units={LengthHeightUnitList}
          defaultValue={LengthHeightUnits.CM}
          disabled={disabled}
          isSigned={isSigned}
          dataTestId={`${dataTestId}-lengthHeightUnit`}
        />
      )
    case 'weight':
      return (
        <UnitSelect
          name={`${name}.weightUnit`}
          units={WeightUnitList}
          defaultValue={WeightUnits.KG}
          disabled={disabled}
          isSigned={isSigned}
          dataTestId={`${dataTestId}-weightUnit`}
        />
      )
    case 'headCircumference':
      return (
        <UnitSelect
          name={`${name}.headCircumferenceUnit`}
          units={LengthHeightUnitList}
          defaultValue={LengthHeightUnits.CM}
          disabled={disabled}
          isSigned={isSigned}
          dataTestId={`${dataTestId}-headCircumferenceUnit`}
        />
      )
    case 'bloodPressure':
      return 'mm Hg'
    case 'heartRate':
      return 'bpm'
    default:
      return ''
  }
}

function sortFields (a: EncounterNoteField, b: EncounterNoteField): number {
  return a.additionalFieldOrder! - b.additionalFieldOrder!
}

interface EncounterNoteVitalsProps extends EncounterNoteFieldProps {
  templateField: EncounterNoteTemplateFieldTree
  additionalFields: EncounterNoteField[]
  hasInitialVitalData?: boolean
}

export const EncounterNoteVitals = ({
  name,
  isSigned,
  disabled,
  dataTestId,
  templateField,
  additionalFields,
  hasInitialVitalData
}: EncounterNoteVitalsProps) => {
  const [addVital, setAddVital] = useState<'bloodPressure' | 'heartRate' | null>(null)
  const [vitalEntries, setVitalEntries] = useState<React.ReactNode[]>([])
  const [additionalVitalCount, setAdditionalVitalCount] = useState<number>(
    additionalFields.reduce((maxOrder, field) => Math.max(maxOrder, field.additionalFieldOrder ?? 0), 0)
  )
  const [bloodPressureTemplateFieldId, setBloodPressureTemplateFieldId] = useState<string>('')
  const [heartRateTemplateFieldId, setHeartRateTemplateFieldId] = useState<string>('')

  useEffect(() => {
    if (!templateField.subFields) return

    const heartRateId = templateField.subFields.find((field) => field.name === 'heartRate')?.id || ''
    const bloodPressureId = templateField.subFields.find((field) => field.name === 'bloodPressure')?.id || ''
    setHeartRateTemplateFieldId(heartRateId)
    setBloodPressureTemplateFieldId(bloodPressureId)

    if (templateField.subFields.length > 0) {
      const additionalEntries = additionalFields.sort(sortFields).map((field) => {
        switch (field.templateFieldId){
          case heartRateId:
            const heartRateInfo = VitalEntryTypeList.find((vital) => vital.value === 'heartRate')!
            return <BasicVitalEntry
              key={`${field.templateFieldId}-${field.additionalFieldOrder}`}
              label={heartRateInfo.label}
              name={`vitals-${field.templateFieldId}-${field.additionalFieldOrder}.heartRate`}
              dataTestId={`${dataTestId}-heartRate-${field.additionalFieldOrder}`}
              units={'bpm'}
              numberType={heartRateInfo.numberType}
              disabled={disabled}
              isSigned={isSigned}
            />
          case bloodPressureId:
            return <BloodPressureEntry
              key={`${field.templateFieldId}-${field.additionalFieldOrder}`}
              id={field.templateFieldId!}
              order={field.additionalFieldOrder!}
              dataTestId={dataTestId}
              disabled={disabled}
              isSigned={isSigned}
            />
          default:
            return <></>
        }
      })
      const originalEntries = templateField.subFields.map((field) => {
        const vitalDetails = VitalEntryTypeList.find((vital) => vital.value === field.name)!
        if (field.name === 'bloodPressure') {
          return <BloodPressureEntry
            key={`${field.id}`}
            id={`${field.id}`}
            order={0}
            dataTestId={dataTestId}
            disabled={disabled}
            isSigned={isSigned}
          />
        }
        return (
          <BasicVitalEntry
            key={`${field.id}-0`}
            label={vitalDetails.label}
            name={`${getInputNameForField(field)}.${field.name}`}
            dataTestId={`${dataTestId}-${field.name}-0`}
            units={unitsSelector( field.name, getInputNameForField(field), disabled, isSigned, dataTestId)}
            numberType={vitalDetails.numberType}
            disabled={disabled}
            isSigned={isSigned}
          />
        )
      })
      setVitalEntries([...originalEntries, ...additionalEntries])
    }
  }, [templateField.subFields, additionalFields])


  useEffect(() => {
    if (!addVital) return

    const newIndex = additionalVitalCount + 1

    setVitalEntries((prev) => {
      const newEntries = [...prev]
      switch (addVital) {
        case 'bloodPressure':
          newEntries.push(
            <BloodPressureEntry
              key={`${bloodPressureTemplateFieldId}-${newIndex}`}
              id={bloodPressureTemplateFieldId!}
              order={newIndex}
              dataTestId={dataTestId}
              disabled={disabled}
              isSigned={isSigned}
            />
          )
          break
        case 'heartRate':
          const heartRateDetails = VitalEntryTypeList.find((vital) => vital.value === 'heartRate')!
          newEntries.push(
            <BasicVitalEntry
              key={`${heartRateTemplateFieldId}-${newIndex}`}
              label={heartRateDetails.label}
              name={`vitals-${heartRateTemplateFieldId}-${newIndex}.heartRate`}
              dataTestId={`${dataTestId}-heartRate-${newIndex}`}
              units={'bpm'}
              numberType={heartRateDetails.numberType}
              disabled={disabled}
              isSigned={isSigned}
            />
          )
          break
        default:
          break
      }
      return newEntries
    })

    setAdditionalVitalCount(newIndex)
    setAddVital(null)
  }, [addVital, setAddVital, additionalVitalCount, setAdditionalVitalCount, heartRateTemplateFieldId, bloodPressureTemplateFieldId])


  return (
    <EncounterNoteContainer
      key={templateField.id}
      templateField={templateField}
      dataTestId={dataTestId}
      defaultStyle={'controlled'}
      collapsed={!hasInitialVitalData}
      actions={(
        <VitalAction
          disabled={disabled}
          dataTestId={dataTestId}
          onClick={setAddVital}
        />
      )}
    >
      <Box
        key={name}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          gap: 2
        }}
      >
        {vitalEntries}
      </Box>
    </EncounterNoteContainer>
  )
}

interface BasicVitalEntryProps {
  label: string
  numberType: 'whole' | 'float'
  name: string
  dataTestId: string
  units: string | React.ReactNode
  disabled?: boolean
  isSigned?: boolean
}

const BasicVitalEntry = ({ label, numberType, name, dataTestId, units, disabled, isSigned }: BasicVitalEntryProps ) => {
  const [ inFocus, setInFocus ] = useState(false)
  const { control } = useFormContext()
  const value = useWatch({
    control,
    name: name
  })

  let pattern = /^[0-9]*\.?[0-9]+$/
  if (numberType === 'whole') {
    pattern = /^[1-9]\d*$/
  }

  const onFocus = () => setInFocus(true)
  const onBlur = () => setInFocus(false)

  return (
    <TextField
      label={label}
      name={name}
      dataTestId={dataTestId}
      hideHelperText={true}
      disabled={disabled}
      type={'number'}
      regexPattern={pattern}
      sx={{
        flex: '1 0 140px'
      }}
      slotProps={{
        input: {
          readOnly: isSigned,
          endAdornment:  (value || inFocus) &&
            <InputAdornment position="end" sx={{ marginRight: typeof units === 'string' ? '-8px' : '-16px' }} onFocus={onFocus}>
              {units}
            </InputAdornment>
        }
      }}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  )
}

interface BloodPressureEntryProps {
  id: string
  order: number
  dataTestId: string
  isSigned?: boolean
  disabled?: boolean
}

const BloodPressureEntry = ({id, order, dataTestId, isSigned, disabled}: BloodPressureEntryProps) => {
  const bloodPressureInfo = VitalEntryTypeList.find((vital) => vital.value === 'bloodPressure')!
  return (
    <React.Fragment key={id}>
      <BasicVitalEntry
        key={`${id}-systolic-${order}`}
        label={`Systolic ${bloodPressureInfo.label}`}
        name={`vitals-${id}-${order}.bloodPressureSystolic`}
        dataTestId={`${dataTestId}-bloodPressureSystolic-${order}`}
        units={'mm Hg'}
        numberType={bloodPressureInfo.numberType}
        disabled={disabled}
        isSigned={isSigned}
      />
      <BasicVitalEntry
        key={`${id}-diastolic-${order}`}
        label={`Diastolic ${bloodPressureInfo.label}`}
        name={`vitals-${id}-${order}.bloodPressureDiastolic`}
        dataTestId={`${dataTestId}-bloodPressureDiastolic-${order}`}
        units={'mm Hg'}
        numberType={bloodPressureInfo.numberType}
        disabled={disabled}
        isSigned={isSigned}
      />
    </React.Fragment>
  )
}