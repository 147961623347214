import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import {ConfirmationDialog, Form, TextField} from 'saga-library/src'
import { FormRow } from '../../../../components/FormRow'
import { AssignedTo } from '../../../../components/AssignedTo'
import { PractitionerSelect } from '../../../../components/PractitionersSelect'
import FormattedDatePicker from '../../../../components/FormattedDatePicker'
import { TaskTemplateSelect } from '../../../../components/TaskTemplateSelect'
import { TaskStateSelect } from '../../../../components/TaskStateSelect'
import { PatientSearch } from '../../../../components/SearchControls/Patient/PatientSearch'
import { TaskTypeSelect } from '../../../../components/TaskTypeSelect'
import { TaskPrioritySelect } from '../../../../components/TaskPrioritySelect'
import { useFormContext } from 'react-hook-form'
import { rules } from './TaskValidationsSchema'
import { CommentsField } from './CommentsField'
import LinkItemSelect from '../LinkItemSelect/LinkItemSelect'
import { PermissionForm } from '../../../../components/PermissionForm'
import { PermissionType } from '../../../../types/settings/Permission'
import TabFocusInterceptor from 'saga-library/src/hooks/tabFocusInterceptor'
import { TaskTemplate } from '../../../../types/tasks/TaskTemplate'
import moment from 'moment-timezone'

interface TaskFormProps {
  formName: string
  showTemplateSelect?: boolean
  canClearPatient?: boolean
  fetchDetails?: boolean
  autoFocus?: boolean
  dataTestId: string
}

export const TaskForm = ({
  requiredPermissionType,
  formDisabled = false,
  onSubmit,
  ...props
}: TaskFormProps & {
  requiredPermissionType?: PermissionType
  formDisabled?: boolean
  onSubmit: () => void
}) => {
  const { formName } = props

  if (requiredPermissionType !== undefined) {
    return (
      <PermissionForm
        name={formName}
        onSubmit={onSubmit}
        id={formName}
        requiredPermissionType={requiredPermissionType}
        readOnlyOverride={true}
        formDisabled={formDisabled}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingRight: '8px'
        }}
      >
        <TaskFormBase {...props} />
      </PermissionForm>
    )
  }

  return (
    <Form onSubmit={onSubmit} id={formName}>
      <TaskFormBase {...props} />
    </Form>
  )
}

const TaskFormBase = ({
  showTemplateSelect = true,
  canClearPatient = true,
  fetchDetails = false,
  autoFocus = false,
  dataTestId
}: TaskFormProps) => {
  const LINK_ITEM_NAME = "linkedItems"
  const {
    watch,
    setValue,
    getValues,
    reset,
    formState: {
      isDirty,
      dirtyFields,
      touchedFields,
    }
  } = useFormContext()
  const firstFieldRef = useRef<any>(null)
  const [hasFocus, setHasFocus] = useState(autoFocus)
  const [openRemovePatientConfirmation, setOpenRemovePatientConfirmation] = useState<boolean>(false)
  const [ taskTemplateChanged, setTaskTemplateChanged ] = useState<{
    message: string | null,
    selectedTaskTemplate?: TaskTemplate | null,
  }>({ message: null, selectedTaskTemplate: null })

  const setFocus = (focus) => {
    setHasFocus(focus)
  }
  TabFocusInterceptor(firstFieldRef, hasFocus, setFocus)

  useEffect(() => {
    if (firstFieldRef && !autoFocus) {
      setHasFocus(false)
    }
  }, [firstFieldRef])

  const id = watch('id')
  const version = watch('version')
  const templateValue = watch('template')

  const resetPatient = () => {
    setValue(LINK_ITEM_NAME, [])
    setValue("patient", null, { shouldDirty: true })
    setOpenRemovePatientConfirmation(false)
  }

  const clearTaskTemplateChanged = () => {
    setTaskTemplateChanged({ message: null, selectedTaskTemplate: null })
  }

  const taskTemplateOnChange = (selectedTaskTemplate: TaskTemplate|null|undefined) => {
    if (!selectedTaskTemplate) return

    reset({
      // patient and linkedItems should persist when changing between templates
      'patient': getValues('patient'),
      'linkedItems': getValues('linkedItems'),
      'template': selectedTaskTemplate,
      'name': selectedTaskTemplate.taskName,
      'description': selectedTaskTemplate.description,
      'typeId': selectedTaskTemplate.type.id,
      'priority': selectedTaskTemplate.priority,
      'startDate': selectedTaskTemplate?.startOffset
        ? moment().add(selectedTaskTemplate.startOffset, 'days')
        : moment(),
      'dueDate': selectedTaskTemplate?.duration !== null
        ? moment().add((selectedTaskTemplate?.startOffset ?? 0) + selectedTaskTemplate.duration, 'days')
        : moment(),
      'practitionerId': selectedTaskTemplate.practitioner?.id,
      'assignedTo': selectedTaskTemplate.assignedRole ? {
        type: 'role',
        value: selectedTaskTemplate.assignedRole.id
      } : {
        type: 'user',
        value: selectedTaskTemplate.assignedUser?.id
      },
      'version': getValues('version')
    }, { keepDirty: false })

    clearTaskTemplateChanged()
  }

  const handleTemplateOnChange = (selectedTemplate: TaskTemplate|null|undefined) => {
    if (isDirty && Object.keys(dirtyFields).length > 0) {
      setTaskTemplateChanged({
        message: 'Changing the template will discard any unsaved changes. Are you sure you want to continue?',
        selectedTaskTemplate: selectedTemplate
      })
    }
    else {
      taskTemplateOnChange(selectedTemplate)
    }
  }

  return (
    <>
      <ConfirmationDialog
          title={"Remove patient?"}
          message={"Linked items will be removed."}
          open={openRemovePatientConfirmation}
          primaryLabel={"Remove"}
          primaryAction={resetPatient}
          onClose={()=>setOpenRemovePatientConfirmation(false)}
      />
      {!!showTemplateSelect && (
        <TaskTemplateSelect
          inputRef={firstFieldRef}
          name={'template'}
          label={'Template'}
          value={templateValue}
          autoFocus={autoFocus}
          onChange={handleTemplateOnChange}
          dataTestId={`${dataTestId}-template`}
        />
      )}
      <FormRow>
        <TaskTypeSelect
          name={'typeId'}
          label={'Type'}
          sx={{ flex: '0 0 calc(25% - 12px)' }}
          dataTestId={`${dataTestId}-type`}
        />
        <TextField
          name={'name'}
          label={'Name'}
          slotProps={{ htmlInput: { maxLength: rules.name.max } }}
          fullWidth={true}
          dataTestId={`${dataTestId}-name`}
        />
      </FormRow>
      <FormRow>
        <Box sx={{ flex: '1 1 50%' }}>
          <AssignedTo
            name={'assignedTo'}
            label={'Assigned to'}
            includeRoles={true}
            dataTestId={`${dataTestId}-assignedTo`}
          />
          <FormRow>
            <TaskStateSelect
              name={'state'}
              label={'State'}
              dataTestId={`${dataTestId}-state`}
            />
            <TaskPrioritySelect
              name={'priority'}
              label={'Priority'}
              dataTestId={`${dataTestId}-priority`}
            />
          </FormRow>
          <FormRow>
            <FormattedDatePicker
              name={'startDate'}
              label={'Start date'}
              sx={{ width: '100%' }}
              dataTestId={`${dataTestId}-startDate`}
            />
            <FormattedDatePicker
              name={'dueDate'}
              label={'Due date'}
              sx={{ width: '100%' }}
              dataTestId={`${dataTestId}-dueDate`}
            />
          </FormRow>
          <PractitionerSelect
            name={'practitionerId'}
            label={'Practitioner'}
            dataTestId={`${dataTestId}-practitioner`}
          />
          <PatientSearch
            key={id && `${id}-${version}`}
            name={'patient'}
            label={'Patient'}
            defaultStyle={'expanded'}
            fetchDetails={!!id || !!fetchDetails}
            disabled={!canClearPatient}
            dataTestId={`${dataTestId}-patient`}
            patientLabelSx={{
              mb:3
            }}
            onClear={()=>setOpenRemovePatientConfirmation(true)}
          />
          <LinkItemSelect name={LINK_ITEM_NAME} />
        </Box>
        <Box sx={{ flex: '1 1 50%' }}>
          <TextField
            name={'description'}
            label={'Description'}
            multiline={true}
            rows={3}
            fullWidth={true}
            dataTestId={`${dataTestId}-description`}
          />
          <CommentsField dataTestId={`${dataTestId}`} key={`task_comments_${id}`}/>
        </Box>
      </FormRow>
      <ConfirmationDialog
        open={!!taskTemplateChanged.message}
        title={'Change task template?'}
        message={taskTemplateChanged.message}
        primaryAction={() => {
          taskTemplateOnChange(taskTemplateChanged.selectedTaskTemplate)
        }}
        primaryLabel={'discard'}
        onClose={clearTaskTemplateChanged}
        dataTestId={'change-task-template-confirmation'}
      />
    </>
  )
}