import React from 'react'
import { Box } from '@mui/material'
import { Section } from 'saga-library/src'
import { PharmacyArray } from "../../../components/SearchControls/Pharmacy/PharmacyArray";
import { Pharmacy } from "../../../types";

export type PatientPharmacyDetailsType = {
  pharmacies?: Pharmacy[]
  pharmacyIds?: string[]
}

const defaults: PatientPharmacyDetailsType = {
  pharmacies: [],
  pharmacyIds: []
}

const Form = (props) => {
  const dataTestId = props?.dataTestId
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      <Section.SubHeader>Pharmacies</Section.SubHeader>
      <PharmacyArray
        dataTestId={dataTestId}
        name={'pharmacies'}
      />
    </Box>
  )
}

export const PatientPharmacyDetails = {
  Form: Form,
  defaults: defaults,
}
