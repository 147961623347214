import React from "react";
import { Select, Typography } from "saga-library/src";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { Box, CircularProgress, MenuItem } from "@mui/material";
import { LIST_APPOINTMENT_STATES } from "../graphql-definitions";

interface AppointmentStateSelectProps {
  name?: string
  label?:string
  onChange?: (value) => void
  disabled?:boolean
  dataTestId?: string
}
export const AppointmentStateSelect = ({
  name="appointment_state_select",
  label="Appointment state",
  onChange,
  disabled=false,
  dataTestId
}: AppointmentStateSelectProps) => {
  const { tenant_id } = useParams()
  const { showErrorAlert } = useAlerts()

  const { loading, data } = useQuery(LIST_APPOINTMENT_STATES, {
    fetchPolicy: 'cache-first',
    variables: { tenantId: tenant_id },
    onError: (error) => {
      showErrorAlert('Unable to retrieve appointment states.')
      console.error(JSON.stringify(error, null, 2))
    }
  })

  const AppointmentStateMenuItem = ({ id, color, name }, index) => {
    return (
      <MenuItem data-testid={`${dataTestId}-menuItem-${index}`} value={id} key={index}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <ColorBox color={color} />
          <Typography> { name } </Typography>
        </Box>
      </MenuItem>
    )
  }

  return <Select
    dataTestId={dataTestId}
    name={name}
    label={label}
    onChange={onChange}
    disabled={disabled}
    defaultValue={''}
  >
    {loading && (
      <MenuItem>
        <CircularProgress />
      </MenuItem>
    )}

    {data && data.tenant.schedule.state.listStates.map(AppointmentStateMenuItem)}
  </Select>
}

export const ColorBox = ({ color }) => {
  return <Box
    sx={theme => ({
      backgroundColor: color,
      borderRadius:1,
      boxShadow: theme.shadows[2],
      mr:'4px',
      height: 20,
      width: 30
    })}
  />
}