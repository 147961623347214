import React, { createRef, useRef } from "react";
import { FileType } from "../../../types/tasks";
import { PDFDoc, PDFViewer } from '../../../components/FoxitPDFViewer/FoxitTypes'

interface PatientDocumentStateContextType {
  selectedFile: FileType | null,
  setSelectedFile: (file: FileType | null) => void,
  documentModified: boolean,
  setDocumentModified: (modified: boolean) => void,
  pdfDocRef?: React.MutableRefObject<PDFDoc | null>,
  pdfViewerRef?: React.MutableRefObject<PDFViewer | null>,
}

const defaultPatientDocumentContext: PatientDocumentStateContextType = {
  selectedFile: null,
  setSelectedFile: () => {},
  documentModified: false,
  setDocumentModified: () => {},
  pdfDocRef: createRef(),
  pdfViewerRef: createRef(),
}

const PatientDocumentStateContext = React.createContext(defaultPatientDocumentContext)

export const PatientDocumentStateProvider = ({ children }) => {
  const [selectedFile, setSelectedFile] = React.useState<FileType | null>(null)
  const [documentModified, setDocumentModified] = React.useState<boolean>(false);
  const pdfDocRef = useRef<PDFDoc | null>(null)
  const pdfViewerRef = useRef<PDFViewer| null>(null)

  const providerValue : PatientDocumentStateContextType = {
    selectedFile,
    setSelectedFile,
    documentModified,
    setDocumentModified,
    pdfDocRef,
    pdfViewerRef,
  }

  return (
    <PatientDocumentStateContext.Provider value={providerValue}>
      {children}
    </PatientDocumentStateContext.Provider>
  )
}

export const usePatientDocumentStateContext = () => {
  return React.useContext(PatientDocumentStateContext)
}
