import React from 'react'
import { useMutation } from '@apollo/client'
import { LoadingButton, Section, Typography, Form } from 'saga-library/src'
import _get from 'lodash/get'
import { useForm, FormProvider } from "saga-library/src/components/Form"
import * as yup from 'yup'
import { AcceptInviteFormProps, AcceptInviteFormValues } from '../../types/invitations/Invitation'
import Box from '@mui/material/Box/Box'
import { ACCEPT_INVITATION_EXISTING_USER } from "../../graphql-definitions";

export const AcceptInviteForm = (props: AcceptInviteFormProps) => {

  const [acceptInvite, { loading }] = useMutation(
    ACCEPT_INVITATION_EXISTING_USER, {
    onCompleted: (data) => {
      const success = _get(data, 'public.invitation.invitationAcceptedExistingUser.success')
      props.onSubmit(!!success)
    },
    onError: (error) => {
      props.setErrorMessage("The invitation could not be accepted.")
      props.onSubmit(false)
    },
    fetchPolicy: 'network-only'
  })

  const handleAcceptInvite = (data) => {
    props.setErrorMessage("")
    acceptInvite({
      variables: data
    })
  }

  const schema = yup.object().shape({
    hash: yup.string().required(),
    salt: yup.string().required(),
    passcode: yup.string().required(),
    userId: yup.string().required(),
  }).required();

  const acceptInviteFormMethods = useForm<AcceptInviteFormValues>({
    schema: schema
  })

  const {
    handleSubmit,
    register
  } = acceptInviteFormMethods

  register("hash", { value: props.hash })
  register("salt", { value: props.salt })
  register("passcode", { value: props.passcode })
  register("userId", { value: props.userId })

  return (<FormProvider {...acceptInviteFormMethods}>
    <Form id={'reset-password-form'} onSubmit={handleSubmit(handleAcceptInvite)} autoComplete={'off'} >
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Section.Header sx={{ mt: 2 }}>
          The invitation has been validated
        </Section.Header>
        <Typography variant={'body1'} sx={{ mt: 2 }}>
          You are invited to join
        </Typography>
        <Typography variant={'h2'} sx={{ mt: 2 }}>
          {props.tenantName}
        </Typography>
        <LoadingButton sx={{ mt: 2 }}
          name={'AcceptInviteButton'}
          type={'submit'}
          loading={loading}
          variant={'contained'}
        >
          Accept Invite
        </LoadingButton>
      </Box>
    </Form>
  </FormProvider >)
}