import React from 'react'
import { Select } from 'saga-library/src'
import { Box, MenuItem, MenuItemProps } from '@mui/material'
import { TaskPriority, TaskPriorityMap } from '../types/tasks/TaskPriority'

interface TaskPrioritySelectProps {
  label: string,
  name: string,
  disabled?: boolean,
  onChange?: (value) => void,
  dataTestId: string
}

const TaskTypePriorityOptions = [TaskPriority.URGENT, TaskPriority.HIGH, TaskPriority.MEDIUM, TaskPriority.LOW]

const PriorityMenuItem = ({ priority, dataTestId, ...props }: MenuItemProps & { priority: TaskPriority, dataTestId?: string }) => {
  const taskPriority = TaskPriorityMap.get(priority)
  return (
    <MenuItem {...props} sx={{ gap: 1 }} data-testid={`${dataTestId}-${priority}`}>
      {taskPriority?.icon}
      {taskPriority?.label}
    </MenuItem>
  )
}

export const TaskPrioritySelect = ({
  label = 'Priority',
  name,
  disabled = false,
  dataTestId,
}: TaskPrioritySelectProps) => {
  return (
    <Select
      name={name}
      label={label}
      disabled={disabled}
      renderValue={(value) => {
        const priority = TaskPriorityMap.get(value)
        return (
          <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 1 }}>
            {priority?.icon}
            {priority?.label}
          </Box>
        )
      }}
      dataTestId={`${dataTestId}-priority`}
    >
      {TaskTypePriorityOptions.map(priority => (
        <PriorityMenuItem key={priority} priority={priority} value={priority} dataTestId={`${dataTestId}-priority`} />
      ))}
    </Select>
  )
}