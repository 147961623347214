import { HighIconImage, LowIconImage, MediumIconImage, UrgentIconImage } from '../../apps/patients/components/ChartIcons'
import React from 'react'

export enum TaskPriority {
  URGENT = 'URGENT',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export const TaskPriorityMap: Map<TaskPriority, {label: string, order: number, icon: React.ReactNode}> = new Map ([
  [TaskPriority.URGENT, {label: "Urgent", order: 1, icon: <UrgentIconImage />}],
  [TaskPriority.HIGH, {label: "High", order: 2, icon: <HighIconImage />}],
  [TaskPriority.MEDIUM, {label: "Medium", order: 3, icon: <MediumIconImage />}],
  [TaskPriority.LOW, {label: "Low", order: 4, icon: <LowIconImage />}],
])