import React from 'react'
import { Box, SxProps, Theme } from '@mui/material'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import { DragDropContext, Droppable, Draggable, DroppableProvided, DraggableProvided } from 'react-beautiful-dnd'
import { RemoveButton, Typography } from 'saga-library/src'
import { EmptyList } from '../EmptyList'

type GenericBase = {
  label: string
}

interface DraggableListProps<Type> {
  listItems: Type[]
  updateListItems: (L: Type[]) => void
  isDraggable?: boolean
  emptyListMessage: string
  disabled?: boolean
  sx?: SxProps<Theme>
  dataTestId: string
  renderItem?: (item: Type, index: number) => React.ReactNode
}

export const DraggableList = <Type extends GenericBase>({
  listItems = [],
  updateListItems,
  isDraggable = true,
  emptyListMessage,
  disabled,
  sx = {},
  dataTestId,
  renderItem
}: DraggableListProps<Type>) => {

  const showList = listItems && listItems.length > 0
  const isDragDisabled = !isDraggable || disabled

  const removeItem = (index: number) => {
    let newList = [...listItems]
    newList.splice(index, 1)
    updateListItems(newList)
  }

  const renderDraggableItem = (item: Type, index: number) => {
    return (
      <Draggable
        draggableId={index.toString()}
        index={index}
        key={index}
        isDragDisabled={isDragDisabled}
        data-testid={`${dataTestId}-draggableList`}
      >
        {(provided: DraggableProvided) => (
          <Box
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flex: "1 1 auto",
              gap: 1,
              "&:hover": {
                backgroundColor: (theme) => !disabled && theme.palette.backgrounds.hover,
                "button": {
                  visibility: "visible"
                }
              },
              userSelect: "none"
            }}
          >
            <Box sx={{ display: "inline-flex", alignItems: "center", gap: 1, pl: !isDragDisabled ? 0 : 1 }}>
              {!isDragDisabled && (
                <DragHandleIcon
                  sx={{ boxSizing: "content-box" }}
                  data-testid={`draggableListItem-${dataTestId}-dragHandleIcon`}
                />
              )}
              {!!renderItem ? renderItem(item, index) : <Typography>{item.label}</Typography>}
            </Box>
            {!disabled && (
              <RemoveButton
                onClick={() => removeItem(index)}
                sx={{ visibility: "hidden" }}
                dataTestId={dataTestId}
              />
            )}
          </Box>
        )}
      </Draggable>
    )
  }

  const onDragEndHandler = (event) => {
    if (!event.destination) {
      return
    }
    let newList = [...listItems]
    let movedItem = newList.splice(event.source.index, 1)[0]
    newList.splice(event.destination.index, 0, movedItem)
    updateListItems(newList)
  }

  if (!showList) {
    return <EmptyList message={emptyListMessage} />
  }

  return (
    <Box sx={{ display: "flex", flex: "1 1 auto", overflow: "hidden" }}>
      <Box sx={{ width: "100%", overflowY: "auto", ...sx }}>
        <DragDropContext onDragEnd={onDragEndHandler}>
          <Droppable droppableId={"droppable"}>
            {(provided: DroppableProvided) => (
              <Box
                ref={provided.innerRef}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: "1 1 auto"
                }}
                data-testid={`${dataTestId}-draggable-list`}
                {...provided.droppableProps}
              >
                {listItems.map(renderDraggableItem)}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Box>
  )
}

export default DraggableList