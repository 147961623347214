import React, {useState, useEffect} from 'react'
import {AbLabResult} from "../../../../../types/patients";
import {IconButton, SubsectionMenu, SubsectionMenuItem} from "saga-library/src";
import {useQuery} from "@apollo/client";
import {GET_LAB_AND_INVESTIGATION_HISTORY} from "../../../../../graphql-definitions";
import {useParams} from "react-router-dom";
import _get from "lodash/get";
import CloseIcon from "@mui/icons-material/Close";
import {Box, Typography} from "@mui/material";
import {useFormattedDate} from "../../../../../hooks/FormattedDate";
import {useLabAndInvestigationStateContext} from "../../../../../providers/LabAndInvestigationStateProvider";
import {LoadingSpinner} from "../../../../../components/LoadingScreen";
import {useFormContext} from "react-hook-form";
import {usePrompt} from "../../../../../providers/NavigationPrompt";

interface LabAndInvestigationHistoryProps {
  onClose: () => void
  formName: string
}

export const LabAndInvestigationHistory = ({ onClose, formName }: LabAndInvestigationHistoryProps) => {
  const { tenant_id } = useParams()
  const { selectedLabAndInvestigation, setSelectedLabAndInvestigation, labAndInvestigation } = useLabAndInvestigationStateContext()
  const [ labAndInvestigations, setLabAndInvestigations ] = useState<AbLabResult[]>([])
  const [ openNavigationPrompt, setOpenNavigationPrompt ] = useState<boolean>(false)
  const [ clickedLabAndInvestigationId, setClickedLabAndInvestigationId ] = useState<string | null>(null)
  const { reset, formState: { dirtyFields } } = useFormContext()
  const { enableNavigationPrompt } = usePrompt()

  const { loading } = useQuery(GET_LAB_AND_INVESTIGATION_HISTORY, {
    variables: {
      tenantId: tenant_id,
      id: labAndInvestigation?.id
    },
    onCompleted: (data) => {
      const values = _get(data, 'tenant.labAndInvestigationHistory.list', []) as AbLabResult[]
      setLabAndInvestigations([labAndInvestigation!, ...values])
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    }
  })

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        reset(undefined)
        if (clickedLabAndInvestigationId) {
          setSelectedLabAndInvestigation(clickedLabAndInvestigationId)
        }
      }
    }
    setClickedLabAndInvestigationId(null)
  }

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, formName, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    return () => enableNavigationPrompt(false, formName)
  }, [Object.keys(dirtyFields).length, openNavigationPrompt])

  const onClick = (labId: string) => {
    if (labId === selectedLabAndInvestigation?.id) {
      return
    }
    if (!!Object.keys(dirtyFields).length) {
      setClickedLabAndInvestigationId(labId)
      setOpenNavigationPrompt(true)
    }
    else {
      setSelectedLabAndInvestigation(labId)
    }
  }

  return (
    <SubsectionMenu
      sx={{
        border: "1px solid rgba(32, 41, 49, 0.32)",
        borderRadius: 2,
        height: "100%",
        mr: 1,
        gap: 2
      }}
      overflowY={"auto"}
      elevation={0}
      header={
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Typography variant={"h3"}>Older versions</Typography>
          <IconButton
            name={'dialog-header-close-button'}
            dataTestId={'labAndInvestigation-historyMenu-close-button'}
            onClick={onClose}
            icon={<CloseIcon/>}
          />
        </Box>
      }
      routes={
        loading
          ? [<LoadingSpinner/>]
          : labAndInvestigations.map(lab => {
            return (
              <LabAndInvestigationHistoryRow
                labAndInvestigation={lab}
                onSelect={lab => onClick(lab.id)}
                isActive={selectedLabAndInvestigation?.id === lab.id}
              />
            )
          })
      }
      dataTestId={"labAndInvestigation-historyMenu"}
    />
  )
}

interface LabAndInvestigationHistoryRowProps {
  labAndInvestigation: AbLabResult
  onSelect: (labAndInvestigation: AbLabResult) => void
  isActive: boolean
}

export const LabAndInvestigationHistoryRow = ({labAndInvestigation, onSelect, isActive}:LabAndInvestigationHistoryRowProps) => {
  const date = useFormattedDate(labAndInvestigation.date, true)
  return (
    <SubsectionMenuItem
      isActive={isActive}
      primaryText={labAndInvestigation.description}
      secondaryText={date}
      onClick={() => onSelect(labAndInvestigation)}
    />
  )
}