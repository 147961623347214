import React from "react";
import { HighIconImage, LowIconImage } from "../apps/patients/components/ChartIcons";
import { Audit } from './Audit'

export type AllergyType = {
  allergen: string | null,
  reactionType?: ReactionType | null,
  onsetDate?: string,
  reactionDescription: string,
  severity: AllergySeverity | null,
  id?: string,
  audit?: Audit
}

export enum AllergySeverity {
  HIGH = 'HIGH',
  LOW = 'LOW',
  UNABLE_TO_ASSESS = 'UNABLE_TO_ASSESS'
}

export const AllergySeverityMap: Map<AllergySeverity, {label: string, description: string, order: number, icon: React.ReactNode}> = new Map ([
  [AllergySeverity.HIGH, {label: "High", description: "High severity", order: 1, icon: <HighIconImage />}],
  [AllergySeverity.LOW, {label: "Low", description: "Low severity", order: 2, icon: <LowIconImage />}],
  [AllergySeverity.UNABLE_TO_ASSESS, {label: "No Assess", description: "Unable to assess severity", order: 3, icon: <></>}]
])

export enum ReactionType {
  ALLERGY = 'ALLERGY',
  INTOLERANCE = 'INTOLERANCE'
}

export const ReactionTypeMap: Map<ReactionType, {label: string, order: number}> = new Map ([
  [ReactionType.ALLERGY, {label:"Allergy", order: 1}],
  [ReactionType.INTOLERANCE, {label:"Intolerance", order: 2}]
])

export const getOnsetType = (allergy: AllergyType) => {
  if (allergy.reactionType && allergy.onsetDate) {
    return `${ReactionTypeMap.get(allergy.reactionType)?.label} since ${allergy.onsetDate}`
  }

  if (allergy.reactionType) {
    return ReactionTypeMap.get(allergy.reactionType)?.label
  }

  if (allergy.onsetDate) {
    return `Since ${allergy.onsetDate}`
  }
}