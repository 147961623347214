import { gql } from "@apollo/client";
import { APPOINTMENT_FRAGMENT } from './schedule/ScheduleQueries'

export const AB_LAB_RESULT_PRACTITIONER_IDENTIFIER_FRAGMENT = gql`
    fragment PractitionerIdentifierFragment on AbLabResultPractitionerIdentifier {         
        id
        identifier
        orderingFacilityId
        firstName
        lastName
    }
`

export const AB_LAB_RESULT_FRAGMENT = gql`
    fragment AbLabResultFragment on AbLabResult {
        id
        patientId
        labFileId
        gender {
            code
            display
        }
        patientIdentifiers {
            label
            assigningAuthority {
                code
                display
            }
        }
        patientName {
            firstName
            middleName
            lastName
        }
        patientAddress {
            street
            street2
            city
            province {
                code
                display
            }
            postalCode
            country {
                code
                display
            }
        }
        patientHomePhoneNumbers
        patientWorkPhoneNumbers
        dob
        practitioners {
            id
        }
        practitionersExceptionCleared
        isLinkedDocument
        version
    }
`

export const GET_AB_LAB_RESULT_DASHBOARD_COUNTS = gql`
    query GetAbLabResultCounts($tenantId: ID!, $startDate: DateTime!, $endDate: DateTime!){
        tenant(id: $tenantId) {
            dashboard {
                abLabResultCounts(startDate: $startDate, endDate: $endDate) {
                    exceptionCount
                    processedCount,
                    lastCheck,
                    nextCheck,
                    lastProcessed
                }
            }
        }
    }
`

export const LIST_AB_LAB_RESULT_EXCEPTIONS = gql`
    ${AB_LAB_RESULT_FRAGMENT}
    query ListAbLabResultExceptions($tenantId: ID!){
        tenant(id: $tenantId) {
            dashboard {
                listAbLabResultExceptions {
                    abLabResult {
                        ...AbLabResultFragment
                    }
                    practitionerException
                    patientException
                }
            }
        }
    }
`


export const LINK_AB_LAB_RESULT = gql`
    ${AB_LAB_RESULT_FRAGMENT}
    mutation LinkAbLabResult($tenantId: ID!, $abLabResultLink: AbLabResultLinkInput!) {
        tenant(id: $tenantId) {
            dashboard {
                linkAbLabResult(input: $abLabResultLink) {
                    abLabResultExceptions {
                        abLabResult {
                            ...AbLabResultFragment
                        }
                        practitionerException
                        patientException
                    }
                    nextAbLabResultId
                }
            }
        }
    }
`

export const LIST_AB_LAB_RESULT_EXCEPTION_PRACTITIONERS = gql`
    ${AB_LAB_RESULT_PRACTITIONER_IDENTIFIER_FRAGMENT}
    query ListAbLabResultExceptionPractitioners($tenantId: ID!, $abLabResultId: ID!) {
        tenant(id: $tenantId) {
            dashboard {
                listAbLabResultExceptionPractitioners(abLabResultId: $abLabResultId) {
                    ...PractitionerIdentifierFragment
                }
            }
        }
    }
`

const AGENDA_ITEMS_FRAGMENT = gql`
  ${APPOINTMENT_FRAGMENT}
  fragment AgendaItemList on ScheduleItem {
      ... on Appointment {
          ...Appointment
          relatedPatientItems {
            claimIds
            encounterNoteIds
            formIds
            letterIds
            prescriptionIds
            taskIds
          }
      }
      ... on ScheduleEvent {
          id
          resource: scheduleId
          version
          start
          end
          title
          allDay
      }
      ... on TemplateEvent {
        templateEventId: id
        resource: scheduleId
        title
        allDay
        startTimeSpan
        endTimeSpan
        scheduleDate
        templateName
      }
  }
`

export const GET_AGENDA_ITEMS = gql`
  ${AGENDA_ITEMS_FRAGMENT}
  query GetAgendaItems($tenantId: ID!, $scheduleIdList: [ID!]!, $date: DateTime!) {
    tenant(id: $tenantId) {
      dashboard {
        agendaItems(scheduleIds: $scheduleIdList, date: $date) {
          key
          value {
            ...AgendaItemList
          }
        }
      }
    }
  }
`

export const GET_REVIEW_DASHBOARD_COUNTS = gql`
  query GetReviewDashboardCounts($tenantId: ID!) {
    tenant(id: $tenantId) {
      dashboard {
        inboxCounts {
          tasks {
            urgent {
              total
              overdue
            }
            high {
              total
              overdue
            }
            medium {
              total
              overdue
            }
            low {
              total
              overdue
            }
          }
          labs {
            unreviewed
            abnormal
          }
          investigations
          documents
        }
      }
    }
  }
`