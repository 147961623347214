import React from "react";
import { Address } from "saga-client/src/components/Address";
import { TextField, TextFieldMask, Form } from "saga-library/src";
import { Box } from "@mui/material";
import { faxNumberMask, phoneNumberMask } from "../Locations/util/mask";

export const PharmacyForm = ({
  onSubmit,
  formName,
  width
}) => {
  return (
    <Form
      onSubmit={onSubmit}
      name={formName}
      id={formName}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: width,
          p: 2
        }}>
        <TextField
          name={'name'}
          label={'Name'}
          autoFocus
          dataTestId={"pharmacyForm-name"}
        />
        <TextFieldMask
          name={'faxNumber'}
          label={'Fax number'}
          formatMask={faxNumberMask}
          maskChar={''}
          dataTestId={"pharmacyForm-faxNumber"}
        />
        <TextFieldMask
          name={'phoneNumber'}
          label={'Phone number'}
          formatMask={phoneNumberMask}
          maskChar={''}
          dataTestId={"pharmacyForm-phoneNumber"}
        />
        <Address
          showCountry={false}
          dataTestId={"pharmacyForm-address"}
        />
      </Box>
    </Form>
  )

}