import React, { useEffect, useState } from 'react'
import { Typography } from 'saga-library/src'
import { Box, Menu, MenuItem, useTheme } from '@mui/material'
import { ColorBox } from './AppointmentStateSelect'
import Button from 'saga-library/src/components/Button/Button'
import { ExpandRightIcon } from '../apps/patients/components/ChartIcons'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { AppointmentState, AppointmentStateCategory } from '../types/schedule/AppointmentState'
import { AppointmentRoom } from '../types/schedule'
import moment from 'moment-timezone'
import { diffDates } from 'saga-library/src/util'

interface AppointmentStateDropdownProps {
  name?: string
  value: AppointmentState
  room?: AppointmentRoom
  startTime?: string
  options: AppointmentState[]
  rooms: AppointmentRoom[]
  onChangeState: (state: AppointmentState) => void
  onChangeRoom: (room: AppointmentRoom) => void
  disabled?: boolean
  dataTestId?: string
}
export const AppointmentStateDropdown = ({
  name = "appointment_state_select",
  value,
  room,
  startTime,
  options,
  rooms,
  onChangeState,
  onChangeRoom,
  disabled = false,
  dataTestId
}: AppointmentStateDropdownProps) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [roomAnchorEl, setRoomAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const openRooms = Boolean(roomAnchorEl)

  const isSameDay = startTime ? moment(startTime).isSame(moment(), 'day') : false

  const handleClose = () => {
    setAnchorEl(null)
    setRoomAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleInRoomClick = (event: React.MouseEvent<HTMLElement>) => {
    setRoomAnchorEl(event.currentTarget)
  }

  const AppointmentStateMenuItem = (state: AppointmentState, index: number) => {
    const hasRoomMenu = state.name === 'In room' && rooms.length > 0
    return (
      <MenuItem
        key={state.id}
        value={state.id}
        selected={state.id === value.id}
        onClick={(e) => {
          if (hasRoomMenu) {
            handleInRoomClick(e)
          } else {
            handleClose()
            if (state.id !== value.id) {
              onChangeState(state)
            }
          }
        }}
        sx={{ px: 1 }}
        data-testid={`${dataTestId}-menuItem-${index}`}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexGrow: 1,
            gap: 1
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
            <ColorBox color={state.color} />
            <Typography>{state.name}</Typography>
          </Box>
          {hasRoomMenu && (
            <ExpandRightIcon sx={{ my: '-4px' }} />
          )}
        </Box>
      </MenuItem>
    )
  }

  const AppointmentRoomMenu = () => {
    return (
      <Menu
        data-testid={`${dataTestId}-room-menu`}
        open={openRooms}
        anchorEl={roomAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: -8,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          'aria-labelledby': 'customized-button',
          disablePadding: true,
          sx: {
            py: 1,
            width: "234px"
          }
        }}
        sx={{
          "& .Mui-selected": {
            backgroundColor: `${theme.palette.backgrounds.selected} !important`
          },
          "& .Mui-selected:hover": {
            backgroundColor: `${theme.palette.backgrounds.selectedHover} !important`
          },
        }}
      >
        {rooms.map((r, index) => (
          <MenuItem
            key={r.id}
            value={r.id}
            selected={r.id === room?.id}
            disabled={!!r.appointment}
            onClick={() => {
              handleClose()
              if (r.id !== room?.id) {
                onChangeRoom(r)
              }
            }}
            sx={{ px: 1 }}
            data-testid={`${dataTestId}-room-menuItem-${index}`}
          >
            {r.name}
          </MenuItem>
        ))}
      </Menu>
    )
  }

  return (
    <>
      <Button
        dataTestId={dataTestId}
        name={name}
        aria-controls={open ? 'customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant={"text"}
        disabled={disabled}
        onClick={handleClick}
        sx={{
          color: "inherit",
          fontWeight: 400,
          ml: -1,
          pl: 1,
          pr: 0
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", textAlign: "left", gap: "0 4px", flexWrap: "wrap" }}>
          {value.category === AppointmentStateCategory.INPROGRESS
            ? <Typography variant={"h5"} sx={{ flexGrow: 0, flexShrink: 0 }}>{value.name}{room ? ` ${room.name}` : ""}</Typography>
            : <Typography variant={"p2"} sx={{ flexGrow: 0, flexShrink: 0 }}>{value.name}</Typography>
          }
          {startTime && isSameDay && <AppointmentStateTimer startTime={startTime} />}
        </Box>
        <ExpandMore sx={{ visibility: "hidden" }} className={"md-custom-event-button"} data-testid={`${dataTestId}-expandMore`}/>
      </Button>
      <Menu
        data-testid={`${dataTestId}-state-menu`}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        MenuListProps={{
          'aria-labelledby': 'customized-button',
          disablePadding: true,
          sx: {
            py: 1,
            width: "234px"
          }
        }}
        sx={{
          "& .Mui-selected": {
            backgroundColor: `${theme.palette.backgrounds.selected} !important`
          },
          "& .Mui-selected:hover": {
            backgroundColor: `${theme.palette.backgrounds.selectedHover} !important`
          },
        }}
      >
        {options.map(AppointmentStateMenuItem)}
      </Menu>
      <AppointmentRoomMenu />
    </>
  )
}

const AppointmentStateTimer = ({ startTime }: { startTime: string }) => {
  const [timeNow, setTimeNow] = useState(moment())

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeNow(moment())
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  const duration = moment.duration(diffDates(startTime, timeNow))
  const hours = Math.floor(duration.hours()).toLocaleString('en-US', { minimumIntegerDigits: 2 })
  const minutes = Math.floor(duration.minutes()).toLocaleString('en-US', { minimumIntegerDigits: 2 })
  const seconds = Math.floor(duration.seconds()).toLocaleString('en-US', { minimumIntegerDigits: 2 })

  return <Typography variant={"p2"} sx={{ flexGrow: 0, flexShrink: 0, width: "62px" }}>({hours}:{minutes}:{seconds})</Typography>
}