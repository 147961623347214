import { Box } from '@mui/material'
import { FilterSelect, LoadingButton, Section, IconButton } from 'saga-library/src'
import React from 'react'
import {
  LabAndInvestigationLabel,
  LabAndInvestigationPatientLabel
} from '../../../patients/components/labAndInvestigations/components/LabAndInvestigationLabel'
import AddTask from '@mui/icons-material/AddTask'
import {useLabAndInvestigationStateContext} from "../../../../providers/LabAndInvestigationStateProvider";
import {
  LabAndInvestigationFileNavigation
} from "../../../patients/components/labAndInvestigations/components/LabAndInvestigationFileNavigation";
import HistoryIcon from "@mui/icons-material/History"
import { useReviewLabsContext } from '../../providers/ReviewLabsProvider'

interface LabsHeaderProps {
  dataTestId: string,
}

export const LabsHeader = ({
  dataTestId
}: LabsHeaderProps) => {
  const { labFilters, setLabFilters } = useReviewLabsContext()

  const filterLabsOptions = [
    { label: 'Labs and investigations', value: '*' },
    { label: 'Labs', value: 'labs' },
    { label: 'Investigations', value: 'investigations' }
  ]

  const filterLabsReviewOptions = [
    { label: 'Unreviewed', value: 'unreviewed' },
    { label: 'Reviewed', value: 'reviewed' }
  ]

  const filterLabsNormalOptions = [
    { label: 'Normal and abnormal', value: '*' },
    { label: 'Normal', value: 'normal' },
    { label: 'Abnormal', value: 'abnormal' }
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 1
      }}
    >
      <FilterSelect
        options={filterLabsOptions}
        onSelect={(event) => setLabFilters({ labsAndInvestigations: event.value })}
        value={labFilters.labsAndInvestigations}
        dataTestId={`${dataTestId}-list-labs-filter`}
      />
      <FilterSelect
        options={filterLabsReviewOptions}
        onSelect={(event) => setLabFilters({ unreviewed: event.value })}
        value={labFilters.unreviewed}
        dataTestId={`${dataTestId}-list-labs-review-filter`}
      />
      <FilterSelect
        options={filterLabsNormalOptions}
        onSelect={(event) => setLabFilters({ normalAndAbnormal: event.value })}
        value={labFilters.normalAndAbnormal}
        dataTestId={`${dataTestId}-list-labs-normal-filter`}
      />
    </Box>
  )
}

interface LabResultHeaderProps {
  formName: string,
  onMarkAsReviewedClicked: () => void,
  onMarkAsUnreviewedClicked: () => void,
  loading: boolean,
  dataTestId: string,
  isReviewed?: boolean | undefined,
  setTaskModalOpen: (open: boolean) => void,
  setHistoryOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const LabsResultHeader = ({
  formName,
  onMarkAsReviewedClicked,
  onMarkAsUnreviewedClicked,
  loading,
  dataTestId,
  isReviewed,
  setTaskModalOpen,
  setHistoryOpen
}: LabResultHeaderProps) => {
  const { selectedLabAndInvestigation, labAndInvestigation } = useLabAndInvestigationStateContext()

  const handleOnClick = () => {
    setTaskModalOpen(true)
  }

  const getHeaderButtons = () => {
    return (
      !isReviewed && !loading
      ? <LoadingButton
        form={formName}
        type={'button'}
        name={'markAsReviewedButton'}
        variant={'contained'}
        disabled={loading}
        loading={loading}
        onClick={onMarkAsReviewedClicked}
        data-testid={`${dataTestId}-mark-reviewed-button`}
      >
        MARK AS REVIEWED
      </LoadingButton>
      : <LoadingButton
        form={formName}
        type={'button'}
        name={'markAsUnreviewedButton'}
        variant={'contained'}
        disabled={loading}
        loading={loading}
        onClick={onMarkAsUnreviewedClicked}
        data-testid={`${dataTestId}-mark-unreviewed-button`}
      >
        MARK AS UNREVIEWED
      </LoadingButton>
    )
  }

  const headerButtons = () => {
    return (
      <>
        <LabAndInvestigationFileNavigation dataTestId={`${dataTestId}-fileNavigation`} />
        {
          labAndInvestigation?.hasHistory &&
          <IconButton
            dataTestId={`${dataTestId}-history-button`}
            icon={<HistoryIcon/>}
            onClick={() => setHistoryOpen(val => !val)}
          />
        }
        <IconButton
          type={'submit'}
          form={formName}
          color={'primary'}
          disabled={loading}
          onClick={handleOnClick}
          data-testid={'inbox-lab-create-task-modal-button'}
          icon={<AddTask />}
        />
        <LoadingButton
          form={formName}
          type={'submit'}
          name={'SaveButton'}
          variant={'outlined'}
          disabled={loading}
          loading={loading}
          data-testid={`${dataTestId}-save-button`}
        >
          SAVE
        </LoadingButton>
        {getHeaderButtons()}
      </>
    )
  }


  return (
    selectedLabAndInvestigation && (
      <>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
          <Section.Header
            dataTestId={`${dataTestId}-sectionLabel`}
            sx={{ mb: 0, flex: '1 1 100%' }}
          >
            <LabAndInvestigationLabel />
          </Section.Header>
          {headerButtons()}
        </Box>
        <LabAndInvestigationPatientLabel
          sx={{
            mt: 1,
            display: 'flex'
          }}
        />
      </>
    )
  )
}