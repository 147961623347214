import React, { useEffect, useState } from "react";
import { useTenantContext } from "../providers/TenantContextProvider"
import { Autocomplete } from "saga-library/src";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { LetterTemplateType } from "../types/patients/Letters";

type TemplateSelectType = {
  label: string
  name: string
  letterTemplateId: string
  letterTemplate?: LetterTemplateType | null,
  disabled?: boolean
  onChange: (newValue) => void
  autoFocus?: boolean
  inputRef: React.MutableRefObject<any>
  dataTestId: string
}

export const TemplateSelect = ({
  label,
  name,
  letterTemplateId = "",
  letterTemplate = null,
  disabled = false,
  onChange,
  autoFocus = false,
  inputRef,
  dataTestId = ""
}: TemplateSelectType) => {
  const { letterTemplates } = useTenantContext()

  const [letterTemplateOptions, setLetterTemplateOptions] = useState<LetterTemplateType[]>([])
  const [template, setTemplate] = useState<LetterTemplateType | null>(null)


  useEffect(() => {
    if (!!letterTemplate && letterTemplates.findIndex(lt => lt.id === letterTemplate?.id) === -1) {
      setLetterTemplateOptions(sortByNameDescending(letterTemplates.concat(letterTemplate)))
    } else {
      setLetterTemplateOptions(letterTemplates)
    }
  }, [letterTemplate, letterTemplates])

  useEffect(() => {
    if (!letterTemplateId) {
      setTemplate(null)
    } else {
      setTemplate(letterTemplateOptions.find(t => t.id === letterTemplateId) || null)
    }
  }, [letterTemplateId, letterTemplateOptions])

  return (
    <Autocomplete
      name={name}
      label={label}
      placeholder={"No template selected"}
      options={letterTemplateOptions}
      onChange={(e, newValue, reason) => {
        reason === 'clear' ? onChange('') : onChange(newValue)
      }}
      OptionComponent={TemplateSelectItem}
      getOptionLabel={(option) => option.name}
      getOptionDisabled={(option) => option.isDeleted}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      value={template}
      sx={{ flex: '1 1 100%' }}
      disabled={disabled}
      autoFocus={autoFocus}
      inputRef={inputRef}
      dataTestId={dataTestId}
    />
  )
}

const TemplateSelectItem = ({ data, props, dataTestId = "" }) => {
  const { name, practitioner } = data

  return (
    <Box
      component="li"
      sx={{
        display: "flex",
        alignItems: "center",
        padding: 1,
        flex: "1 1 auto"
      }}
      {...props}
      key={data.key}
      name={`letter_template_${data.id}`}
      role={'option'}
      data-testid={dataTestId}
    >
      <Box sx={{ displa: "flex", flexDirection: "column", justifyContent: "center", height: "48px" }}>
        <Box>{name}</Box>
        {!!practitioner && <Typography variant={"p2"}>{practitioner.firstName} {practitioner.lastName}</Typography>}
      </Box>
    </Box>
  )
}

export const sortByNameDescending = (letters: LetterTemplateType[] = []) => {
  return letters.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
}