import React, { useState } from 'react'
import { LoadingButton, Typography } from 'saga-library/src'
import { Box, Collapse } from '@mui/material'
import { useSagaAuthentication } from '../../providers/Auth'
import { useAccountContext } from '../../providers/AccountContext'

export const ChangePassword = ({dataTestId}) => {
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { performForgotPassword, friendlyErrorMessage } = useSagaAuthentication()
  const { userEmailAddress } = useAccountContext()

  const requestResetPasswordLink = () => {
    setIsSubmitting(true)
    return performForgotPassword({ email: userEmailAddress })
      .then((success) => {
        setIsSubmitting(false)
        if (success) setEmailSent(true)
      })
  }

  return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {emailSent ?
          <Typography variant={'body1'}>
            Please check your email inbox for the reset password link.
          </Typography>
          :
          <>
            <Typography variant={'body1'}>
              A reset password link will be sent to your email address.
            </Typography>
            <LoadingButton
              dataTestId={`${dataTestId}-button`}
              name={'change-password'}
              type={'button'}
              onClick={requestResetPasswordLink}
              sx={{ width: 200, mt:1}}
              loading={isSubmitting}
              variant={'outlined'}
            >
              Change Password
            </LoadingButton>
            <Collapse in={!!friendlyErrorMessage} sx={{ color: "#C41923" }}>
              {friendlyErrorMessage}
            </Collapse>
          </>
        }
      </Box>
  )
}
