import { BackButton, Typography } from "saga-library/src";
import Box from "@mui/material/Box";
import React from "react";

interface SettingsHeaderProps {
  title: string
  onBack?: () => void
  actions?: React.ReactNode
  children?: React.ReactNode[] | React.ReactNode
  dataTestId?: string
}

const SettingsHeader = ({title, onBack, actions, children, dataTestId}: SettingsHeaderProps) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', pb: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        {onBack && <BackButton dataTestId={`${dataTestId}-settingsHeader-back`} onClick={onBack} sx={{ mt: '-4px' }} />}
        <Typography variant={'h2'} sx={{ pr: 3 }} dataTestId={`${dataTestId}-settingsHeader-title`}>{title}</Typography>
        {children}
      </Box>
      <Box sx={{ display: 'inline-flex', gap: '4px' }}>
        {actions}
      </Box>
    </Box>
  )
}

export default SettingsHeader
