import { FormProvider, useForm } from "saga-library/src/components/Form";
import React, { useEffect, useState } from "react";
import { DialogV2, Form } from "saga-library/src";
import { usePrompt } from "../../../providers/NavigationPrompt";
import { processPatientData } from "../../../apps/patients/providers/PatientProfileProvider";
import { schema } from "../../../apps/patients/util/validation";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { useParams } from "react-router-dom";
import { ADD_NEW_PATIENT } from "../../../graphql-definitions";
import { useMutation } from "@apollo/client";
import _get from "lodash/get";
import { useTenantContext } from "../../../providers/TenantContextProvider";
import { PersonalDetails } from "../../../apps/patients/components/profile/components/PersonalDetails";
import { IdentifierDetails } from "../../../apps/patients/components/profile/components/IdentifierDetails";
import { ContactDetails } from "../../../apps/patients/components/profile/components/ContactDetails";
import { AddressDetails } from "../../../apps/patients/components/profile/components/AddressDetails";
import { AdministrationDetails } from "../../../apps/patients/components/profile/components/AdministrationDetails";
import { PatientDetailsColumn } from "../../../apps/patients/components/profile/components/PatientDetailsColumn";
import { PatientDetailsContainer } from "../../../apps/patients/components/profile/components/PatientDetailsContainer";
import { PatientProfileType } from "../../../apps/patients/PatientTypes";
import { PatientBillingDefaults, PatientBillingType } from "../../../apps/patients/components/profile/PatientBilling";
import { PatientNotesDefaults, PatientNotesType } from "../../../apps/patients/components/profile/PatientNotes";
import {
  PatientRelationshipDefaults,
  PatientRelationshipsType
} from "../../../apps/patients/components/profile/PatientRelationships";
import { PatientPharmacyDetails } from "../../../apps/patients/components/PharmacyDetails";
import { AddressType } from '../../Address'
import { PatientPractitionerDetails } from '../../../apps/patients/components/PractitionerDetails'
import { PersonalRelationshipDetails } from '../../../apps/patients/components/PersonalRelationshipDetails'
import { getUserErrorMessage } from '../../../utils/getUserErrorMessage'

const FORM_NAME = 'new_patient_form'

export type NewPatientDefaults = Partial<
  PatientProfileType &
  PatientBillingType &
  PatientNotesType &
  PatientRelationshipsType
>

const baseDefaults : NewPatientDefaults = {
  ...PersonalDetails.defaults,
  ...ContactDetails.defaults,
  ...AddressDetails.defaults,
  ...AdministrationDetails.defaults,
  ...IdentifierDetails.defaults,
  ...PatientRelationshipDefaults,
  ...PatientBillingDefaults,
  ...PatientNotesDefaults
}

interface PatientModalProps {
  open: boolean
  onModalClose: () => void
  setSelectedPatient?: (patientId: string) => void
  relationshipAddress?: AddressType
  getDefaults?: () => NewPatientDefaults
}

export const PatientModal = ({ open, onModalClose, setSelectedPatient, relationshipAddress, getDefaults }: PatientModalProps) => {
  const { enableNavigationPrompt } = usePrompt()
  const [ openNavigationPrompt, setOpenNavigationPrompt ] = useState<boolean>(false)
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const { tenant_id } = useParams()
  const { tenantIdentifiers } = useTenantContext()

  const defaults = {...baseDefaults, ...(getDefaults ? getDefaults() : [])}

  const formMethods = useForm<
    PatientProfileType &
    PatientBillingType &
    PatientNotesType &
    PatientRelationshipsType
  >({
    defaultValues: defaults,
    schema: schema,
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset
  } = formMethods

  const handleClose = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      reset(defaults)
      onModalClose()
    }
  }

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        reset(defaults)
        onModalClose()
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const [ addNewPatient ] = useMutation(ADD_NEW_PATIENT)

  const onSubmit = handleSubmit(async(data, event) => {
    if (event && event.target.name !== FORM_NAME) {
      return
    }

    const cleanedPatientData = processPatientData(data, tenantIdentifiers)

    await addNewPatient({
      variables: {
        tenantId: tenant_id,
        patientData: cleanedPatientData,
      },
      onCompleted: (data) => {
        const newPatientId = _get(data,'tenant.patient.createPatient', '')
        showSuccessAlert(`Patient has been saved.`)
        if (newPatientId && setSelectedPatient) {
          setSelectedPatient(newPatientId)
        }
        reset(defaults)
        onModalClose()
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert(getUserErrorMessage(error) || 'Patient couldn\'t be created.')
      }
    })
  })
  
  return (
    <DialogV2
      title={`New patient`}
      onClose={handleClose}
      size={relationshipAddress ? 'md' : 'lg'}
      primaryAction={onSubmit}
      formName={FORM_NAME}
      submitting={isSubmitting}
      open={open}
      dataTestId={"newPatient-dialog"}
    >
      <FormProvider {...formMethods}>
        <Form
          name={FORM_NAME}
          id={FORM_NAME}
          onSubmit={onSubmit}
        >
          <PatientDetailsContainer>
            <PatientDetailsColumn>
              <PersonalDetails.Form dataTestId={"patient-personalDetails"} autoFocus={false} minimal={!!relationshipAddress}/>
              <ContactDetails.Form dataTestId={"patient-contactDetails"}/>
            </PatientDetailsColumn>
            <PatientDetailsColumn>
              <AddressDetails.Form dataTestId={"patient-addressDetails"} relationshipAddress={relationshipAddress}/>
              { !relationshipAddress && <IdentifierDetails.Form dataTestId={'patient-identifierDetails'} /> }
            </PatientDetailsColumn>
            { !relationshipAddress &&
              <PatientDetailsColumn>
                <PatientPractitionerDetails.Form dataTestId={"patient-practitionerDetails"}/>
                <PatientPharmacyDetails.Form dataTestId={"patient-pharmacyDetails"}/>
                <PersonalRelationshipDetails.Form dataTestId={"patient-personalRelationshipDetails"}/>
              </PatientDetailsColumn>
            }
          </PatientDetailsContainer>
        </Form>
      </FormProvider>
    </DialogV2>
  )
}