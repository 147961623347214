import { TableListRowConfig, Typography } from 'saga-library/src'
import { Box } from '@mui/material'
import { useFormattedDate } from '../../../../hooks/FormattedDate'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import React from 'react'
import { ReviewLab } from '../../../../types/inbox/ReviewLab'
import { patientDisplayName } from 'saga-library/src/util'
import moment from 'moment-timezone'

export const LabsRow = (
  result: ReviewLab,
  index: number,
  rowClicked: (result: ReviewLab) => void,
  isRowClicked: (result: ReviewLab) => boolean,
  hasAbnormal: boolean,
  dataTestId: string
): TableListRowConfig => {
  const formattedDate = useFormattedDate(result.date)

  if(!result) return {rowData: [], key: ""}
  const abnormal = result?.abLabResult?.isAbnormal
  const description = result?.abLabResult?.description
  const patientName = patientDisplayName(result.abLabResult?.patientName?.firstName, result.abLabResult?.patientName?.lastName)

  const textColor = abnormal ? 'error.main' : (isRowClicked(result) ? 'primary.main' : 'greys.dark')

  const onRowClicked = () => {
    rowClicked(result)
  }


  return ({
    isRowClicked: isRowClicked(result),
    onClick: onRowClicked,
    key: result.id,
    rowData: [
      {
        data: moment(formattedDate).unix().toString(),
        children: (
          <Typography
            variant={'body2'}
            dataTestId={`${dataTestId}-date`}
            sx={{ color: textColor }}
          >
            {formattedDate}
          </Typography>
        )
      },
      {
        data: patientName,
        children: (
          <Typography
            variant={'body2'}
            dataTestId={`${dataTestId}-date`}
            sx={{ color: textColor }}
          >
            {patientName}
          </Typography>
        )
      },
      ...hasAbnormal ? [{
      children: (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            gap: 1,
            color: textColor
          }}
        >
          { abnormal && <WarningAmberIcon />}
        </Box>
      )}] : [],
      {
        children: (
          <Typography
            variant={'body2'}
            dataTestId={`${dataTestId}-description`}
            sx={{ color: textColor }}
          >
            {description}
          </Typography>
        )
      },
    ]
  })
}