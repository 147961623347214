import React from 'react'
import { Box, useTheme } from "@mui/material";
import { Typography } from "saga-library/src";

export const Tag = ({label, color, fontWeight=700}) => {
  const theme = useTheme()

  let opacity = 1
  let border = 'none'
  if (color === theme.palette.chart.greyTag) {
    opacity = 0.64
    border = `1px solid ${theme.palette.chart.greyTag}`
    color = '#FFF'
  }

  return <Box
        sx={ theme => ({
          borderRadius: '8px',
          width: 'fit-content',
          px: 1,
          backgroundColor: color,
          border: border,
          opacity: opacity,
          display:'flex',
          alignItems:'center',
          height: "16"
        })}
      >
        <Typography variant={'body2'} sx={{ fontWeight: fontWeight, color: theme.palette.getContrastText(color) }}> { label } </Typography>
      </Box>

}