import React, { useState } from "react";
import { Divider, List, ListItem, ListItemIcon, SxProps, Theme } from "@mui/material";
import Box from '@mui/material/Box'
import { Typography } from "../Typography";
import { Section } from "../Section/Section";
import { EmptyList } from "../EmptyList";
import { EmptyListConfig } from '../EmptyList/EmptyList'

interface SubsectionMenuProps {
  header?: React.ReactNode
  footer?: React.ReactNode
  rightPadding?: number
  sx?: SxProps<Theme>
  width?: string
  routes: React.ReactNode[]
  overflowY?: 'auto' | 'hidden',
  emptyListComponent?: EmptyListConfig
  dataTestId?: string
  elevation?: number
}

export const SubsectionMenu = ({ header, footer, rightPadding = 0, sx, width = '289px', routes, overflowY = 'hidden', emptyListComponent, dataTestId, elevation}: SubsectionMenuProps) => {

  const list = () => {
    if (emptyListComponent && routes.length === 0) {
      return <EmptyList
        message={emptyListComponent?.message || 'There are no items in this list'}
        size={emptyListComponent?.size}
        icon={emptyListComponent?.icon}
        sx={emptyListComponent?.sx}
        dataTestId={`${dataTestId}-subsectionMenu-emptyList`}
      />
    }
    return <List data-testid={`${dataTestId}-subsectionMenu-list`} sx={{ overflowY: overflowY, flex: '1 1 auto', pt: 0, pr: 1}}>
      {routes.map((route, index) => (
        <div key={index}>{route}</div>
      ))}
    </List>
  }
  return (
    <Section.Column
      preventScroll={true}
      rightPadding={rightPadding}
      sx={{
        width: width,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        p: 1,
        ...sx
      }}
      header={header}
      elevation={elevation}
      dataTestId={dataTestId}
    >
      {list()}
      { footer &&
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingRight: 1
          }}
          gap={1}
        >
          <Divider />
          {footer}
        </Box>
      }
    </Section.Column>
  )
}

interface SubsectionMenuItemProps {
  isActive: boolean
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  primaryText: string | React.ReactNode
  secondaryText?: string
  secondaryActions?: React.ReactNode
  onClick?: () => void
  dataTestId?: string
}

export const SubsectionMenuItem = ({ isActive, startIcon, endIcon, primaryText, secondaryText, secondaryActions, onClick, dataTestId }: SubsectionMenuItemProps) => {
  const [isMouseOver, setIsMouseOver] = useState(false)
  const fontColor = isActive ? 'primary.main' : 'greys.dark'

  const primaryLabel = () => {
    if(typeof primaryText === 'string') {
      return <Typography variant={'h5'} lineclamp={1} >{primaryText}</Typography>
    }

    return primaryText
  }

  return (
    <ListItem
      sx={{
        display: 'block',
        padding: 0,
      }}
      data-testid={dataTestId}
      secondaryAction={ isMouseOver && secondaryActions ?
        secondaryActions
        : null
      }
      onMouseEnter={ ()=> setIsMouseOver(true)}
      onMouseLeave={ ()=> setIsMouseOver(false)}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          my: '8px',
          padding: '8px',
          borderRadius: '8px',
          alignItems: 'center',
          color: fontColor,
          backgroundColor: isActive ? 'backgrounds.selected' : 'backgrounds.none',
          '&:hover': {
            backgroundColor: isActive ? 'backgrounds.selectedHover' : 'backgrounds.hover',
            cursor: 'pointer',
          },
        }}
        onClick={onClick}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          { startIcon && <SubsectionIcon dataTestId={`${dataTestId}-icon`} icon={startIcon} color={fontColor} />}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            { primaryLabel() }
            { secondaryText && <Typography variant={'p2'} lineclamp={1} sx={{color: fontColor}} >{secondaryText}</Typography> }
          </Box>
        </Box>
        { endIcon && <SubsectionIcon dataTestId={`${dataTestId}-icon`} icon={endIcon} color={'greys.medium'} marginRight={''} /> }
      </Box>
    </ListItem>
  )
}

export const SubsectionIcon = ({ dataTestId, icon, color, marginRight = '8px' }) => {
  return (
    <ListItemIcon
      data-testid={dataTestId}
      sx={{
        color: color,
        minWidth: '24px',
        marginRight: marginRight,
      }}
    >
      {icon}
    </ListItemIcon>
  )
}

export default SubsectionMenu
