import React from 'react'
import { Box } from '@mui/material'
import { Checkbox, TextField } from 'saga-library/src'
import { EncounterNoteFieldProps } from '../../../../../types/patients/EncounterNote'

export const EncounterNoteCheckboxWithTextField = ({
  name,
  label,
  isSigned,
  disabled,
  dataTestId,
  register
}: EncounterNoteFieldProps & { label: string }) => {
  const registeredField = register(name)
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: '12px' }}>
      <Box sx={{ flexShrink: 0, width: '288px' }}>
        <Checkbox
          label={label}
          name={`${registeredField.name}.selected`}
          disabled={isSigned || disabled}
          formControlLabelSx={{ ml: 0 }}
          dataTestId={`${dataTestId}-checkbox`}
        />
      </Box>
      <TextField
        label={''}
        name={`${registeredField.name}.text`}
        dataTestId={`${dataTestId}-text-area`}
        hideHelperText={true}
        fullWidth={true}
        disabled={disabled}
        slotProps={{
          input: {
            readOnly: isSigned
          }
        }}
        sx={{
          mt: 0
        }}
      />
    </Box>
  )
}