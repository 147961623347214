import React from 'react'
import { Box } from '@mui/material'
import { FilterSelect, Button } from 'saga-library/src'
import { useTaskContext } from '../../../../providers/TaskProvider'
import { TaskCategory, TaskState } from '../../../../types/tasks/TaskState'
import { TaskType } from '../../../../types/tasks/TaskType'
import { useReviewContext } from '../../providers/ReviewProvider'
import { useParams } from 'react-router-dom'

interface TaskHeaderProps {
  onNewTask: () => void
}

export const TaskListHeader = ({
  onNewTask
}: TaskHeaderProps) => {
  const { task_id } = useParams()
  const { taskTypes, taskStates } = useTaskContext()
  const { taskFilters, trySetTaskFilters } = useReviewContext()

  const typeOptions = [
    {label: "All types", value: "*"},
    ...taskTypes.map((type: TaskType) => {
      return {
        label: type.type,
        value: type.id
      }
    })
  ]

  const stateOptions = [
    {label: "Incomplete", value: TaskCategory.INCOMPLETE},
    ...(taskStates.filter(s=>s.active)).map((state: TaskState) => {
      return {
        label: state.name,
        value: state.id
      }
    })
  ]

  return (
    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
      <Box sx={{ flex: "1 1 100%", gap: 2, display: 'flex', flexDirection: 'row' }}>
        <FilterSelect
          dataTestId={'task-type-filter'}
          options={typeOptions}
          onSelect={(event) => trySetTaskFilters({ type: event.value }, task_id)}
          value={taskFilters.type}
        />
        <FilterSelect
          dataTestId={'task-state-filter'}
          options={stateOptions}
          onSelect={(event) => trySetTaskFilters({ state: event.value }, task_id)}
          value={taskFilters.state}
        />
      </Box>
      <Button dataTestId={"new_task_button"} name={"new"} onClick={onNewTask}> new </Button>
    </Box>
  )
}