import React, {useEffect, useState} from 'react'
import { Box, MenuItem } from '@mui/material'
import {
  ConditionAndProcedureIcon,
  AllergiesIcon,
  LabAndInvestigationsIcon,
  FormIcon, LetterIcon, PrescriptionIcon,
  OtherDocumentIcon,
  ChartNotesIcon
} from '../../../patients/components/ChartIcons'
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import NestedDropdown from './NestedDropdown'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { ConditionAndProcedureItem, ConditionAndProcedureList } from './components/ConditionAndProcedures'
import { AllergyItem, AllergyList } from './components/Allergies'
import { LabItem, LabList } from './components/Labs'
import { EncounterNoteItem, EncounterNoteList } from './components/EncounterNotes'
import { FormItem, FormList } from './components/Forms'
import { LetterItem, LetterList } from './components/Letter'
import { PrescriptionItem, PrescriptionList } from './components/Prescriptions'
import { OtherDocumentItem, OtherDocumentsList } from './components/OtherDocuments'
import { TaskItem, TaskList } from './components/Tasks'
import { LinkType } from '../../../../types/tasks/TaskAssignment'

const LinkItemSelect = ({name="linkedItems"}) => {
  const { control, setValue } = useFormContext()
  const patient = useWatch({
    control,
    name: 'patient'
  })

  useEffect(() => {
    if(!patient) {
      setValue(name, [])
    }
  }, [patient])

  if(!patient) {
    return null
  }

  return (
    <Controller
      name={name}
      control={control}
      render={ ({field: {value = [], onChange}})=> {
        const onSelect = (v) => {
          const newValue = {
            itemId: v.id,
            itemType: v.linkType,
            item: v
          }
          const updateValue = [...value, newValue]
          onChange(updateValue)
        }
        const linkItemTypes = [
          {
            label: 'Conditions and procedures',
            icon: <ConditionAndProcedureIcon />,
            items: [<ConditionAndProcedureList patientId={patient.id} onSelect={onSelect} />]
          },
          {
            label: 'Allergies',
            icon: <AllergiesIcon />,
            items: [<AllergyList patientId={patient.id} onSelect={onSelect} /> ]
          },
          {
            label: 'Labs and investigations',
            icon: <LabAndInvestigationsIcon />,
            items: [<LabList patientId={patient.id} onSelect={onSelect} />]
          },
          {
            label: 'Encounter notes',
            icon: <ChartNotesIcon />,
            items: [<EncounterNoteList patientId={patient.id} onSelect={onSelect} />]
          },
          {
            label: 'Forms',
            icon: <FormIcon />,
            items: [<FormList patientId={patient.id} onSelect={onSelect} />]
          },
          {
            label: 'Letters',
            icon: <LetterIcon/>,
            items: [<LetterList patientId={patient.id} onSelect={onSelect} />]
          },
          {
            label: 'Prescriptions',
            icon: <PrescriptionIcon />,
            items: [<PrescriptionList patientId={patient.id} onSelect={onSelect} />]
          },
          {
            label: 'Other documents',
            icon: <OtherDocumentIcon />,
            items: [<OtherDocumentsList patientId={patient.id} onSelect={onSelect} />]
          },
          {
            label: 'Task',
            icon: <TaskAltIcon />,
            items: [<TaskList patientId={patient.id} onSelect={onSelect} />]
          }
        ]

        return(
          <>
            <NestedDropdown
              menu={linkItemTypes}
              onSelect={onSelect}
            />
            {value.length > 0 &&
              <Box sx={{mt: 1, display: "flex", flexDirection: "column", gap: 1}}>
                {value.map( (item, index) => <ControlledLinkedItem
                  name={`${name}.${index}`}
                  item={item}
                  control={control}
                  key={`linked_item_${index}_${item.itemType}_${item.itemId}`}
                  removeAdded={ (removeItem) => setValue(name, value.filter(v => !(v.itemId === removeItem.itemId && v.itemType === removeItem.itemType)))}
                  />)
                }
              </Box>
            }
          </>
      )}}
  />
  )
}

const ControlledLinkedItem = ({name, item, control, removeAdded}) => {
  const listStyles = {
    pl:0
  }

  return <Controller
    name={name}
    control={control}
    render={ ({field: {value, onChange}})=> {
      const onDelete = () =>{
        if(value.id){
          onChange({...value, isDeleted: true})
        } else {
          removeAdded(value)
        }
      }

        switch(item.itemType){
          case(LinkType.CONDITION_PROCEDURE ):
            return <ConditionAndProcedureItem
              item={item.item}
              icon={<ConditionAndProcedureIcon />}
              sx={listStyles}
              onDelete={onDelete}
              onRestore={() => onChange({...value, isDeleted: false})}
              isDeleted={value.isDeleted}
            />
          case(LinkType.ALLERGY):
            return <AllergyItem
              item={item.item}
              icon={<AllergiesIcon />}
              sx={listStyles}
              onDelete={onDelete}
              onRestore={() => onChange({...value, isDeleted: false})}
              isDeleted={value.isDeleted}
            />
          case(LinkType.LAB_INVESTIGATION):
            return <LabItem
              item={item.item}
              icon={<LabAndInvestigationsIcon />}
              sx={listStyles}
              onDelete={onDelete}
              onRestore={() => onChange({...value, isDeleted: false})}
              isDeleted={value.isDeleted}
            />
          case(LinkType.ENCOUNTER_NOTE):
            return <EncounterNoteItem
              item={item.item}
              icon={<ChartNotesIcon />}
              sx={listStyles}
              onDelete={onDelete}
              onRestore={() => onChange({...value, isDeleted: false})}
              isDeleted={value.isDeleted}
            />
          case(LinkType.FORM):
            return <FormItem
              item={item.item}
              icon={<FormIcon/>}
              sx={listStyles}
              onDelete={onDelete}
              onRestore={() => onChange({...value, isDeleted: false})}
              isDeleted={value.isDeleted}
            />
          case(LinkType.LETTER):
            return <LetterItem
              item={item.item}
              icon={<LetterIcon/>}
              sx={listStyles}
              onDelete={onDelete}
              onRestore={() => onChange({...value, isDeleted: false})}
              isDeleted={value.isDeleted}
            />
          case(LinkType.PRESCRIPTION):
            return <PrescriptionItem
              item={item.item}
              icon={<PrescriptionIcon />}
              sx={listStyles}
              onDelete={onDelete}
              onRestore={() => onChange({...value, isDeleted: false})}
              isDeleted={value.isDeleted}
            />
          case(LinkType.OTHER_DOCUMENT):
            return <OtherDocumentItem
                item={item.item}
                icon={<OtherDocumentIcon />}
                sx={listStyles}
                onDelete={onDelete}
                onRestore={() => onChange({...value, isDeleted: false})}
                isDeleted={value.isDeleted}
            />
          case(LinkType.TASK):
            return <TaskItem
              item={item.item}
              icon={<TaskAltIcon />}
              sx={listStyles}
              onDelete={onDelete}
              onRestore={() => onChange({...value, isDeleted: false})}
              isDeleted={value.isDeleted}
            />
          default:
            return <MenuItem> Unknown linked item </MenuItem>
        }
    }}
  />
}

export default LinkItemSelect


