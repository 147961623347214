import React, { useEffect, useState } from "react";
import { Button, DialogV2, ImageUpload, LoadingButton, Section, Typography } from "saga-library/src";
import { FormFragment } from "../../../../components/PermissionForm";
import { Controller, useFormContext, useWatch } from "saga-library/src/components/Form";
import { Box } from "@mui/material";
import { useDocumentContext } from "../../../../providers/DocumentProvider";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { FileType, FileWithProgress } from "../../../../types/tasks";

const defaults = {
  signature: null,
}

export const SignaturePicker = ({disabled = false}) => {
  const { control } = useFormContext();
  const { getFileTypeUriWithToken } = useDocumentContext()
  const [ openUploadDialog, setOpenUploadDialog ] = useState(false);
  const [ fileDetails, setFileDetails ] = useState<string>("");

  async function getUriAndToken(selectedFile) {
    return await getFileTypeUriWithToken(selectedFile as FileType);
  }

  const signature = useWatch({
    control,
    name: 'signature',
  })

  useEffect(() => {
    if (signature) {
      getUriAndToken(signature)
        .then((fileDetails) => {
          if (fileDetails) {
            setFileDetails(fileDetails)
          }
        })
    }
  }, [signature]);

  const SignatureDisplay = () => {
    if (fileDetails) {
      return <img key={fileDetails} src={fileDetails} alt={"signature"}
                  style={{ width: "100%", height: "100%", objectFit: "contain" }} />
    }
    return <Typography variant={"body1"}>No signature</Typography>
  }

  const UploadSignatureButton = ({onClick, disabled = false}) => {
    return (
      <Button
        dataTestId={'import-signature-button'}
        variant={'outlined'}
        name={'import_signature_button'}
        onClick={onClick}
        sx={{mt:2}}
        disabled={disabled}
      >
        { signature ? "Change Signature" : "Upload Signature" }
      </Button>
    )
  }

  return (
    <Controller
      name={'signature'}
      control={control}
      render={({ field: { onChange } }) => (
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "132px",
              padding: "8px",
              gap: "8px",
              alignSelf: "stretch",
              borderRadius: "8px",
              border: "3px dashed",
              borderColor: "greys.light"
            }}
          >
            <SignatureDisplay />
          </Box>
          <UploadSignatureButton
            onClick={() => setOpenUploadDialog(true)}
            disabled={disabled}
          />
          <UploadSignatureDialog
            open={openUploadDialog}
            onModalClose={() => setOpenUploadDialog(false)}
            onChange={onChange}
          />
        </Box>
      )}
    />
  )
}

const UploadSignatureDialog = ({ open, onModalClose, onChange }) => {
  const { uploadFiles } = useDocumentContext()
  const { showSuccessAlert, showWarningAlert, showErrorAlert } = useAlerts()
  const [ uploading, setUploading ] = useState<boolean>(false)
  const [ signatureFile, setSignatureFile ] = useState<FileWithProgress[]>([])

  const handleClose = () => {
    setSignatureFile([])
    onModalClose()
  }

  const handleSubmit = () => {
    if (signatureFile.length === 0) {
      showWarningAlert('Please select a file to upload.')
      return
    }

    setUploading(true)

    uploadFiles(signatureFile, false)
      .then((processedFiles) => {
        showSuccessAlert('Signature has been uploaded.')
        if (processedFiles) {
          onChange(processedFiles[0])
        }
      })
      .catch((error) => {
        showErrorAlert('Signature couldn\'t be uploaded.')
        console.error(error)
      })
      .finally(() => {
        handleClose()
        setUploading(false)
      })
  }

  const uploadButton = (
    <LoadingButton
      name={'upload_button'}
      onClick={handleSubmit}
      loading={uploading}
      variant={'contained'}
      dataTestId={'upload-button'}
    >
      {'Upload'}
    </LoadingButton>
  )

  return (
    <DialogV2
      size={'sm'}
      dataTestId={'upload-signature-dialog'}
      title={'Upload signature'}
      onClose={handleClose}
      primaryAction={handleSubmit}
      primaryLabel={'Upload'}
      overridePrimaryComponent={uploadButton}
      open={open}
    >
      <ImageUpload dataTestId={'signature-upload'} files={signatureFile} setFiles={setSignatureFile} uploading={uploading} />
    </DialogV2>
  )
}

const Form = ({requiredPermissionType}) => {
  return (
    <FormFragment requiredPermissionType={requiredPermissionType} readOnlyOverride={true}>
      <Section.FormSection>
        <Section.Header>Signature</Section.Header>
        <SignaturePicker />
      </Section.FormSection>
    </FormFragment>
  )
}

export type SignatureDetailsType = {
  signature?: FileType | null,
}

export const SignatureDetails = {
  Form: Form,
  defaults: defaults,
}
