import * as yup from 'yup'
import { isArray } from 'lodash'
import moment from 'moment-timezone'
import {LinkType} from "../../../../types/tasks/TaskAssignment";

export const rules = {
  name: { max: 64 }
}

export const schema = yup.object({
  name: yup.string().max(rules.name.max).nullable(),
  typeId: yup.string().required("Required"),
  assignedTo: yup.object().required("Required"),
  state: yup.object().required("Required"),
  priority: yup.string().nullable(),
  startDate: yup.date().typeError("Required").required("Required"),
  dueDate: yup.date().typeError("Required").required("Required")
    .test('due_date_is_greater',
      'Due date should be greater than start date',
      (dueDate, context) => {
        const { startDate } = context.parent
        if (!dueDate || !startDate) {
          return true
        }
        return moment(dueDate).isSameOrAfter(moment(startDate))
      }
    ),
  practitionerId: yup.string().nullable(),
  description: yup.string().nullable(),
  patientId: yup.string().transform((val) => val?.id).nullable(),
  comment: yup.string().transform(val => val===""?null:val).nullable(),
  linkedItems: yup.array().transform( val => {
    if(isArray(val) && val.length > 0) {
      return val.filter(li => !li.isDeleted).map(li => {
          return {
            id: li.id,
            itemId: li.itemId,
            itemType: li.item.isLinkedDocument ? LinkType.LINKED_DOCUMENT : (li.itemType as string).toUpperCase(),
          }
      })
    }
    return null
  }).nullable()
})