import React from "react";
import { Box } from "@mui/material";
import { Section } from "saga-library/src";
import { ClinicPractitioner, ReferralPractitioner } from "../../../types/settings";
import { PractitionerRelationshipType } from "../../../components/SearchControls/Practitioner/PractitionerBlock";
import { PractitionerArray } from "../../../components/SearchControls/Practitioner/PractitionerArray";

export type PatientPractitionerDetailsType = {
  referralPractitioners?: ReferralPractitioner[]
  referralPractitionerLocationIds?: string[]
  clinicPractitioners?: ClinicPractitioner[]
  clinicPractitionerIds?: string[]
  familyPractitioners?: ReferralPractitioner[]
  familyPractitionerIds?: string[]
}

const defaults: PatientPractitionerDetailsType = {
  referralPractitioners: [],
  referralPractitionerLocationIds: [],
  clinicPractitioners: [],
  clinicPractitionerIds: [],
  familyPractitioners: [],
  familyPractitionerIds: [],
}

const Form = (props) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      <Section.SubHeader>Practitioners</Section.SubHeader>
      <PractitionerArray
        dataTestId={"patient-clinicPractitioners"}
        name={'clinicPractitioners'}
        label={PractitionerRelationshipType.CLINIC}
      />
      <PractitionerArray
        dataTestId={"patient-referralPractitioners"}
        name={'referralPractitioners'}
        label={PractitionerRelationshipType.REFERRING}
      />
      <PractitionerArray
        dataTestId={"patient-familyPractitioner"}
        name={'familyPractitioners'}
        label={PractitionerRelationshipType.FAMILY}
        allowMultiple={false}
      />
    </Box>
  )
}

export const PatientPractitionerDetails = {
  Form: Form,
  defaults: defaults,
}
