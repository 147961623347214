import React, { useState, useMemo } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import { Section } from 'saga-library/src'
import Logo from '../../components/Logo'
import _get from 'lodash/get'
import { b64DecodeUnicode } from 'utils/CryptUtil'
import { ValidateInviteForm } from 'apps/invitations/ValidateInviteForm'
import { AcceptInviteForm } from 'apps/invitations/AcceptInviteForm'
import { InvitationAccepted } from 'apps/invitations/InvitationAccepted'
import { SignUpInviteForm } from 'apps/invitations/SignUpInviteForm'
import { InviteValidationStep } from '../../types/invitations/Invitation'

export const InvitationValidation = () => {
  const [currentStep, setCurrentStep] = useState<InviteValidationStep>(InviteValidationStep.Validate)
  const [searchParams] = useSearchParams()
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [userId, setUserId] = useState<string>("")
  const [tenantName, setTenantName] = useState<string>("")
  const [passcode, setPasscode] = useState<string>("")
  const [emailAddress, setEmailAddress] = useState<string>("")
  const { invite_key } = useParams()
  const [hash, salt] = invite_key!.split('~')

  const questionParam = searchParams.get('q') || ""
  const question = useMemo((): string => {
    let decoded = "";
    if (questionParam) {
      try {
        decoded = b64DecodeUnicode(questionParam)
      }
      catch (ex) {
        console.warn(ex)
      }
    }
    return decoded
  }, [questionParam])

  function currentStepIs(step: InviteValidationStep) {
    return step === currentStep;
  }

  function validateCompleted(success: boolean, data: string) {
    if (success) {
      let dataUserId = _get(data, 'public.invitation.validateInvitation.userId');

      setUserId(dataUserId || "")
      setTenantName(_get(data, 'public.invitation.validateInvitation.tenantName') || "")
      setEmailAddress(_get(data, 'public.invitation.validateInvitation.emailAddress') || "")
      setPasscode(_get(data, 'passcode') || "")

      if (!!dataUserId) {
        setCurrentStep(InviteValidationStep.Accept)
      }
      else {
        setCurrentStep(InviteValidationStep.SignUp)
      }
    }
  }

  function acceptCompleted(success: boolean) {
    if (success) setCurrentStep(InviteValidationStep.Complete)
  }

  function signUpCompleted(success: boolean) {
    if (success) setCurrentStep(InviteValidationStep.Complete)
  }

  const validateInviteFormProps = {
    hash: hash,
    salt: salt,
    question: question,
    onSubmit: validateCompleted,
    setErrorMessage: setErrorMessage
  }

  const acceptInviteFormProps = {
    hash: hash,
    salt: salt,
    passcode: passcode,
    userId: userId,
    tenantName: tenantName,
    onSubmit: acceptCompleted,
    setErrorMessage: setErrorMessage
  }

  const signUpInviteFormProps = {
    hash: hash,
    salt: salt,
    passcode: passcode,
    userId: userId,
    tenantName: tenantName,
    emailAddress: emailAddress,
    onSubmit: signUpCompleted,
    setErrorMessage: setErrorMessage
  }

  return (
    <Box sx={{ width: '100%', textAlign: 'center' }}>
      <Box sx={{ mt: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Section.Column>
          <Box sx={{ display: 'flex', flexDirection: 'column', my: 2, justifyContent: 'center', alignItems: 'center' }}>
            <Logo sx={{ width: 180, height: 70 }} />

            {currentStepIs(InviteValidationStep.Validate) && <ValidateInviteForm {...validateInviteFormProps} />}

            {currentStepIs(InviteValidationStep.Accept) && <AcceptInviteForm {...acceptInviteFormProps} />}

            {currentStepIs(InviteValidationStep.SignUp) && <SignUpInviteForm {...signUpInviteFormProps} />}

            {currentStepIs(InviteValidationStep.Complete) && <InvitationAccepted />}

            <Collapse in={!!errorMessage} sx={{ marginTop: '16px', color: "#C41923" }}>
              {errorMessage}
            </Collapse>

          </Box>
        </Section.Column>
      </Box>
    </Box>
  )
}


