import React from 'react'
import { ErrorBoundary, Section, Typography } from 'saga-library/src'
import { Box } from '@mui/material'
import { DashboardCard } from '../DashboardCard'
import { UrgentIcon, HighIcon, MediumIcon, LowIcon } from '../../../patients/components/ChartIcons'
import { useQuery } from '@apollo/client'
import { GET_REVIEW_DASHBOARD_COUNTS } from '../../../../graphql-definitions'
import { useNavigate, useParams } from 'react-router-dom'
import _get from 'lodash/get'
import { LoadingSpinner } from '../../../../components/LoadingScreen'
import { useAccountContext } from '../../../../providers/AccountContext'

type InboxDashboardPriorityCounts = {
  total: number,
  overdue: number
}

type InboxDashboardCounts = {
  tasks: {
    urgent: InboxDashboardPriorityCounts,
    high: InboxDashboardPriorityCounts,
    medium: InboxDashboardPriorityCounts,
    low: InboxDashboardPriorityCounts
  },
  labs: {
    unreviewed: number,
    abnormal: number
  },
  investigations: number,
  documents: number
}

const greyscaleIconSx = (count: number) => {
  if (count === 0) {
    return {
      color: 'greys.medium',
      '& path': {
        fill: 'currentColor'
      }
    }
  }
  return {}
}

const OverdueMessage = ({ count }: { count: number }) => (
  <Typography variant={'p2'} sx={{ color: 'error.main', minHeight: '16px' }}>{count > 0 ? `${count} overdue` : ''}</Typography>
)

export const InboxDashboard = () => {
  return (
    <Section.Column rightPadding={1} height={'fit-content'} dataTestId={'inboxDashboard-column'}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Section.SubHeader variant={'h3'} sx={{ mb: 1 }}>Inbox</Section.SubHeader>
        <ErrorBoundary fallback={<Box sx={{ color: 'error.main' }}>Failed to load</Box>}>
          <InboxDashboardContent />
        </ErrorBoundary>
      </Box>
    </Section.Column>
  )
}

const InboxDashboardContent = () => {
  const { buildTenantRoute, userId } = useAccountContext()
  const { tenant_id} = useParams()
  const navigate = useNavigate()

  const { loading, data } = useQuery(GET_REVIEW_DASHBOARD_COUNTS, {
    variables: { tenantId: tenant_id },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
    fetchPolicy: 'cache-and-network'
  })

  const inboxCounts: InboxDashboardCounts = _get(data, 'tenant.dashboard.inboxCounts', {})

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Section.SubHeader sx={{ mb: '4px' }}>Incomplete tasks</Section.SubHeader>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          <DashboardCard
            label={inboxCounts.tasks.urgent.total}
            description={'Urgent'}
            icon={<UrgentIcon sx={{ fontSize: '36px', ...greyscaleIconSx(inboxCounts.tasks.urgent.total) }} />}
            onClick={() => navigate(buildTenantRoute(`inbox/u/${userId}/tasks?sortBy=priority&order=asc`, tenant_id))}
            dataTestId={'incompleteTasks-urgent'}
          >
            <OverdueMessage count={inboxCounts.tasks.urgent.overdue} />
          </DashboardCard>
          <DashboardCard
            label={inboxCounts.tasks.high.total}
            description={'High'}
            icon={<HighIcon sx={{ fontSize: '36px', ...greyscaleIconSx(inboxCounts.tasks.high.total) }} />}
            onClick={() => navigate(buildTenantRoute(`inbox/u/${userId}/tasks?sortBy=priority&order=asc`, tenant_id))}
            dataTestId={'incompleteTasks-high'}
          >
            <OverdueMessage count={inboxCounts.tasks.high.overdue} />
          </DashboardCard>
          <DashboardCard
            label={inboxCounts.tasks.medium.total}
            description={'Medium'}
            icon={<MediumIcon sx={{ fontSize: '36px', ...greyscaleIconSx(inboxCounts.tasks.medium.total) }}/>}
            onClick={() => navigate(buildTenantRoute(`inbox/u/${userId}/tasks?sortBy=priority&order=asc`, tenant_id))}
            dataTestId={'incompleteTasks-medium'}
          >
            <OverdueMessage count={inboxCounts.tasks.medium.overdue} />
          </DashboardCard>
          <DashboardCard
            label={inboxCounts.tasks.low.total}
            description={'Low'}
            icon={<LowIcon sx={{ fontSize: '36px', ...greyscaleIconSx(inboxCounts.tasks.low.total) }} />}
            onClick={() => navigate(buildTenantRoute(`inbox/u/${userId}/tasks?sortBy=priority&order=desc`, tenant_id))}
            dataTestId={'incompleteTasks-low'}
          >
            <OverdueMessage count={inboxCounts.tasks.low.overdue} />
          </DashboardCard>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Section.SubHeader sx={{ mb: '4px' }}>Labs</Section.SubHeader>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
            <DashboardCard
              label={inboxCounts.labs.unreviewed}
              description={'Unreviewed'}
              onClick={() => navigate(buildTenantRoute(`inbox/u/${userId}/labs?category=labs`, tenant_id))}
              dataTestId={'labs-unreviewed'}
            />
            <DashboardCard
              label={inboxCounts.labs.abnormal}
              labelColor={inboxCounts.labs.abnormal > 0 ? 'error.main' : ''}
              description={'Abnormal'}
              descriptionColor={inboxCounts.labs.abnormal > 0 ? 'error.main' : ''}
              onClick={() => navigate(buildTenantRoute(`inbox/u/${userId}/labs?category=labs&state=abnormal`, tenant_id))}
              dataTestId={'labs-abnormal'}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Section.SubHeader sx={{ mb: '4px' }}>Investigations</Section.SubHeader>
            <DashboardCard
              label={inboxCounts.investigations}
              description={'Unreviewed'}
              onClick={() => navigate(buildTenantRoute(`inbox/u/${userId}/labs?category=investigations`, tenant_id))}
              dataTestId={'investigations-unreviewed'}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Section.SubHeader sx={{ mb: '4px' }}>Documents</Section.SubHeader>
            <DashboardCard
              label={inboxCounts.documents}
              description={'Unreviewed'}
              onClick={() => navigate(buildTenantRoute(`inbox/u/${userId}/documents`, tenant_id))}
              dataTestId={'documents-unreviewed'}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}