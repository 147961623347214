import React, { useEffect, useState } from "react";
import { Box, Divider, SxProps, Theme } from "@mui/material";
import {
  Button,
  DialogV2,
  EmptyList,
  FileUpload,
  Section,
  LoadingButton
} from "saga-library/src";
import { LoadingSpinner } from "../../../../components/LoadingScreen";
import { useImportedDocumentsStateContext } from "./ImportedDocumentsStateProvider";
import { UnlinkedDocumentList } from "./UnlinkedDocumentList";
import { FileUploadOutlined } from "@mui/icons-material";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { useDocumentContext } from "../../../../providers/DocumentProvider";

interface DocumentListSectionProps {
  sx?: SxProps<Theme>
}

export const DocumentListSection = ({sx} : DocumentListSectionProps) => {
  const [ openImportDocumentDialog, setOpenImportDocumentDialog ] = useState<boolean>(false)
  const [ listLoading, setListLoading ] = useState<boolean>(false)
  const { refreshUnlinkedDocuments, unlinkedDocuments, setSelectedFile } = useImportedDocumentsStateContext()
  const showEmptyListMessageText = 'Documents appear here once imported.'

  useEffect(() => {
    const fetchData = async () =>
    {
      setListLoading(true)
      await refreshUnlinkedDocuments()
      setListLoading(false)
    }

    fetchData()
      .catch(console.error);

  }, [refreshUnlinkedDocuments])

  useEffect(() => {
    if (unlinkedDocuments.length === 0) setSelectedFile(null)
  }, [unlinkedDocuments.length, setSelectedFile])

  const onImportDocumentsClick = () => {
    setOpenImportDocumentDialog(true)
  }

  const onImportDocumentsDialogClose = () => {
    setOpenImportDocumentDialog(false)
  }

  const documentListHeader = (
    <>
      <Section.SubHeader variant={"h3"} sx={{ py: 1, mb: 1 }}>Import documents</Section.SubHeader>
      <Divider />
    </>
  )

  const list = () => {
    if (listLoading) {
      return <LoadingSpinner sx={{ flexGrow: 'inherit' }} size={"md"} />
    }
    if (unlinkedDocuments.length === 0) {
      return <EmptyList dataTestId={"unlinked-documents-empty-list"} size={'md'} sx={{mx:6}} message={showEmptyListMessageText} />
    }
    return <UnlinkedDocumentList dataTestId={'unlinked-documents-list'} items={unlinkedDocuments} />
  }

  return (
    <Section.Column
      preventScroll={true}
      sx={{
        height: 'auto',
        width: '289px',
        whiteSpace: 'nowrap',
        p: 1,
        flex: "0 0 auto",
        ...sx
      }}
      header={documentListHeader}
    >
      {list()}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingRight: 1,
        }}
      >
        <Divider
          sx={{
            mt: 0,
            mb: 1,
          }}
        />
        <ImportDocumentsButton onClick={onImportDocumentsClick} />
      </Box>
      <ImportDocumentsDialog open={openImportDocumentDialog} onModalClose={onImportDocumentsDialogClose} />
    </Section.Column>
  )
}

const ImportDocumentsButton = ({onClick}) => {
  return (
    <Button
      dataTestId={'import-document-button'}
      variant={'outlined'}
      name={'import_document_button'}
      onClick={onClick}
      sx={{pl:2, pr:2}}
      startIcon={<FileUploadOutlined/>}
    >
      Import Documents
    </Button>
  )
}

const ImportDocumentsDialog = ({ open, onModalClose }) => {
  const { uploadFiles } = useDocumentContext()
  const { showSuccessAlert, showErrorAlert } = useAlerts()
  const { pendingFiles, setPendingFiles, refreshUnlinkedDocuments } = useImportedDocumentsStateContext()
  const [ uploading, setUploading ] = useState<boolean>(false)

  const handleClose = () => {
    onModalClose()
    setPendingFiles([])
  }

  const handleSubmit = () => {
    if (pendingFiles.length === 0) return

    setUploading(true)

    uploadFiles(pendingFiles, true)
      .then(() => {
        showSuccessAlert('Documents have been imported.')
      })
      .catch((error) => {
        showErrorAlert('Documents couldn\'t be imported.')
        console.error(error)
      })
      .finally(() => {
        refreshUnlinkedDocuments()
        handleClose()
        setPendingFiles([])
        setUploading(false)
      })
  }

  const importButton = (
    <LoadingButton
      name={'import_button'}
      onClick={handleSubmit}
      loading={uploading}
      variant={'contained'}
      dataTestId={'import-button'}
    >
      {'Import'}
    </LoadingButton>
  )

  return (
    <DialogV2
      dataTestId={'import-documents-dialog'}
      title={'Import Documents'}
      onClose={handleClose}
      primaryAction={handleSubmit}
      primaryLabel={'import'}
      overridePrimaryComponent={importButton}
      open={open}
    >
      <FileUpload dataTestId={'file-upload'} files={pendingFiles} setFiles={setPendingFiles} uploading={uploading} />
    </DialogV2>
  )
}