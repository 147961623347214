import { Typography } from 'saga-library/src'
import React from 'react'
import { Box } from '@mui/material'
import {
  AbLabResult,
  AbLabResultObservationRequestPersonType,
  AbLabResultPersonBase
} from '../../../../../types/patients'
import { isCCLabResult } from '../LabAndInvestigationsUtil'
import { useFormattedDate } from '../../../../../hooks/FormattedDate'

export interface LabAndInvestigationFooterProps {
  lab: AbLabResult
}

export const LabAndInvestigationFooter = ({lab}:LabAndInvestigationFooterProps) => {
  const receivedOn = useFormattedDate(lab.abLabResultSpecimen?.receivedDate ?? lab.abLabResultObservationRequest?.specimenReceivedDate, true)
  const reportedOn = useFormattedDate(lab.abLabResultObservationRequest?.resultDate, true)

  return (
    <Box
      className={"lab-and-investigation-footer"}
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 3
      }}
    >
      <LabAndInvestigationFooterPeopleField label={"Ordered by"}                people={lab.abLabResultObservationRequest?.people?.find(person => person.type === AbLabResultObservationRequestPersonType.ORDERING_PROVIDER)} />
      <LabAndInvestigationFooterPeopleField label={"Clinicians copied (CC)"}    people={lab.abLabResultObservationRequest?.people?.filter(person => person.type === AbLabResultObservationRequestPersonType.RESULT_COPIES_TO)} />
      <LabAndInvestigationFooterField label={"Received on:"}  value={receivedOn}/>
      <LabAndInvestigationFooterField label={"Reported on:"}  value={reportedOn}/>
      <LabAndInvestigationFooterField       label={"Order number"}              value= {lab.abLabResultObservationRequest?.placerOrderId} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 3
        }}
      >
        <LabAndInvestigationFooterField       label={"Lab accession number"}      value= {isCCLabResult(lab) ? lab.abLabResultObservationRequest?.fillerOrderId : lab.abLabResultObservationRequest?.fillerField1} />
        <LabAndInvestigationFooterField       label={"Collected at"}              value= {lab.abLabResultObservationResults?.find(result => result.performingOrganizationName)?.performingOrganizationName} />
        <LabAndInvestigationFooterField       label={"Specimen source"}           value= {lab.abLabResultSpecimen?.source ?? lab.abLabResultObservationRequest?.specimenSource} />
        <LabAndInvestigationFooterField       label={"Specimen type"}             value= {lab.abLabResultSpecimen?.type} />
        <LabAndInvestigationFooterField       label={"Sending application"}       value= {lab.sendingApplication} />
        <LabAndInvestigationFooterField       label={"Sending facility"}          value= {lab.sendingFacility} />
      </Box>
    </Box>
  )
}

interface LabAndInvestigationFooterFieldProps {
  label?: string,
  value?: string | React.ReactNode
}

const LabAndInvestigationFooterField = ({label, value}:LabAndInvestigationFooterFieldProps) => {
  if (!label || !value) {
    return null
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <Typography variant={"body2"} sx={{color: 'greys.medium'}}>{label}</Typography>
      <Typography variant={"body1"}>{value}</Typography>
    </Box>
  )
}

interface LabAndInvestigationFooterPeopleFieldProps {
  label?: string,
  people?: AbLabResultPersonBase<any> | AbLabResultPersonBase<any>[]
}

const LabAndInvestigationFooterPeopleField = ({label, people}:LabAndInvestigationFooterPeopleFieldProps) => {
  if (!label || !people) {
    return null
  }

  if (!Array.isArray(people)) {
    people = [people]
  }

  people = people.filter(p => p.lastName || p.firstName)

  if (people.length === 0) {
    return null
  }

  const value = people!
    .map(person =>
      <Box component={"span"} key={`${typeof(person.type)}-${person.id}`}>
        {`${person.lastName ?? ""}${person.lastName && person.firstName ? ", " : ""}${person.firstName ?? ""}`}
      </Box>)
    ?.reduce((people, person) => {
      return (
        <>
          {people}
          <Box component={"span"} sx={{ display: "inline-block", p: "0 4px" }}>•</Box>
          {person}
        </>
      )
    } )

  return (
    <LabAndInvestigationFooterField
      label={label}
      value={value}
    />
  )
}