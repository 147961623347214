import React from 'react'
import { Switch, Typography } from 'saga-library/src'
import Box from '@mui/material/Box'
import { SettingsSubHeader } from "../AccountModal";

const NotificationsForm = ({dataTestId}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box>
        <SettingsSubHeader> Product News </SettingsSubHeader>
        <Typography variant={'body1'}>
          Get emails about new features and the latest updates to Saga
          Health
        </Typography>
      </Box>
      <Box sx={{ ml: 8 }}>
        <Switch name={'marketingEmails'} dataTestId={`${dataTestId}-marketingEmails`}/>
      </Box>
    </Box>
  )
}

export default NotificationsForm

export type NotificationFormValues = {
  marketingEmails?: boolean
}
