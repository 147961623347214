import React from 'react'
import { TextField, Typography, TextFieldMask } from "saga-library/src";
import { rules } from './ProfileValidations'
import { SettingsSubHeader } from "../AccountModal";
import { ChangePassword } from "../ChangePassword";
import { phoneNumberMask } from "../../settings/Locations/util/mask";


export const ProfileForm = ({dataTestId}) => {
  return (
    <>
      <Typography sx={{mb:1}}> Your Saga Health profile information is available to all clinics to which you have access. </Typography>
      <SettingsSubHeader> Personal </SettingsSubHeader>

      <TextField
        dataTestId={`${dataTestId}-lastName`}
        name={'lastName'}
        label={'Last name'}
        slotProps={{ htmlInput: { maxLength: rules.lastName.max } }}
        sx={{ maxWidth: 400 }}
      />

      <TextField
        dataTestId={`${dataTestId}-firstName`}
        name={'firstName'}
        label={'First name'}
        slotProps={{ htmlInput: { maxLength: rules.firstName.max } }}
        sx={{ maxWidth: 400 }}
      />

      <SettingsSubHeader> Contact </SettingsSubHeader>
      <TextFieldMask
        name={'contactPhone'}
        label={'Phone number'}
        formatMask={phoneNumberMask}
        maskChar={''}
        dataTestId={`${dataTestId}-phoneNumber`}
        sx={{ maxWidth: 400 }}
      />
      <SettingsSubHeader>Password</SettingsSubHeader>
      <ChangePassword dataTestId={`${dataTestId}-changePassword`}/>
    </>
  )
}

export type ProfileFormValues = {
  firstName?: string
  lastName?: string
  contactPhone?: string
  version?: string
}

export default ProfileForm
