import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { Button } from 'saga-library/src'
import Avatar from '@mui/material/Avatar'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { alpha } from '@mui/material'
import { styled } from "@mui/system";
import { User } from "../../../../types/account";

interface TenantRoleUsersProps {
  removeUser: (id: string | null) => void
  type?: 'add' | 'remove' | null | undefined
  tenantUser: User
  buttonLabel?: string
  disabled?: boolean
}

const AccountIcon = styled(AccountCircleIcon)(({ theme }) => ({
  color: theme.palette.greys.light,
  fontSize: 'xxx-large',
}))

const TenantRoleUserListItem = ({
  removeUser,
  tenantUser: { lastName, firstName, emailAddress, id },
  type,
  buttonLabel = 'REMOVE',
  disabled=false
}: TenantRoleUsersProps) => {
  const [isMouseOver, setIsMouseOver] = useState(false)

  const typeStyles = (theme) => {
    const styles = {
      marginRight: '-2px',
      marginLeft: '-2px',
      marginTop: '8px',
      borderRadius: '8px',
      color: 'none',
      backgroundColor: 'none',
      fontWeight: 'none',
    }
    if(!disabled) {
      if (isMouseOver) {
        styles.backgroundColor = alpha(theme.palette.tertiary.main, 0.5)
      }
      if (type === 'add') {
        styles.color = alpha(theme.palette.primary.main, 1)
        styles.backgroundColor = isMouseOver
          ? alpha(theme.palette.primary.main, 0.25)
          : alpha(theme.palette.primary.main, 0.15)
      } else if (type === 'remove') {
        styles.color = alpha(theme.palette.error.main, 1)
        styles.backgroundColor = isMouseOver
          ? alpha(theme.palette.error.main, 0.25)
          : alpha(theme.palette.error.main, 0.15)
      }
    }
    return styles
  }

  return (
    <Box
      component={'li'}
      role={`roleUserListItem`}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        ...typeStyles(theme),
      })}
      data-testid={`roleUserListItem_${id}`}
    >
      <Box
        sx={{
          flex: '1 1 auto',
          display: 'flex',
          padding: 1.5,
          alignItems: 'center'
        }}
        key={`roleUser_${id}`}
      >
        <UserRoleListItemAvatar type={type}/>
        <Box>
          <Box> {`${lastName}, ${firstName}`} </Box>
          <Box
            sx={{
              fontWeight: 'light',
            }}
          >
            {emailAddress || 'n/a'}
          </Box>
        </Box>
      </Box>
      {isMouseOver && !disabled && (
        <Button
          name={`${buttonLabel?.toLowerCase()}_button`}
          onClick={() => removeUser(id)}
          variant={'text'}
        >
          {buttonLabel}
        </Button>
      )}
    </Box>
  )
}

interface UserRoleListItemAvatarProps {
  type: 'add' | 'remove' | null | undefined
}

const UserRoleListItemAvatar = ({
  type,
}: UserRoleListItemAvatarProps) => {
  if (type === 'add') {
    return (
      <Avatar
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          mr: 1,
        }}
      >
        <AddIcon />
      </Avatar>
    )
  }

  if (type === 'remove') {
    return (
      <Avatar
        sx={{
          backgroundColor: (theme) => theme.palette.error.main,
          mr: 1,
        }}
      >
        <RemoveIcon />
      </Avatar>
    )
  }

  return (
    <AccountIcon sx={{ mr: 1}} />
  )
}

export default TenantRoleUserListItem
