import React, { Suspense, useEffect } from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import { usePageTitleContext } from "../providers/PageTitleContextProvider";
import { useAccountContext } from '../providers/AccountContext'
import { ImportedDocuments } from '../apps/tasks/documents/ImportedDocuments'
import { InboxSection } from '../apps/inbox/Inbox'
import { EmptyTaskSelection, Tasks } from '../apps/inbox/Tasks'
import { EmptyLabSelection, Labs } from '../apps/inbox/Labs'
import { UpdateTask } from '../apps/inbox/components/Tasks/UpdateTask'
import { NewTask } from '../apps/inbox/components/Tasks/NewTask'
import { Documents, EmptyDocumentSelection } from '../apps/inbox/Documents'
import { DocumentResult } from '../apps/inbox/components/Documents/DocumentResult'
import { LabsResult } from '../apps/inbox/components/Labs/LabsResult'
import { Box } from '@mui/material'
import { LoadingSpinner } from '../components/LoadingScreen'

export const Inbox = () => {
  const { setAreaTitle } = usePageTitleContext()
  const { tenant_id } = useParams()
  const { userId, buildTenantRoute } = useAccountContext()

  useEffect(() => {
    setAreaTitle('Inbox')
  }, [setAreaTitle])

  const defaultPath = buildTenantRoute(`inbox/u/${userId}/tasks`, tenant_id)

  return (
    <Routes>
      <Route path="/" element={<InboxSection />}>
        <Route index element={<Navigate to={defaultPath} replace />} />
        <Route path={'u/:user_id/*'} element={<InboxSubRoutes />} />
        <Route path={'r/:role_id/*'} element={<InboxSubRoutes />} />
      </Route>
    </Routes>
  )
}

const InboxSubRoutes = () => {
  const { tenant_id } = useParams()
  const { userId, buildTenantRoute } = useAccountContext()
  const defaultPath = buildTenantRoute(`inbox/u/${userId}/tasks`, tenant_id)
  return (
    <Routes>
      <Route path={'tasks'} element={<Tasks />}>
        <Route index element={<EmptyTaskSelection />} />
        <Route path={'t/:task_id'} element={<UpdateTask />} />
        <Route path={'t/new'} element={<NewTask />} />
      </Route>
      <Route path={'labs'} element={<Labs />}>
        <Route index element={<EmptyLabSelection />} />
        <Route path={'l/:lab_id'} element={<LabsResult />} />
      </Route>
      <Route path={'documents'} element={<Documents />}>
        <Route index element={<EmptyDocumentSelection />} />
        <Route path={'d/:document_id'} element={<DocumentResult />} />
      </Route>
      <Route path={"import"} element={<ImportedDocuments />} />
      <Route path="*" element={<Navigate to={defaultPath} replace />} />
    </Routes>
  )
}