import React from 'react'
import { getLabAndInvestigationLabel } from '../LabAndInvestigationsUtil'
import { AbLabResult, AbLabResultPatientIdentifier } from '../../../../../types/patients'
import { Chip, Typography } from 'saga-library/src'
import { Box, SxProps, Theme, useTheme } from '@mui/material'
import { useFormattedDate } from '../../../../../hooks/FormattedDate'
import { patientDisplayName } from 'saga-library/src/util'
import _uniqWith from 'lodash/uniqWith'
import {useLabAndInvestigationStateContext} from "../../../../../providers/LabAndInvestigationStateProvider";

export interface LabAndInvestigationLabelProps {
  dataTestId?: string,
  sx?: SxProps<Theme>
}

export const LabAndInvestigationLabel = ({}:LabAndInvestigationLabelProps) => {
  const theme = useTheme()
  const { selectedLabAndInvestigation } = useLabAndInvestigationStateContext()
  const collectedOn = useFormattedDate(selectedLabAndInvestigation?.abLabResultSpecimen?.collectionDate, true)

  const resultWithEmbeddedFile = selectedLabAndInvestigation?.abLabResultObservationResults?.find(r => r.file)
  const label = selectedLabAndInvestigation ? getLabAndInvestigationLabel(selectedLabAndInvestigation) : ''

  const chipLabel = selectedLabAndInvestigation?.abLabResultObservationRequest?.resultStatus?.display
    ?? selectedLabAndInvestigation?.abLabResultTranscription?.documentAvailabilityStatus?.display
    ?? selectedLabAndInvestigation?.abLabResultTranscription?.documentCompletionStatus?.display

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <Typography variant={"inherit"}>{label}</Typography>
      {!resultWithEmbeddedFile &&
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            height: "20px"
          }}
        >
          {chipLabel?.toLowerCase() === "cancel" || chipLabel?.toLowerCase() === "cancelled" || chipLabel?.toLowerCase() === "deleted"
            ? <Chip
                data-testid={"lab-and-investigation-label-chip"}
                label={chipLabel}
                size={"small"}
                variant={"outlined"}
                sx={{
                  color: theme.palette.error.main,
                  borderColor: theme.palette.error.main
                }}
              />
            : <Chip
                data-testid={"lab-and-investigation-label-chip"}
                label={chipLabel}
                size={"small"}
              />
          }
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2
            }}
          >
            <LabAndInvestigationLabelField label={"Collected on:"} value={collectedOn}/>
          </Box>
        </Box>
      }
    </Box>
  )
}

export const LabAndInvestigationPatientLabel = ({
  dataTestId,
  sx,
}: LabAndInvestigationLabelProps) => {
  const { selectedLabAndInvestigation } = useLabAndInvestigationStateContext()

  const resultWithEmbeddedFile = selectedLabAndInvestigation?.abLabResultObservationResults?.find(r => r.file)
  const dob = useFormattedDate(selectedLabAndInvestigation?.dob)
  const uniqueIdentifiers : AbLabResultPatientIdentifier[] = _uniqWith(selectedLabAndInvestigation?.patientIdentifiers, (a, b) => a.label === b.label && a.assigningAuthority?.display === b.assigningAuthority?.display)

  if (resultWithEmbeddedFile) {
    return null
  }

  const patientFields = [
    <Typography variant={"body1"} key={"dob"}>{dob}</Typography>,
    <Typography variant={"body1"} key={"gender"}>{selectedLabAndInvestigation?.gender?.display}</Typography>,
    ...(uniqueIdentifiers.map(identifier =>
      <Typography variant={"body1"} key={`identifier-${identifier.id}`}>{identifier.assigningAuthority?.display}: {identifier.label}</Typography>
    ) ?? [])
  ]

  const patientName = patientDisplayName(selectedLabAndInvestigation?.patientName?.firstName ?? null, selectedLabAndInvestigation?.patientName?.lastName ?? null)

  return (
    <Box
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: 1,
        ...sx
      }}
      data-testid={dataTestId}
    >
      <Typography variant={"h3"}>{patientName}</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        {patientFields.reduce((fields, field) => {
          return (
            <>
              {fields}
              <Typography variant={"body1"}>&nbsp;•&nbsp;</Typography>
              {field}
            </>
          )
        })}
      </Box>
    </Box>
  )
}

interface LabAndInvestigationLabelFieldProps {
  label?: string,
  value?: string
}

const LabAndInvestigationLabelField = ({label, value}:LabAndInvestigationLabelFieldProps) => {
  if (!label || !value) {
    return null
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
      }}
    >
      <Typography variant={"body2"} sx={{color: 'greys.medium'}}>{label}&nbsp;</Typography>
      <Typography variant={"body2"}>{value}</Typography>
    </Box>
  )
}
