import React from 'react'
import { useParams } from 'react-router-dom'
import {QueryRef, useBackgroundQuery} from '@apollo/client'
import { LIST_ENCOUNTER_NOTE_TEMPLATES } from '../graphql-definitions'

interface EncounterNoteTemplateContextInterface {
  encounterNoteTemplatesRef: QueryRef | null
}

const defaultEncounterNoteTemplateContext: EncounterNoteTemplateContextInterface = {
  encounterNoteTemplatesRef: null
}

const EncounterNoteTemplateContext = React.createContext(defaultEncounterNoteTemplateContext)

export const EncounterNoteTemplateProvider = ({ children }) => {
  const { tenant_id} = useParams()

  const [ encounterNoteTemplatesRef ] = useBackgroundQuery(LIST_ENCOUNTER_NOTE_TEMPLATES, {
    variables: {
      tenantId: tenant_id
    },
    fetchPolicy: 'cache-first'
  })

  const providerValues = {
    encounterNoteTemplatesRef
  }

  if (!encounterNoteTemplatesRef) {
    return null
  }

  return (
    <EncounterNoteTemplateContext.Provider value={providerValues}>
      { children }
    </EncounterNoteTemplateContext.Provider>
  )
}

export const useEncounterNoteTemplateContext = () => {
  return React.useContext(EncounterNoteTemplateContext)
}