import {Section, useForm} from 'saga-library/src'
import React, { useEffect, useState } from 'react'
import { AbLabResultUpdate } from '../../../../types/patients'
import { LabAndInvestigationForm } from '../../../patients/components/labAndInvestigations/LabAndInvestigationForm'
import {FormProvider, useFormContext} from 'react-hook-form'
import { useMutation, useReadQuery } from '@apollo/client'
import _get from 'lodash/get'
import { LabsResultHeader } from './LabsHeader'
import { UPDATE_LAB_AND_INVESTIGATION, UPDATE_REVIEW_LAB } from '../../../../graphql-definitions'
import { useNavigate, useParams } from 'react-router-dom'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { Box } from '@mui/material'
import { useReviewDocumentStateContext } from '../../providers/ReviewDocumentStateProvider'
import { ReviewLab, ReviewLabInput } from '../../../../types/inbox/ReviewLab'
import { useUpdateCount } from '../../../../hooks/useUpdateCount'
import { useAccountContext } from '../../../../providers/AccountContext'
import { usePrompt } from '../../../../providers/NavigationPrompt'
import { schema } from '../../../patients/components/labAndInvestigations/LabAndInvestigationFormValidationSchema'
import { useReviewLabsContext } from '../../providers/ReviewLabsProvider'
import { NewTaskModal } from '../Tasks/NewTaskModal'
import { LinkType } from '../../../../types/tasks/TaskAssignment'
import {
  LabAndInvestigationStateProvider,
  useLabAndInvestigationStateContext
} from "../../../../providers/LabAndInvestigationStateProvider";
import {
  LabAndInvestigationHistory
} from "../../../patients/components/labAndInvestigations/components/LabAndInvestigationHistory";

const FORM_NAME = 'labs_and_investigations_inbox_form'
const dataTestId = 'inbox'

interface LabsResultFormProps {
  labQuery: any,
}

export const LabsResultForm = ({
  labQuery,
}: LabsResultFormProps) => {
  const { tenant_id, user_id, role_id } = useParams()
  const labData = useReadQuery(labQuery)
  const reviewLab = _get(labData, 'data.tenant.review.lab.get', null) as unknown as ReviewLab

  const [pendingNavigation, setPendingNavigation] = useState<ReviewLab | null>(null)
  const [loadingLabsForm, setLoadingLabsForm] = useState<boolean>(true)

  const { enableNavigationPrompt } = usePrompt()
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)

  const formMethods = useForm<AbLabResultUpdate>({
    schema: schema
  })

  const {
    formState: { dirtyFields },
  } = formMethods

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (!discard) {
      setOpenNavigationPrompt(false)
      return
    }
    if (pendingNavigation && discard) {
      setOpenNavigationPrompt(false)
      navigate(buildTenantRoute(`inbox/${role_id ? `r/${role_id}` : `u/${user_id}`}/labs/l/${pendingNavigation.id}`, tenant_id))
      setPendingNavigation(null)
    }
  }

  useEffect(() => {
    if (!labQuery) return
    setLoadingLabsForm(true)
  }, [labQuery])

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, (discard) => handleNavigationPromptDiscard(discard))
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length, openNavigationPrompt])

  return (
    <FormProvider {...formMethods}>
      <LabAndInvestigationStateProvider labAndInvestigation={reviewLab?.abLabResult}>
        <LabsResultFormContent
          reviewLab={reviewLab}
          loading={loadingLabsForm}
          setLoading={setLoadingLabsForm}
        />
      </LabAndInvestigationStateProvider>
    </FormProvider>
  )
}

interface LabsResultFormContentProps {
  reviewLab: ReviewLab,
  loading: boolean,
  setLoading: (loading: boolean) => void
}

const LabsResultFormContent = ({
  reviewLab,
  loading,
  setLoading
}:LabsResultFormContentProps) => {
  const { tenant_id, user_id, role_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const { updateLabCount } = useUpdateCount()
  const { selectNextRow } = useReviewLabsContext()
  const documentStateContext = useReviewDocumentStateContext()
  const { selectedLabAndInvestigation, setSelectedLabAndInvestigation, labAndInvestigation } = useLabAndInvestigationStateContext()
  const formMethods = useFormContext()
  const [submitFromTaskModal, setSubmitFromTaskModal] = useState<boolean>(false)
  const [taskModalOpen, setTaskModalOpen] = useState<boolean>(false)
  const [ historyOpen, setHistoryOpen ] = useState(false)

  const {
    watch,
    reset,
    formState: {
      isSubmitSuccessful,
      isSubmitted,
      isSubmitting,
      isLoading,
    },
  } = formMethods

  const labNotes = watch('notes')

  const [markAsUnreviewedMutation] = useMutation(UPDATE_REVIEW_LAB)
  const markAsUnreviewed = async () => {
    if (!reviewLab) return
    const reviewLabInput: ReviewLabInput = {
      id: reviewLab.id,
      userId: user_id,
      abLabResultVersion: reviewLab?.abLabResult?.version || '0',
      labNotes: labNotes,
      isReviewed: false,
      version: reviewLab?.version || '0'
    }

    reset({
      id: reviewLab?.id,
      notes: labNotes,
      version: reviewLab?.version
    })

    await markAsUnreviewedMutation({
      variables: {
        tenantId: tenant_id,
        input: reviewLabInput
      },
      onCompleted: (data) => {
        showSuccessAlert('Lab has been marked as unreviewed.')
        const reviewLab = _get(data, 'tenant.review.lab.update', null) as unknown as ReviewLab
        selectNextRow(reviewLab.id, user_id, true)
        updateLabCount(1)
      },
      onError: () => {
        showErrorAlert('Lab and investigation couldn\'t be saved')
      },
      update: (cache, data) => {
        const updatedLab = _get(data, 'data.tenant.review.lab.update') as unknown as ReviewLab
        if (!!updatedLab) {
          cache.modify({
            id: cache.identify(updatedLab),
            fields: {
              isReviewed: () => false
            }
          })
        }
      }
    })
  }

  const [markAsReviewedMutation] = useMutation(UPDATE_REVIEW_LAB)
  const markAsReviewed = async () => {
    if (!reviewLab) return
    const reviewLabInput: ReviewLabInput = {
      id: reviewLab.id,
      userId: user_id,
      abLabResultVersion: reviewLab?.abLabResult?.version || '0',
      labNotes: labNotes,
      isReviewed: true,
      version: reviewLab?.version || '0'
    }

    reset({
      id: reviewLab?.id,
      notes: labNotes,
      version: reviewLab?.version
    })
    await markAsReviewedMutation({
      variables: {
        tenantId: tenant_id,
        input: reviewLabInput
      },
      onCompleted: (data) => {
        showSuccessAlert('Lab has been marked as reviewed.')
        const reviewLab = _get(data, 'tenant.review.lab.update', null) as unknown as ReviewLab
        selectNextRow(reviewLab.id, user_id)
        updateLabCount(-1)
      },
      onError: () => {
        showErrorAlert('Lab and investigation couldn\'t be saved')
      },
      update: (cache, data) => {
        const updatedLab = _get(data, 'data.tenant.review.lab.update') as unknown as ReviewLab
        if (!!updatedLab) {
          cache.modify({
            id: cache.identify(updatedLab),
            fields: {
              isReviewed: () => true
            }
          })
        }
      }
    })
  }

  const [updateLabAndInvestigationMutation] = useMutation(UPDATE_LAB_AND_INVESTIGATION)
  const updateLabAndInvestigation = async (labAndInvestigationInput, onSuccess) => {
    const id = labAndInvestigationInput.id
    delete labAndInvestigationInput.id

    await updateLabAndInvestigationMutation({
      variables: {
        tenantId: tenant_id,
        id: id,
        input: labAndInvestigationInput
      },
      onCompleted: (data) => {
        showSuccessAlert('Lab has been saved.')
        if (onSuccess) {
          const labAndInvestigation = _get(data, 'tenant.patient.labAndInvestigation.update', null)
          onSuccess(labAndInvestigation)
          reset({
            id: labAndInvestigation.id,
            notes: labAndInvestigation.notes,
            version: labAndInvestigation.version
          })
        }
      },
      onError: () => {
        showErrorAlert('Lab and investigation couldn\'t be saved')
      }
    })
  }

  const saveAndMarkAsReviewed = async () => {
    await markAsReviewed().then(() => {
      showSuccessAlert('Task has been saved and lab has been marked as reviewed.')
    })
  }

  useEffect(() => {
    if (isSubmitted && submitFromTaskModal && isSubmitSuccessful) {
      setTaskModalOpen(true)
    }
  }, [submitFromTaskModal, isSubmitted, isSubmitSuccessful])

  useEffect(() => {
    if (!historyOpen && labAndInvestigation?.id && selectedLabAndInvestigation?.id !== labAndInvestigation?.id) {
      setSelectedLabAndInvestigation(labAndInvestigation.id)
    }
  }, [historyOpen])

  useEffect(() => {
    setHistoryOpen(false)
  }, [reviewLab])

  return (
    <Section.Column
      sx={{ pr: 1 }}
      width={'100%'}
      header={
        <LabsResultHeader
          loading={isLoading || isSubmitting || loading}
          formName={FORM_NAME}
          isReviewed={reviewLab?.isReviewed}
          setTaskModalOpen={setSubmitFromTaskModal}
          onMarkAsReviewedClicked={markAsReviewed}
          onMarkAsUnreviewedClicked={markAsUnreviewed}
          dataTestId={dataTestId}
          setHistoryOpen={setHistoryOpen}
        />
      }
    >
      <Box sx={{display:"flex", height:"100%"}}>
        <LabAndInvestigationForm
          formName={FORM_NAME}
          setLoading={setLoading}
          updateLabAndInvestigationMethod={updateLabAndInvestigation}
          dataTestId={`${dataTestId}-labAndInvestigation`}
          documentStateContext={documentStateContext}
        />
        {
          historyOpen && reviewLab?.abLabResult?.hasHistory &&
          <LabAndInvestigationHistory
            onClose={() => setHistoryOpen(false)}
            formName={FORM_NAME}
          />
        }
      </Box>
      <NewTaskModal
        open={taskModalOpen}
        setOpen={setTaskModalOpen}
        markAsReviewedMethod={saveAndMarkAsReviewed}
        patient={{
          id: reviewLab?.abLabResult?.patientId,
          firstName: reviewLab?.abLabResult?.patientName?.firstName,
          lastName: reviewLab?.abLabResult?.patientName?.lastName
        }}
        linkedItem={[{
          itemId: reviewLab?.abLabResult?.id,
          itemType: LinkType.LAB_INVESTIGATION,
          item: reviewLab?.abLabResult
        }]}
      />
    </Section.Column>
  )
}