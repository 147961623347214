import { Box, useTheme } from "@mui/material";
import { Typography, Button } from "saga-library/src";
import ReceiptLong from "@mui/icons-material/ReceiptLongOutlined";
import UpdateIcon from '@mui/icons-material/Update'
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import React, { useState } from 'react'
import { styled } from "@mui/system";
import { useNavigate } from 'react-router-dom'
import { BatchDeleteDialog } from "../BatchUpdate/components/BatchDeleteDialog";
import { useUnsubmittedClaimsContext } from '../../providers/UnsubmittedClaimsProvider'


const BillingsIcon = styled(ReceiptLong)(({ theme }) => ({
  color: theme.palette.greys.light,
  fontSize: '100px',
}))



export const withMultipleSelectedHOC = WrappedComponent => (props) => {
  const { selectedClaimIds } = useUnsubmittedClaimsContext()
  return(
    <>
      <Box sx={{ display: selectedClaimIds.length === 0 ? 'none' : undefined }}>
        <UnsubmittedMultipleSelected dataTestId={'unsubmittedMultipleSelected'} />
      </Box>
      <Box sx={{ display: selectedClaimIds.length > 0 ? 'none' : undefined }}>
        <WrappedComponent {...props} />
      </Box>
    </>
  )
}


const UnsubmittedMultipleSelected = ({ dataTestId }) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const { selectedClaimIds, setSelectedClaimIds, batchDeleteClaims, batchUnholdClaims, batchHoldClaims } = useUnsubmittedClaimsContext()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const handleUpdate = () => {
    navigate(`../c/update-batch`)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 6
      }}>
      <BillingsIcon />
      <Typography variant={'h2'}>{selectedClaimIds.length} {selectedClaimIds.length === 1 ? 'claim' : 'claims'} selected</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 4,
          color: theme.palette.primary.main,
        }}
      >
        <ActionComponent
          icon={<UpdateIcon/>}
          text={'UPDATE'}
          onClick={handleUpdate}
          dataTestId={`${dataTestId}-update`}
        />
        <ActionComponent
          icon={<PauseCircleOutlineIcon/>}
          text={'HOLD'}
          onClick={batchHoldClaims}
          dataTestId={`${dataTestId}-hold`}
        />
        <ActionComponent
          icon={<PlayCircleFilledWhiteOutlinedIcon/>}
          text={'UNHOLD'}
          onClick={batchUnholdClaims}
          dataTestId={`${dataTestId}-unhold`}
        />
        <ActionComponent
          icon={<DeleteOutlineOutlinedIcon/>}
          text={'DELETE'}
          onClick={() => setOpenDeleteModal(true)}
          dataTestId={`${dataTestId}-delete`}
        />
        <BatchDeleteDialog
          selectedClaimIds={selectedClaimIds}
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          onDelete={batchDeleteClaims}
        />
        <hr
          style={{
            alignItems: 'center',
            color: theme.palette.greys.light,
            width: '120px',
            marginTop: '32px',
          }}
        />

        <ActionComponent
          icon={<ClearOutlinedIcon/>}
          text={'CANCEL'}
          onClick={() => {setSelectedClaimIds([])}}
          dataTestId={`${dataTestId}-cancel`}
        />
      </Box>
    </Box>
  )
}

export const ActionComponent = ({icon, text, onClick, dataTestId}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        mt: '12px',
      }}
    >
      <Button name={'action-button'} variant={'text'} onClick={onClick} startIcon={icon} dataTestId={`${dataTestId}-button`}>
        {text}
      </Button>
    </Box>
  )
}