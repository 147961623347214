import Avatar from '@mui/material/Avatar'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { Button, Typography } from 'saga-library/src'
import { Box, ListItem } from '@mui/material'
import React from 'react'

export const DeletedListItem = ({onRestore, dataTestId}) => {
  return (
    <ListItem
      data-testid={`${dataTestId}_comment`}
      sx={theme => ({
        gap:1,
        alignItems:"center",
        borderRadius:"8px",
        border: `thick dashed ${theme.palette.greys.light}`,
        mb:1,
        p: "4px"
      })}
    >
      <Avatar>
        <DeleteOutlineOutlinedIcon />
      </Avatar>
      <Typography sx={{ color: "greys.light" }}> Deleted when task is saved. </Typography>
      <Box sx={{ display: "flex", justifyContent: "right", flex: "1 1 auto" }}>
        <Button
          name={"restore_item"}
          onClick={onRestore}
          variant="outlined"
          size={"small"}
          sx={{
            color: "greys.light",
            justifySelf :"right",
            borderColor: 'greys.light'
          }}
        >
          Restore
        </Button>
      </Box>
    </ListItem>
  )
}