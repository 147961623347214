import React, { useState } from "react";
import { Box, Collapse, Divider, SvgIcon } from '@mui/material'
import { ExpandButton, IconButton, Typography } from "saga-library/src";
import ScheduleIcon from "@mui/icons-material/CalendarMonthOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { PatientNotesDialog } from "./PatientNotesDialog";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ClearIcon from "@mui/icons-material/Clear";
import { BirthdayIcon, PractitionerIcon } from "../../../apps/patients/components/ChartIcons";
import { PatientSearchResult } from "../../../types/patients";
import { useFormContext } from "saga-library/src/components/Form";
import { useParams } from "react-router-dom";
import { useAccountContext } from "../../../providers/AccountContext";
import { formatNextAppointmentDate, patientDisplayName } from "saga-library/src/util";
import { IdentifierGroup } from "saga-library/src/components/SearchControl/blocks";
import { SxProps, Theme } from "@mui/material";
import { useFormattedDate } from '../../../hooks/FormattedDate'
import { NO_DATE_OF_BIRTH } from "../../FormattedAge";
import { postalCodeMask } from "saga-library/src/components/PostalCodeField/PostalCodeField";
import { phoneNumberMask } from "saga-library/src/components/PhoneField/PhoneField";
import { practitionerDisplayName } from "saga-library/src/util/formatting";
import { PHONE_TYPES } from "saga-library/src/components/Phone/PhoneType";
import { LoadingSpinner } from "../../LoadingScreen";
import { getAgeAndGenderString } from '../../../hooks/useFormattedAge'

interface PatientEndAdornmentsProps {
  patient: PatientSearchResult
  disabled?: boolean
  clearOnClick: () => void
  refresh?: () => void
  dataTestId?: string
  openInNewTab?: () => void
  displayNotes?: boolean
}

const PatientEndAdornments = ({
  patient,
  disabled = false,
  clearOnClick,
  refresh,
  dataTestId,
  openInNewTab,
  displayNotes = true
}: PatientEndAdornmentsProps ) => {
  const [notesOpen, setNotesOpen] = useState<boolean>(false)
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end'
      }}
    >
      {
        displayNotes && patient && patient.patientNotes?.length > 0 &&
        <>
          <IconButton
            dataTestId={`${dataTestId}-notes`}
            onClick={() => setNotesOpen(true)}
            icon={<DescriptionOutlinedIcon />}
          />
          <PatientNotesDialog
            patient={patient}
            open={notesOpen}
            setOpen={setNotesOpen}
          />
        </>
      }
      { refresh &&
        <IconButton
          dataTestId={`${dataTestId}-refresh`}
          onClick={refresh}
          icon={<AutorenewIcon />}
        />
      }
      { openInNewTab &&
        <IconButton
          dataTestId={`${dataTestId}-open`}
          onClick={openInNewTab}
          icon={<OpenInNewIcon />}
        />
      }
      { !disabled &&
        <IconButton
          dataTestId={`${dataTestId}-clear`}
          onClick={clearOnClick}
          icon={<ClearIcon />}
        />
      }
    </Box>
  )
}

const DetailedViewRow = ({ Icon, details = [], sx = {} }: { Icon: typeof SvgIcon, details: (string | { label: string, text: string })[], sx?: SxProps<Theme> }) => {
  if (details.length === 0) {
    return <></>
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '4px', ...sx }}>
      <Icon sx={{ color: (theme) => theme.palette.greys.medium, fontSize: '16px' }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
        {details.filter(detail => !!detail).map((detail, index) => {
          if (typeof detail === 'string') {
            return (
              <Typography key={index} variant={'p2'}>{detail}</Typography>
            )
          }
          return (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <Typography variant={'p3'}>{detail.label}</Typography>
              <Typography variant={'p2'}>{detail.text}</Typography>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

const DetailedView = ({ patient, isLoading }: { patient: any, isLoading?: boolean }) => {
  if (isLoading) {
    return <LoadingSpinner size={'sm'} label={null} />
  }

  const ageAndGenderString = getAgeAndGenderString(patient)
  return (
    <>
      <Divider />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, paddingY: 1 }}>
        {ageAndGenderString && (
          <DetailedViewRow
            Icon={BirthdayIcon}
            details={[ageAndGenderString]}
          />
        )}
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
          {(patient.street || patient.street2 || patient.street3 || patient.city || patient.postalCode) && (
            <DetailedViewRow
              Icon={HomeOutlinedIcon}
              details={[
                patient.street,
                patient.street2,
                patient.street3,
                `${patient.city ? `${patient.city}${patient.province ? ', ' : ''}`: ''}${patient.province || ''}`,
                postalCodeMask(patient.postalCode)
              ]}
            />
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {patient.phones && (
              <DetailedViewRow
                Icon={PhoneOutlinedIcon}
                details={patient.phones.map(phone => ({
                  label: PHONE_TYPES[phone.type],
                  text: phoneNumberMask(phone.number, phone.extension)
                }))}
              />
            )}
            {patient.primaryPractitioner && (
              <DetailedViewRow
                Icon={PractitionerIcon}
                details={[practitionerDisplayName(patient.primaryPractitioner.firstName, patient.primaryPractitioner.lastName)]}
              />
            )}
          </Box>
        </Box>
        <DetailedViewRow
          Icon={ScheduleIcon}
          details={[
            { label: 'Last', text: formatNextAppointmentDate(patient.lastAppointmentDate) },
            { label: 'Next', text: formatNextAppointmentDate(patient.nextAppointmentDate) }
          ]}
        />
      </Box>
    </>
  )
}

interface PatientLabelProps {
  patient: PatientSearchResult
  name: string
  disabled: boolean
  variant?: 'md' | 'sm'
  isLoadingDetails?: boolean
  defaultStyle?: 'collapsed' | 'expanded' | 'relationship'
  refresh?: () => void
  dataTestId?: string
  sx?: SxProps<Theme>,
  onClear?: ()=> void
}


interface PatientLabelSizingProps {
  title: 'body1' | 'p2'
  identifier: 'p2' | 'p3'
  flexDirection: 'row' | 'column'
  alignItems?: 'center' | "normal"
  gap: string
  height: string
}

export const PatientLabel = ({
  patient,
  name,
  disabled,
  variant = 'md',
  defaultStyle = 'collapsed',
  isLoadingDetails = false,
  refresh,
  dataTestId,
  sx = {},
  onClear
}: PatientLabelProps) => {
  const { setValue } = useFormContext()
  const { tenant_id } = useParams()
  const { buildTenantRoute } = useAccountContext()
  const [ expand, setExpand ] = useState<boolean>(defaultStyle === 'expanded')

  const formattedDob = useFormattedDate(patient.dob)

  const openPatientInNewTab = (patient) => {
    window.open(buildTenantRoute(
      `patients/p/${patient.id}`, tenant_id
    ))
  }

  let sizing: PatientLabelSizingProps = {
    title: 'body1',
    identifier: 'p2',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',
    height: '20px'
  }
  if (variant === 'sm') {
    sizing = {
      title: 'p2',
      identifier: 'p3',
      alignItems: 'normal',
      flexDirection: 'column',
      gap: '2px',
      height: '16px'
    }
  }

  if (defaultStyle === 'relationship') {
    return (
      <PatientLabelBox sx={sx} dataTestId={dataTestId}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box>
            <Typography variant={sizing.title} lineclamp={1} sx={{fontWeight:500, wordBreak: "break-all"}} >{patientDisplayName(patient.firstName, patient.lastName)}</Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: sizing.flexDirection,
                gap: sizing.gap,
                alignItems: sizing.alignItems
              }}
            >
              { patient.primaryPhoneNumber &&
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <PhoneOutlinedIcon sx={{ color: (theme) => theme.palette.greys.medium, fontSize: '16px' }} />
                  <Typography variant={'p3'}
                              sx={{ color: 'greys.dark' }}>{phoneNumberMask(patient.primaryPhoneNumber)}</Typography>
                </Box>
              }
              { patient.email &&
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <MailOutlineIcon sx={{ color: (theme) => theme.palette.greys.medium, fontSize: '16px' }} />
                  <Typography variant={'p3'} sx={{ color: 'greys.dark' }}>{patient.email}</Typography>
                </Box>
              }
            </Box>
          </Box>
        </Box>
        <PatientEndAdornments
          dataTestId={dataTestId}
          patient={patient}
          disabled={disabled}
          clearOnClick={() => setValue(name, null)}
          openInNewTab={() => openPatientInNewTab(patient)}
          displayNotes={false}
        />
      </PatientLabelBox>
    )
  }

  return (
    <PatientLabelBox
      sx={sx}
      dataTestId={dataTestId}
      detailView={(
        <Collapse in={expand}>
          <DetailedView patient={patient} isLoading={isLoadingDetails} />
        </Collapse>
      )}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ExpandButton expand={expand} setExpand={setExpand} dataTestId={dataTestId} sx={{ ml: -1 }} />
        <Box>
          <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 1 }}>
            <Typography variant={sizing.title} lineclamp={1} sx={{fontWeight:500, wordBreak: "break-all"}} >{patientDisplayName(patient.firstName, patient.lastName)}</Typography>
            <IconButton
              dataTestId={`${dataTestId}-open`}
              onClick={() => openPatientInNewTab(patient)}
              icon={<OpenInNewIcon fontSize={'inherit'} />}
              sx={{ fontSize: '16px', m: '-4px', p: '4px' }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: sizing.flexDirection,
              gap: sizing.gap,
              alignItems: sizing.alignItems
            }}
          >
            <Typography dataTestId={`${dataTestId}-identifier`} variant={sizing.identifier} sx={{ color: 'greys.dark' }} lineclamp={1}>{formattedDob || NO_DATE_OF_BIRTH}</Typography>
            <IdentifierGroup dataTestId={`${dataTestId}-id`} variant={sizing.identifier} height={sizing.height} label={patient.primaryIdentifierLabel || 'ID'} identifier={patient.primaryIdentifier || 'None'} />
          </Box>
        </Box>
      </Box>
      <PatientEndAdornments
        dataTestId={dataTestId}
        patient={patient}
        disabled={disabled}
        clearOnClick={() => onClear ? onClear() : setValue(name, null)}
        refresh={refresh}
      />
    </PatientLabelBox>
  )
}

interface PatientLabelBoxProps {
  children: React.ReactNode
  detailView?: React.ReactNode
  dataTestId?: string
  sx?: SxProps<Theme>
}

const PatientLabelBox = ({children, detailView, dataTestId, sx}: PatientLabelBoxProps) => {
  return (
    <Box
      sx={{
        padding: '0px 8px 0px 16px',
        borderRadius: '8px',
        border: '1px solid rgba(32, 41, 49, 0.32)',
        mb: 1,
        mt: 1,
        ...sx,
      }}
    >
      <Box
        data-testid={dataTestId}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '56px',
          flexShrink: 0,
          alignSelf: 'stretch'
        }}
      >
        {children}
      </Box>
      {detailView}
    </Box>
  )
}