import { Box, useTheme } from "@mui/material";
import { Typography } from "saga-library/src";
import React from "react";
import { AbLabResultObservationResult } from "../../../../../types/patients";
import DOMPurify from 'dompurify'

export interface LabAndInvestigationResultValueProps {
  result: AbLabResultObservationResult
  dataTestId: string
}

export const LabAndInvestigationResultValue = ({result, dataTestId}:LabAndInvestigationResultValueProps) => {
  const theme = useTheme()

  const abnormal = (result.abLabResultObservationResultAbnormals?.length ?? 0) > 0

  const sanitizeObservationValue = (result: AbLabResultObservationResult) => {
    if (!result?.observationValue) {
      return ""
    }

    return DOMPurify.sanitize(result.observationValue, {FORBID_TAGS: ['a'], ALLOWED_TAGS: ['br', 'null']});
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "start"
      }}
    >
      <Typography
        variant={"h5"}
        fontFamily={"inherit"}
        dataTestId={`${dataTestId}-value`}
        sx={{whiteSpace: "break-spaces", overflowWrap: "anywhere"}}
      >
        <Box component={"span"} sx={{ fontWeight: "700" }}>
          {
            sanitizeObservationValue(result)
              ?.replaceAll('<BR>', '\n')
              ?.replaceAll('<br>', '\n')
              ?.replaceAll('</null>', '\n')
          }
        </Box>
        <Box component={"span"} sx={{ fontWeight: "400" }}>{result?.unitsDescription ? " " + result?.unitsDescription : ""}</Box>
      </Typography>
      <Typography
        variant={"body2"}
        fontFamily={"inherit"}
        dataTestId={`${dataTestId}-referenceRange`}
        sx={{ color: abnormal ? theme.palette.error.main : theme.palette.greys.medium }}
      >
        {result?.referenceRange}
      </Typography>
    </Box>
  )
}