import { Typography } from "../../Typography";
import { Box } from "@mui/material";
import React from "react";

interface NameAndIdentifierProps {
  name: string,
  identifier?: string
}

export const NameAndIdentifier = ({name, identifier}:NameAndIdentifierProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '144px', alignItems: 'start', textOverflow: 'ellipsis', overflow: 'hidden'}}>
      <Typography variant={'body2'}>{name}</Typography>
      <Typography variant={'p3'} sx={{ color: 'gray' }}>{identifier}</Typography>
    </Box>
  )
}