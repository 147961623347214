import React, { useState } from 'react'
import { Box } from '@mui/material'
import { Radio, SimpleRadioGroup, TableList, TableListHeaderConfig } from 'saga-library/src'
import { UnsubmittedClaimRow } from './UnsubmittedClaimRow'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAccountContext } from '../../../../providers/AccountContext'
import { useUnsubmittedClaimsContext } from '../../providers/UnsubmittedClaimsProvider'
import { useReadQuery } from '@apollo/client'
import _get from 'lodash/get'
import { Permission, PermissionType } from '../../../../types/settings/Permission'
import { AbClaimProblemSeverity } from '../../../../utils/ABClaimConstants'
import { AbClaim } from '../../../../types/billing'
import { DATE_FORMAT } from '../../../../utils/SettingsConstants'


export const UnsubmittedClaimList = ({ dataTestId }) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const [filter, setFilter] = useState(queryParams.get('filter') || '*')
  const { tenant_id } = useParams()
  const { userHasPermission } = useAccountContext()
  const { getUserSetting } = useAccountContext()
  const dateFormat = getUserSetting(DATE_FORMAT) as string
  const navigate = useNavigate()

  const { unsubmittedClaimsQueryRef, setRedirectToNewlyCreatedClaim, setSelectedClaimIds, selectedClaimIds } = useUnsubmittedClaimsContext()

  const data = useReadQuery(unsubmittedClaimsQueryRef!)
  let allUnsubmittedClaims = _get(data, 'data.tenant.abClaim.listUnsubmittedAbClaim', [] as any)

  const hasEditPermission = (tenant_id && userHasPermission(tenant_id, PermissionType.Billing, Permission.READWRITE))

  const filterClaimsByStatus = (claim) => {
    if (filter === '*') {
      return true
    } else if (filter !== 'ERROR') {
      return claim.claimStatus === filter
    } else {
      return claim.problems.some(p => p.severity === AbClaimProblemSeverity.ERROR)
    }
  }

  const onRowClick = (claim: AbClaim) => {
    setSelectedClaimIds([])
    setRedirectToNewlyCreatedClaim(false)
    navigate(`../c/${claim.id}`)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box
        data-testid={dataTestId}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <SimpleRadioGroup
          label={null}
          row={true}
          name={'filter'}
          dataTestId={`${dataTestId}-filters`}
          value={filter}
          onChange={(event) => setFilter(event.target.value)}
          sx={{ gap: 2 }}
        >
          <Radio label={'All'} value={'*'} sx={{ ml: 0 }} dataTestId={`${dataTestId}-all`} />
          <Radio label={'Ready to submit'} value={'READY'} dataTestId={`${dataTestId}-ready`} />
          <Radio label={'Held'} value={'HOLD'} dataTestId={`${dataTestId}-hold`} />
          <Radio label={'Contains errors'} value={'ERROR'} dataTestId={`${dataTestId}-error`} />
        </SimpleRadioGroup>
      </Box>
      <TableList
        size={'small'}
        checkedRows={selectedClaimIds}
        setCheckedRows={setSelectedClaimIds}
        showCheckbox={!!hasEditPermission}
        emptyListComponent={ { message: "Unsubmitted claims appear here once saved." } }
        columns={UnsubmittedClaimColumns}
        rows={[
          ...allUnsubmittedClaims
            .filter(filterClaimsByStatus)
            .map((claim) => {
              return (
                UnsubmittedClaimRow(claim, dateFormat, onRowClick)
              )
            })
        ]}
        dataTestId={dataTestId}
      />
    </Box>
  )
}

export default UnsubmittedClaimList
export const UnsubmittedClaimColumns: TableListHeaderConfig[] = [
  { name: '' },
  { name: 'Patient' },
  { name: 'Service date' },
  { name: 'Service' },
  { name: 'Calls' },
  { name: 'Diagnoses' },
  { name: 'Practitioner' },
  { name: 'Facility' },
  { name: 'Claimed' },
]